<template>
  <div style="margin-top: 15px;">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane :label="$t('lang.poweranalysis')" name="first">
        <analysis ref="analysis"></analysis>
      </el-tab-pane>
      <el-tab-pane v-if="info.type==0" :label="$t('lang.operaterecord')" name="second">
        <opera-record ref="opera_record"></opera-record>
      </el-tab-pane>
      <el-tab-pane :label="$t('lang.historicaldata')" name="third">
        <history ref="history"></history>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import history from "./historical-data/historical-data";
import analysis from "./analysis/analysis";
import RechardRecord from "./rechard-record/rechard-record";
import OperaRecord from "./opera-record/opera-record";
export default {
  components: { history, analysis, RechardRecord, OperaRecord },
  props: {
    deviceInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      activeName: "first",
      tabIndex:"0",
      info: {},
    };
  },
  watch: {
    deviceInfo(val) {
        console.log(val,this.tabIndex);
        if (val) {
            this.info = val;
            this.handleClick({index:this.tabIndex});
            // this.$refs.history.setData(val);
        }
      // this.$refs.analysis.setData(val);
    },
  },
  mounted() {
        if (this.deviceInfo) {
            this.info = this.deviceInfo;
            this.handleClick({index:'0'});
        }
  },
  methods: {
    handleClick(tab, event) {
      //   console.log(tab, event, tab.index);
      this.tabIndex = tab.index || '0';
      switch (tab.index) {
        
        case "0":
          this.$refs.analysis.setData(this.info);
          break;
        case "1":
           this.$refs.opera_record.setData(this.info);
          break;
        case "2":
          this.$refs.history.setData(this.info);
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="less" scoped>
</style>