<template>
  <div class="common_bg">
    <el-form
      inline
      :model="reqParams"
      :rules="rules"
      ref="importForm"
      label-width="80px"
    >
      <el-form-item class="form-margin" label="采购主体" prop="company">
        <el-input
          maxlength="20"
          v-model="reqParams.company"
          placeholder="请输入采购主体"
        ></el-input>
      </el-form-item>
      <el-form-item class="form-margin" label="所属平台" prop="platform">
        <el-input
          maxlength="20"
          v-model="reqParams.platform"
          placeholder="请输入所属平台"
        ></el-input>
      </el-form-item>
      <el-form-item class="form-margin" label="采购人" prop="buyer">
        <el-input
          maxlength="20"
          v-model="reqParams.buyer"
          placeholder="请输入采购人"
        ></el-input>
      </el-form-item>
      <el-form-item class="form-margin" label="联系方式" prop="phonenum">
        <el-input
          type="number"
          v-model="reqParams.phonenum"
          placeholder="请输入联系方式"
        ></el-input>
      </el-form-item>
      <el-form-item class="form-margin" label="订单号" prop="orderno">
        <el-input
          maxlength="20"
          v-model="reqParams.orderno"
          placeholder="请输入订单号"
        ></el-input>
      </el-form-item>
      <el-form-item class="form-margin" label="供应商" prop="provider">
        <el-input
          maxlength="20"
          v-model="reqParams.provider"
          placeholder="请输入供应商"
        ></el-input>
      </el-form-item>
      <el-form-item class="form-margin" label="运营商" prop="carrierType">
        <el-select v-model="reqParams.carrierType">
          <el-option
            v-for="(item, index) in options"
            :label="item.label"
            :key="index"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="form-margin" label="业务名称" prop="plainName">
        <el-input
          maxlength="20"
          v-model="reqParams.plainName"
          placeholder="请输入业务名称"
        ></el-input>
      </el-form-item>
      <el-form-item class="form-margin" label="订单数量" prop="cardCount">
        <el-input
          maxlength="20"
          type="number"
          v-model="reqParams.cardCount"
          placeholder="请输入订单数量"
        ></el-input>
      </el-form-item>
      <el-form-item class="form-margin" label="价格" prop="plainPrice">
        <el-input
          maxlength="20"
          type="number"
          v-model="reqParams.plainPrice"
          placeholder="请输入价格"
        ></el-input>
      </el-form-item>
      <el-form-item class="form-margin" label="付费方式" prop="paymentMethod">
        <el-input
          maxlength="20"
          v-model="reqParams.paymentMethod"
          placeholder="请输入付费方式"
        ></el-input>
      </el-form-item>
      <el-form-item class="form-margin" label="结算时间" prop="chargingTime">
        <el-input
          maxlength="2"
          type="number"
          v-model="reqParams.chargingTime"
          placeholder="请输入结算时间"
        >
          <template slot="suffix">日</template>
        </el-input>
      </el-form-item>
      <el-form-item class="form-margin" label="网络类型" prop="flowType">
        <el-select v-model="reqParams.flowType">
          <el-option
            v-for="(item, index) in options1"
            :label="item.label"
            :key="index"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="form-margin" label="套餐详情" prop="plainDetail">
        <el-input
          maxlength="20"
          v-model="reqParams.plainDetail"
          placeholder="请输入套餐详情"
        ></el-input>
      </el-form-item>
      <el-form-item class="form-margin" label="受理地区" prop="acceptArea">
        <el-input
          maxlength="20"
          v-model="reqParams.acceptArea"
          placeholder="请输入受理地区"
        ></el-input>
      </el-form-item>
      <el-form-item class="form-margin" label="受理时间" prop="acceptTime">
        <el-date-picker
          v-model="reqParams.acceptTime"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="datetime"
          placeholder="选择日期时间"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item class="form-margin" label="客户经理" prop="customerManager">
        <el-input
          maxlength="10"
          v-model="reqParams.customerManager"
          placeholder="请输入客户经理"
        ></el-input>
      </el-form-item>
      <el-form-item class="form-margin" label="附件" prop="upgradefileUrl">
        <div class="_row upload_border">
          <span class="attachment" v-if="text2">{{ text2 }}</span>
          <el-upload
            action="#"
            :http-request="doMyUploadPic1"
            :before-upload="beforeUpload1"
            :limit="100"
          >
            <div slot="file"></div>
            <p style="color: #66b1ff; font-size: 14px">上传附件</p>
          </el-upload>
        </div>
      </el-form-item>
      <el-form-item class="form-margin" label="">
        <el-upload
          action=""
          class="upload-demo"
          :auto-upload="false"
          :show-file-list="false"
          multiple
          :limit="999"
          :on-change="handleChange"
        >
          <el-button type="primary" plain>模板导入</el-button>
        </el-upload>
      </el-form-item>
      <el-form-item class="form-margin" label="">
        <el-button type="primary" @click="submit()">入库</el-button>
      </el-form-item>
      <el-form-item class="form-margin" label="">
        <el-button type="primary"
          ><a
            :href="`${path}card-import.xls`"
            style="color: #fff"
            download="流量卡入库模版.xls"
            >下载模板</a
          >
        </el-button>
      </el-form-item>
    </el-form>
    <!-- 表格信息 -->
    <el-table
      :data="tableData"
      :show-summary="false"
      style="width: 100%"
      v-loading="loading"
    >
      <!-- 不分页 -->
      <el-table-column label="编号" type="index" width="80"> </el-table-column>
      <el-table-column
        v-for="(item, index) in tableTitleList"
        :key="index"
        :prop="item.prop"
        align="left"
        :label="item.label"
        :width="item.width"
        :formatter="formatter"
      >
      </el-table-column>
      <el-table-column
        v-if="showTabelError"
        prop="failReason"
        label="错误原因"
      ></el-table-column>
    </el-table>
    <div style="height: 65px"></div>
    <div>
      <span id="test_infoCount"
        >{{ $t("lang.common") }}{{ totalItemCount }}{{ $t("lang.strip") }}</span
      >
    </div>
  </div>
</template>
<script>
import { options2, options3 } from "./options";
import { tableTitleList1 } from "./titleOptions";
import { regexp } from "@/app/common/tools/index.js";
import { mapActions } from "vuex";
import XLSX from "xlsx";
import moment from "moment";
export default {
  name: "CardPutStorage",
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$nextTick(() => {
        vm.addKeepAlive("CardPutStorage");
      });
    });
  },
  components: {},
  data() {
    var validatePhone = (rule, value, callback) => {
      let reg = new RegExp(regexp.phone);
      if (value === "") {
        callback(new Error(this.$t("lang.toinput") + this.$t("lang.phone")));
      } else if (!reg.test(value)) {
        callback(new Error(this.$t("lang.phoneincorrect")));
      } else {
        callback();
      }
    };
    var validateInput = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入内容"));
      } else {
        callback();
      }
    };
    var validateTime = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入内容"));
      } else if (value < 1 || value > 31) {
        callback(new Error("日期范围为1到31号"));
      } else {
        callback();
      }
    };
    return {
      text2: null,
      showTabelError: false,
      options: options2,
      options1: options3,
      timeValue: null,
      timeValue1: null,
      tableTitleList: tableTitleList1,
      path: process.env.BASE_URL,
      loading: false,
      tableData: [],
      reqParams: {
        acceptArea: "",
        acceptTime: "",
        buyer: "",
        cancelCount: null,
        cardCount: null,
        carrierType: null,
        carrierTypeName: "",
        chargingTime: null,
        company: "",
        customerManager: "",
        fileUrl: "",
        flowType: null,
        flowTypeName: "",
        listItem: [
          {
            acceptTime: "",
            activeTime: "",
            cancelTime: "",
            cardDisc: "",
            cardStatus: null,
            ccid: "",
            failReason: "",
            id: null,
            imei: "",
            phonenum: "",
          },
        ],
        nouseCount: null,
        orderno: "",
        paymentMethod: "",
        phonenum: "",
        plainDetail: null,
        plainName: "",
        plainPrice: null,
        platform: "",
        provider: "",
        usingCount: null,
      },
      totalItemCount: 0,
      rules: {
        company: [{ validator: validateInput, trigger: "blur" }],
        platform: [{ validator: validateInput, trigger: "blur" }],
        buyer: [{ validator: validateInput, trigger: "blur" }],
        orderno: [{ validator: validateInput, trigger: "blur" }],
        provider: [{ validator: validateInput, trigger: "blur" }],
        phonenum: [{ validator: validatePhone, trigger: "blur" }],
        carrierType: [{ validator: validateInput, trigger: "blur" }],
        plainName: [{ validator: validateInput, trigger: "blur" }],
        cardCount: [{ validator: validateInput, trigger: "blur" }],
        plainPrice: [{ validator: validateInput, trigger: "blur" }],
        paymentMethod: [{ validator: validateInput, trigger: "blur" }],
        chargingTime: [{ validator: validateTime, trigger: "blur" }],
        flowType: [{ validator: validateInput, trigger: "blur" }],
        plainDetail: [{ validator: validateInput, trigger: "blur" }],
        acceptArea: [{ validator: validateInput, trigger: "blur" }],
        acceptTime: [{ validator: validateInput, trigger: "blur" }],
        customerManager: [{ validator: validateInput, trigger: "blur" }],
      },
    };
  },
  created() {},
  mounted() {},
  methods: {
    ...mapActions({
      addKeepAlive: "addKeepAlivePage",
      orderSubmit: "ZD_FlowCardOrderSubmit",
      upload: "ZD_UPLOAD_FILE",
    }),
    submit() {
      this.$refs["importForm"].validate((valid) => {
        if (valid) {
          this.deviceImport();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm() {
      this.$refs["importForm"].resetFields();
    },
    deviceImport() {
      let errorMassage = "";
      let list = this.tableData;
      let errphonenum = false;
      let errimei = false;
      let errccid = false;
      let errversion = false;
      let errcardStatus = false;
      let inmeternoregular = false;
      let versionname = "";
      console.log(list);
      if (this.totalItemCount != this.reqParams.cardCount) {
        errorMassage += "导入订单数量不一致、";
      }
      list.map((item, index) => {
        list.map((ele, idx) => {
          if (index != idx) {
            if (ele.phonenum && item.phonenum == ele.phonenum) {
              errphonenum = true;
            }
            if (ele.imei && item.imei == ele.imei) {
              errimei = true;
            }
            if (ele.ccid && item.ccid == ele.ccid) {
              errccid = true;
            }
            if (
              ele.cardStatus != -1 &&
              ele.cardStatus != 0 &&
              ele.cardStatus != 1 &&
              ele.cardStatus != 3
            ) {
              errcardStatus = true;
            }
          }
        });
      });

      if (errphonenum) {
        errorMassage += "用户号码存在重复、";
      }
      if (inmeternoregular) {
        errorMassage += "通讯地址格式错误、";
      }
      if (errimei) {
        errorMassage += "IMEI号存在重复、";
      }
      if (errccid) {
        errorMassage += "ccid存在重复、";
      }
      if (errcardStatus) {
        errorMassage += "产品状态格式输入错误";
      }
      // console.log("errorMassage>>>",errorMassage);
      if (errorMassage == "") {
        this.putInStorage();
      } else {
        this.$message.error(errorMassage);
      }
    },
    putInStorage() {
      this.reqParams.listItem = this.tableData;
      this.orderSubmit(this.reqParams).then((res) => {
        if (res.success == 1) {
          this.$message.success("导入成功");
          this.$router.push("/equipmentputstorage");
        } else {
          this.showTabelError = res.failList && res.failList.length > 0;
          this.tableData = res.failList;
        }
      });
    },
    beforeUpload1(file) {
      this.showTabelError = false;
      // const isText = file.type === "text/plain";
      // file.name
      const isLt2M = file.size / 1024 / 1024 < 2;
      let type = file.name.split(".");
      let length = type.length - 1;
      let isText =
        type[length] == "xls" ||
        type[length] == "xlsx" ||
        type[length] == "doc" ||
        type[length] == "docx" ||
        type[length] == "rar" ||
        type[length] == "zip";
      if (!isText) {
        this.$message.error("上传文件只能是 EXCEL/WORD/RAR/ZIP 格式!");
      } else {
        this.text2 = file.name;
      }
      // if (!isLt2M) {
      //   this.$message.error('上传头像图片大小不能超过 2MB!');
      // }
      return isText;
    },
    doMyUploadPic1(file) {
      const params = new FormData();
      params.append("file", file.file);
      this.upload(params).then((res) => {
        console.log(res);
        var url = res.url;
        this.reqParams.fileUrl = url;
      });
    },
    search() {
      this.reqParams.pageNum = 1;
      this.getList();
    },
    handleCurrentChange(e) {
      this.reqParams.pageNum = e;
      this.getList();
    },
    detail(e) {
      console.log(e);
    },
    putInStorage() {
      this.reqParams.listItem = this.tableData;
      this.orderSubmit(this.reqParams).then((res) => {
        if (res.success == 1) {
          this.$message.success("导入成功");
          this.$router.push("/datacardmanagement");
        } else {
          this.showTabelError = res.failList && res.failList.length > 0;
          res.failList && (this.tableData = res.failList);
        }
      });
    },
    formatter(row, { property }) {
      let ret = null;
      switch (property) {
        case "carrierType":
          ret = this.options.find((item) => item.value == row[property]).label;
          break;
        case "flowType":
          ret = this.options1.find((item) => item.value == row[property]).label;
          break;
        case "nouseCount":
          if (row[property] == 0) {
            ret = "未激活";
          } else if (row[property] == 1) {
            ret = "在用";
          } else if (row[property] == 3) {
            ret = "注销";
          } else {
            ret = "未知";
          }
          break;
        case "acceptTime":
          ret = row[property]
            ? moment(row[property]).format("YYYY-MM-DD HH:mm")
            : "--";
          break;
        case "activeTime":
          ret = row[property]
            ? moment(row[property]).format("YYYY-MM-DD HH:mm")
            : "--";
          break;
        case "cancelTime":
          ret = row[property]
            ? moment(row[property]).format("YYYY-MM-DD HH:mm")
            : "--";
          break;
        default:
          ret = row[property] ? row[property] : row[property] == 0 ? 0 : "--";
          break;
      }
      return ret;
    },
    dealExcelData(list) {
      let data = [];
      list.map((item) => {
        let obj = {
          acceptTime: null,
          activeTime: null,
          cancelTime: null,
          cardDisc: null,
          cardStatus: null,
          ccid: null,
          failReason: null,
          id: null,
          imei: null,
          phonenum: null,
        };
        for (let key in item) {
          switch (key) {
            case "用户号码":
              obj.phonenum = item[key];
              break;
            case "ICCID":
              obj.ccid = item[key];
              break;
            case "L_IMSI":
              obj.imei = item[key];
              break;
            case "卡状态":
              obj.cardDisc = item[key];
              break;
            case "产品状态":
              obj.cardStatus = item[key];
              break;
            case "受理时间":
              obj.acceptTime = item[key];
              break;
            case "激活时间":
              obj.activeTime = item[key];
              break;
            case "注销时间":
              obj.cancelTime = item[key];
              break;
            default:
              break;
          }
        }

        data.push(obj);
      });
      this.tableData = data;
      this.totalItemCount = data.length;
    },
    handleChange(file) {
      // let file = file.files[0] // 使用传统的input方法需要加上这一步
      const types = file.name.split(".")[1];
      const fileType = ["xlsx", "xlc", "xlm", "xls", "xlt", "xlw", "csv"].some(
        (item) => item === types
      );
      if (!fileType) {
        alert("格式错误！请重新选择");
        return;
      }
      this.file2Xce(file).then((tabJson) => {
        console.log(tabJson);
        if (tabJson && tabJson.length > 0) {
          this.xlsxJson = tabJson;
          this.dealExcelData(tabJson[0].sheet);
          // xlsxJson就是解析出来的json数据,数据格式如下
          // [
          //   {
          //     sheetName: sheet1
          //     sheet: sheetData
          //   }
          // ]
        }
      });
    },
    file2Xce(file) {
      return new Promise(function (resolve, reject) {
        const reader = new FileReader();
        reader.onload = function (e) {
          const data = e.target.result;
          this.wb = XLSX.read(data, {
            type: "binary",
          });
          const result = [];
          console.log(this.wb);
          this.wb.SheetNames.forEach((sheetName) => {
            result.push({
              sheetName: sheetName,
              sheet: XLSX.utils.sheet_to_json(this.wb.Sheets[sheetName]),
            });
          });
          resolve(result);
        };
        reader.readAsBinaryString(file.raw);
        // console.log(file);
        // reader.readAsBinaryString(file) // 传统input方法
      });
    },
  },
  computed: {},
  watch: {},
  filters: {},
  //keepAlive
  activated() {},
  deactivated() {},
  updated() {},
  destroyed() {},
};
</script>
<style lang='less' scoped>
.form-margin {
  margin-right: 20px;
}
.bottom_page {
  position: absolute;
  bottom: 36px;
  // bottom: 13px;
  right: 20px;
}
#test_infoCount {
  font-size: 14px;
  color: #999;
  position: absolute;
  // bottom: 42px;
  // left: 30px;
  bottom: 38px;
  left: 45px;
}
.attachment {
  width: 80px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
}
.upload_border {
  border: 1px solid #d8dce5;
  height: 36px;
  width: 179px;
  border-radius: 2px;
  padding-left: 10px;
}
/deep/.el-upload-list__item:first-child {
  margin-top: 0px;
}
/deep/.el-input__inner {
  width: 200px;
}
/deep/.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 200px;
}
</style>