const tableTitleList = [{
    label: "订单号",
    prop: "orderno"
},
{
    label: "采购主体",
    prop: "company"
},
{
    label: "所属平台",
    prop: "platform"
},
{
    label: "采购人",
    prop: "buyer"
},
{
    label: "供应商",
    prop: "provider"
},
{
    label: "运营商",
    prop: "carrierType"
},
{
    label: "网络类型",
    prop: "flowType"
},
{
    label: "业务名称",
    prop: "plainName"
},
{
    label: "订单数量(张)",
    prop: "cardCount"
},
{
    label: "卡状态(未使用/正常/注销)",
    prop: "nouseCount",
    width: "200"
},
{
    label: "价格(元/月)",
    prop: "plainPrice"
},
{
    label: "套餐详情",
    prop: "plainDetail"
},
{
    label: "受理地区",
    prop: "acceptArea"
},
{
    label: "受理时间",
    prop: "acceptTime",
    width: "200"
},
{
    label: "客户经理",
    prop: "customerManager"
},
{
    label: "电话",
    prop: "phonenum"
},

]

const tableTitleDetail = [{
    label: "用户号码",
    prop: "phonenum"
},
{
    label: "ICCID",
    prop: "ccid",
    width: '200'
},
// {
//     label: "IMEI",
//     prop: "imei"
// },
{
    label: "卡状态",
    prop: "cardDisc"
},
{
    label: "产品状态",
    prop: "cardStatus"
},
{
    label: "受理时间",
    prop: "acceptTime"
},
{
    label: "激活时间",
    prop: "activeTime"
},
{
    label: "注销时间",
    prop: "cancelTime"
},
{
    label: "设备资产号",
    prop: "inmeterno"
},
]

const tableTitleList1 = [{
    label: "用户号码",
    prop: "phonenum"
},
{
    label: "CCID",
    prop: "ccid"
},
// {
//     label: "IMEI",
//     prop: "imei"
// },
{
    label: "卡状态",
    prop: "cardDisc"
},
{
    label: "产品状态",
    prop: "cardStatus"
},
{
    label: "受理时间",
    prop: "acceptTime"
},
{
    label: "激活时间",
    prop: "activeTime"
},
{
    label: "注销时间",
    prop: "cancelTime"
},
]

const storeTitleList = [
    {
        label: "用户号码",
        prop: "phonenum"
    },
    {
        label: "ICCID",
        prop: "ccid"
    },
    {
        label: "IMEI",
        prop: "imei"
    },
    {
        label: "卡状态",
        prop: "cardDisc",
        width: "100"
    },
    {
        label: "产品状态",
        prop: "cardStatus",
        width: "100"
    },
    {
        label: "受理时间",
        prop: "acceptTime"
    },
    {
        label: "激活时间",
        prop: "activeTime"
    },
    {
        label: "注销时间",
        prop: "cancelTime",
        width: "160"
    },
]

const storeMsg = [
    {
        title: "采购主体",
        value: "--",
    },
    {
        title: "所属平台",
        value: "--",
    },
    {
        title: "采购人",
        value: "--",
    },
    {
        title: "供应商",
        value: "--",
    },
    {
        title: "业务名称",
        value: "--",
    },
    {
        title: "运营商",
        value: "--",
    },
    {
        title: "订单号",
        value: "--",
    },
    {
        title: "订单数量",
        value: "--",
    },
    {
        title: "结算日期",
        value: "--",
    },
    {
        title: "客户经理",
        value: "--",
    },
    {
        title: "网络类型",
        value: "--",
    },
    {
        title: "套餐详情",
        value: "--",
    },
    {
        title: "受理时间",
        value: "--",
    },
    {
        title: "受理地区",
        value: "--",
    },
    {
        title: "联系方式",
        value: "--",
    },
]

const settlementDataDetailsShortTitle = [
    {
        label: "",
        prop: "",
        width: "5",
    },
    {
        label: "序号",
        prop: "index",
        width: "120"
    },
    {
        label: "结算日期",
        prop: "date",
        width: "140"
    },
    {
        label: "结算金额(元)",
        prop: "amount",
        width: "140",
        align: "right"
    },
    {
        label: "",
        prop: "",
        width: "10",
    },
]
const settlementDataDetailsLongTitle = [
    {
        label: "",
        prop: "",
        width: "5",
    },
    {
        label: "序号",
        prop: "index",
        width: "120"
    },
    {
        label: "结算日期",
        prop: "date",
        width: "140"
    },
    {
        label: "结算金额(元)",
        prop: "amount",
        width: "140",
        align: "right"
    },
    {
        label: "",
        prop: "",
    },
    {
        label: "序号",
        prop: "index2",
        width: "120",
    },
    {
        label: "结算日期",
        prop: "date2",
        width: "140"
    },
    {
        label: "结算金额(元)",
        prop: "amount2",
        width: "140",
        align: "right"
    },
    {
        label: "",
        prop: "",
        width: "10",
    },
]

const flowCardCancelTitle = [
    {
        label: "编号",
        prop: "index"
    },
    {
        label: "ICCID",
        prop: "iccid"
    },
    {
        label: "注销时间",
        prop: "cancellTime"
    },
    {
        label: "用户号码",
        prop: "phoneNum"
    },
    {
        label: "订单号",
        prop: "orderno"
    },
    {
        label: "设备资产号",
        prop: "inmeterno",
        width: "120"
    },
]

export {
    tableTitleList,
    tableTitleList1,
    tableTitleDetail,
    storeTitleList,
    storeMsg,
    settlementDataDetailsShortTitle,
    settlementDataDetailsLongTitle,
    flowCardCancelTitle
}