<template>
  <div class="common_bg">
    <div v-show="!showDateDetail">
      <span @click="back"><cm-back /></span>
      <div class="_row" style="padding-bottom: 20px">
        <echart
          style="flex-shrink: 0"
          :options="echartOption"
          :height="165"
        ></echart>
        <div class="aline"></div>
        <div
          v-if="Object.keys(cardInfo).length > 0"
          class="_row"
          style="padding: 0 20px; flex-wrap: wrap; margin-right: 30px"
        >
          <div class="top_info">
            <p>
              <span class="label">采购主体</span>
              <span class="value" :title="repEmpty(cardInfo.company, '--')">{{
                repEmpty(cardInfo.company, "--")
              }}</span>
            </p>
            <p>
              <span class="label">所属平台</span>
              <span class="value" :title="repEmpty(cardInfo.platform, '--')">{{
                repEmpty(cardInfo.platform)
              }}</span>
            </p>
            <p>
              <span class="label">采购人</span>
              <span class="value" :title="repEmpty(cardInfo.buyer, '--')">{{
                repEmpty(cardInfo.buyer)
              }}</span>
            </p>
            <p>
              <span class="label">订单号</span>
              <span class="value" :title="repEmpty(cardInfo.orderno, '--')">{{
                repEmpty(cardInfo.orderno)
              }}</span>
            </p>
            <p>
              <span class="label">供应商</span>
              <span class="value" :title="repEmpty(cardInfo.provider, '--')">{{
                repEmpty(cardInfo.provider)
              }}</span>
            </p>
            <p>
              <span class="label">运营商</span>
              <span
                class="value"
                :title="cardInfo.carrierType | carrierTypeFilter(options)"
                >{{ cardInfo.carrierType | carrierTypeFilter(options) }}</span
              >
            </p>
            <p>
              <span class="label">业务名称</span>
              <span class="value" :title="repEmpty(cardInfo.plainName, '--')">{{
                repEmpty(cardInfo.plainName, "--")
              }}</span>
            </p>
            <p>
              <span class="label">订单数量</span>
              <span class="value" :title="repEmpty(cardInfo.cardCount, '--')">{{
                repEmpty(cardInfo.cardCount, "--")
              }}</span>
            </p>
            <p>
              <span class="label">套餐详情</span>
              <span
                class="value"
                :title="repEmpty(cardInfo.plainDetail, '--')"
                >{{ repEmpty(cardInfo.plainDetail, "--") }}</span
              >
            </p>
            <p>
              <span class="label">网络类型</span>
              <span
                class="value"
                :title="cardInfo.flowType | flowTypeFilter(options1)"
                >{{ cardInfo.flowType | flowTypeFilter(options1) }}</span
              >
            </p>
            <p>
              <span class="label">受理时间</span>
              <span
                class="value"
                :title="repEmpty(cardInfo.acceptTime, '--')"
                >{{ repEmpty(cardInfo.acceptTime, "--") }}</span
              >
            </p>
            <p>
              <span class="label">受理地区</span>
              <span
                class="value"
                :title="repEmpty(cardInfo.acceptArea, '--')"
                >{{ repEmpty(cardInfo.acceptArea, "--") }}</span
              >
            </p>
            <p>
              <span class="label">客户经理</span>
              <span
                class="value"
                :title="repEmpty(cardInfo.customerManager, '--')"
                >{{ repEmpty(cardInfo.customerManager, "--") }}</span
              >
            </p>
            <p>
              <span class="label">联系电话</span>
              <span class="value" :title="repEmpty(cardInfo.phonenum, '--')">{{
                repEmpty(cardInfo.phonenum, "--")
              }}</span>
            </p>
            <p>
              <span class="label">结算日期</span>
              <span class="value detail" @click="onClickCheckDetail"
                >查看详情</span
              >
            </p>
            <p v-if="!isEmpty(cardInfo.plainPrice)">
              <span class="label">价格</span>
              <span
                class="value"
                :title="repEmpty(cardInfo.plainPrice, '--')"
                >{{ repEmpty(cardInfo.plainPrice, "--") }}</span
              >
            </p>
            <p v-if="!isEmpty(cardInfo.paymentMethod)">
              <span class="label">付费方式</span>
              <span
                class="value"
                :title="repEmpty(cardInfo.paymentMethod, '--')"
                >{{ repEmpty(cardInfo.paymentMethod, "--") }}</span
              >
            </p>
            <p v-if="!isEmpty(cardInfo.chargingTime)">
              <span class="label">结算时间</span>
              <span class="value" :title="repEmpty(cardInfo.chargingTime, '--')"
                >{{ repEmpty(cardInfo.chargingTime, "--") }}号</span
              >
            </p>
          </div>
        </div>
      </div>
      <el-form inline>
        <el-form-item class="form-margin" label="">
          <el-input
            maxlength="20"
            v-model="reqParams.easySearch"
            prefix-icon="el-icon-search"
            placeholder="请输入用户号码/CCID"
            @keyup.enter.native="search"
          ></el-input>
        </el-form-item>
        <el-form-item id="sign1" class="form-margin" label="产品状态">
          <el-select v-model="reqParams.cardStatus">
            <el-option
              v-for="(item, index) in options4"
              :label="item.label"
              :key="index"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" @click="search()">查询</el-button>
        </el-form-item>
        <!-- <el-form-item id="sign2">
          <el-button plain @click="onClickDownLoad">下载附件</el-button>
        </el-form-item> -->
        <el-form-item id="sign2">
          <el-button plain @click="onClickSubmit" style="background: #ecf5ff">数据校验</el-button>
        </el-form-item>
      </el-form>
      <!-- 表格信息 -->
      <el-table
        :data="dataList"
        :show-summary="false"
        style="width: 100%"
        :max-height="415"
      >
        <el-table-column
          label="序号"
          type="index"
          :index="getIndex"
          width="100"
        >
        </el-table-column>
        <el-table-column
          v-for="(item, index) in tableTitleList"
          :key="index"
          :prop="item.prop"
          align="left"
          :label="item.label"
          :min-width="item.width || '170'"
          :formatter="formatter"
        >
        </el-table-column>
        <el-table-column label="操作" width="55" fixed="right">
          <template slot-scope="scope">
            <div>
              <el-button @click="onClickDetail(scope.row)" type="text">
                详情
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div style="height: 65px"></div>
      <div>
        <el-pagination
          class="bottom_page"
          background
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :total="totalItemCount"
          :current-page.sync="reqParams.pageNum"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="10"
        >
        </el-pagination>
      </div>
    </div>
    <div v-show="showDateDetail">
      <settlement-date-details ref="settlementDateDetails" />
    </div>
    <data-verification-dialog ref="dataVerification" />
  </div>
</template>
<script>
import CmBack from "../../components/common/cm-back.vue";
import { options, options1, options4 } from "./options";
import { chartOption4 } from "./echarts-option";
import { tableTitleDetail } from "./titleOptions";
import { mapActions } from "vuex";
import moment from "moment";
import mixinf from "@/app/common/mixins/mixin_func.js";
import FlowCardInformation from "./children/flow-card-information.vue";
import SettlementDateDetails from "./children/settlement-date-details.vue";
import DataVerificationDialog from "./dialog/data-verification-dialog.vue";
export default {
  name: "DataCardDetail",
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$nextTick(() => {
        vm.addKeepAlive("DataCardDetail");
      });
    });
  },
  mixins: [mixinf],
  components: {
    FlowCardInformation,
    SettlementDateDetails,
    DataVerificationDialog,
    CmBack,
  },
  data() {
    return {
      /** 是否显示结算日期*/
      showDateDetail: false,
      options4: options4,
      options: options,
      options1: options1,
      timeValue: null,
      tableTitleList: tableTitleDetail,
      echartOption: chartOption4,
      dataList: [],
      cardInfo: {},
      reqParams: {
        cardStatus: null,
        easySearch: "",
        id: null,
        pageNum: 1,
        pageSize: 10,
        pageNumFinish: 1,
      },
      totalItemCount: 0,
    };
  },
  created() {
    this.init();
  },
  mounted() {
    // this.$bus.$on("back", (list) => {
    //     console.log(123);
    //     console.log(list);
    //     for (let i = 0; i < list.length; i++) {
    //       if (list[i].title === '流量卡订单详情') {
    //         list.splice(i, 1);
    //       }
    //     }
        // console.log(list);
        // list.splice(0, 1);
        // list.filter(function(item) {
        //   item.title != "流量卡订单详情"
        // });
        // let flag = list.find((item)=> item.title == '流量卡订单详情');
        // if(flag){

        // }
      // });
  },
  activated() {
    if (this.$route.params.id) this.init();
  },
  methods: {
    init() {
      this.showDateDetail = false;
      let id = this.$route.params.id;
      this.reqParams = {
        cardStatus: null,
        easySearch: "",
        id: this.$route.params.id,
        pageNum: 1,
        pageSize: 10,
        pageNumFinish: 1,
      };
      this.getStatistic(id);
      this.getList();
    },

    ...mapActions({
      addKeepAlive: "addKeepAlivePage",
      orderInfo: "ZD_FlowCardOrderDetail",
      orderList: "ZD_FlowCardOrderDetailList",
      delKeepAlive: "delKeepAlivePage",
    }),
    getIndex(idx) {
      return (
        idx + 1 + (this.reqParams.pageNumFinish - 1) * this.reqParams.pageSize
      );
    },
    formatter(row, { property }) {
      let ret = null;
      switch (property) {
        case "carrierType":
          ret = this.options.find((item) => item.value == row[property]).label;
          break;
        case "flowType":
          ret = this.options1.find((item) => item.value == row[property]).label;
          break;
        case "cardStatus":
          if (row[property] == 0) {
            ret = "未激活";
          } else if (row[property] == 1) {
            ret = "在用";
          } else if (row[property] == 3) {
            ret = "注销";
          } else {
            ret = "未知";
          }
          break;
        case "acceptTime":
          ret = row[property]
            ? moment(row[property]).format("YYYY-MM-DD HH:mm")
            : "--";
          break;
        case "activeTime":
          ret = row[property]
            ? moment(row[property]).format("YYYY-MM-DD HH:mm")
            : "--";
          break;
        case "cancelTime":
          ret = row[property]
            ? moment(row[property]).format("YYYY-MM-DD HH:mm")
            : "--";
          break;
        default:
          ret = row[property] ? row[property] : row[property] == 0 ? 0 : "--";
          break;
      }
      return ret;
    },
    handleSizeChange(size) {
      this.reqParams.pageNum = 1;
      this.reqParams.pageSize = size;
      this.getList();
    },
    handleCurrentChange(e) {
      this.reqParams.pageNum = e;
      this.getList();
    },
    search() {
      this.reqParams.pageNum = 1;
      this.getList();
    },
    getStatistic(e) {
      this.orderInfo({ id: e }).then((res) => {
        if (res.success == 1) {
          this.cardInfo = res.value;
          let type = res.value.carrierType;
          let acount =
            res.value.usingCount + res.value.nouseCount + res.value.cancelCount;
          let carType =
            type == 2
              ? "电信"
              : type == 0
              ? "移动"
              : type == 1
              ? "联通"
              : "其他";
          this.echartOption.series[0].label.formatter =
            acount + "张\n" + carType;
          this.echartOption.series[0].data = [
            {
              value: res.value.usingCount,
              name: `正常 ${res.value.usingCount} 张`,
            },
            {
              value: res.value.nouseCount,
              name: `未使用 ${res.value.nouseCount} 张`,
            },
            {
              value: res.value.cancelCount,
              name: `注销 ${res.value.cancelCount} 张`,
            },
          ];
        }
      });
    },
    getList() {
      this.orderList(this.reqParams)
        .then((res) => {
          if (res.success == 1) {
            this.dataList = res.listitem;
            this.totalItemCount = res.totalItemCount;
          } else {
            this.dataList = [];
            this.totalItemCount = 0;
          }
        })
        .finally((f) => {
          this.reqParams.pageNumFinish = this.reqParams.pageNum;
        });
    },
    /**操作 详情 */
    onClickDetail(row) {
      this.$store.commit("flowcard/ccid", row.ccid);
      this.$router.push({
        name: "FlowCardInformation",
        params: {
          ccid: row.ccid,
        },
      });
    },
    /**结算日期 查看详情 */
    onClickCheckDetail() {
      this.$refs.settlementDateDetails.init(this.cardInfo.id);
      this.showDateDetail = true;
    },
    onClickGoBack(idx = 1) {
      if (idx == 1) this.showDateDetail = false;
    },
    onClickSubmit() {
      this.$refs.dataVerification.show(this.cardInfo.id);
    },
    back() {
      // this.$bus.$emit('back')
      this.$router.push({
        path: "/datacardmanagement",
      });
      this.$bus.$on("back",list=> {
        // console.log(123);
        // // console.log(list);
        // let list = this.routeList
        for (let j = 0; j < list.length; j++) {
          if (list[j].title === '流量卡订单详情') {
            list.splice(j, 1);
          }
        }
        })
    },
  },
  computed: {},
  watch: {},
  filters: {
    carrierTypeFilter(e, options) {
      if (e || e == 0) {
        return options.find((item) => item.value == e).label;
      } else {
        return "--";
      }
    },
    flowTypeFilter(e, options1) {
      if (e || e == 0) {
        return options1.find((item) => item.value == e).label;
      } else {
        return "--";
      }
    },
  },
  destroyed() {
    // this.$bus.$off("back");
  },
};
</script>
<style lang='less' scoped>
/deep/ .el-form--inline .el-form-item {
  margin-right: 24px;
}
#sign1 {
  /deep/ .el-input {
    width: 139px;
  }
}
#sign2 {
  /deep/ .el-button {
    border: 1px solid #409eff;
    color: #409eff;
  }
  /deep/ .el-button:hover {
    background-color: #f2f9ff;
  }
}
.bottom_page {
  position: absolute;
  bottom: 36px;
  // bottom: 13px;
  right: 20px;
}
.aline {
  width: 2px;
  height: 165px;
  background-color: #eeeeee;
}
.top_info {
  display: flex;
  flex-wrap: wrap;
  p {
    font-size: 14px;
    display: flex;
    margin-bottom: 18px;
    .label {
      color: #333;
      width: 60px;
      text-align: right;
      margin-right: 12px;
    }
    .value {
      color: #666;
      width: 185px;
      margin-right: 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .detail {
      color: #409eff;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
</style>