<template>
  <div class="device_tab">
    <el-row justify="space-between">
      <el-col :span="8">
        <el-date-picker
          v-if="reqParams.datatype == 1"
          v-model="time"
          align="right"
          type="date"
          :placeholder="$t('lang.tochoose')"
          :picker-options="pickerOptions"
          @change="timeChange($event)"
        >
        </el-date-picker>
        <el-date-picker
          v-if="reqParams.datatype == 2"
          v-model="time"
          type="month"
          :placeholder="$t('lang.tochoose')"
          @change="timeChange($event)"
        >
        </el-date-picker>
        <el-date-picker
          v-if="reqParams.datatype == 3"
          v-model="time"
          type="year"
          :placeholder="$t('lang.tochoose')"
          @change="timeChange($event)"
        >
        </el-date-picker>
      </el-col>
      <el-col :span="14">
        <el-radio-group v-model="radioValue" @change="radioChange($event)">
          <el-radio-button :label="$t('lang.day')"></el-radio-button>
          <el-radio-button :label="$t('lang.month')"></el-radio-button>
          <el-radio-button :label="$t('lang.year')"></el-radio-button>
        </el-radio-group>
      </el-col>
      <el-col :span="2">
        <el-button
          icon="iconfont icon-daochu icon_margin"
          type="text"
          size="medium"
          @click="exportToLocal"
          >{{ $t("lang.export") }}</el-button
        >
      </el-col>
    </el-row>
    <my-chart
      :options="optionLine"
      :width="'100%'"
      :vHeight="'19.5vh'"
    ></my-chart>
    <!-- 表格信息 -->
    <el-table
      :data="dataList"
      :show-summary="false"
      style="width: 100%; margin-top: 20px"
      height="40vh"
      v-loading="loading"
    >
      <el-table-column
        v-for="(item, index) in titleList"
        :key="index"
        :prop="item.prop"
        width="150"
        :align="item.align ? item.align : 'right'"
        :label="$t(item.label)"
      >
        <template slot-scope="scope">
          <div
            v-if="item.prop == 'datatime'"
            style="width: 200px; text-align: left"
          >
            <p v-if="reqParams.datatype == 1" class="font14">
              {{ scope.row[item.prop] | timeFormat("HH:mm:ss") }}
            </p>
            <p v-else-if="reqParams.datatype == 2" class="font14">
              {{ scope.row[item.prop] | timeFormat("YYYY-MM-DD") }}
            </p>
            <p v-else class="font14">
              {{ scope.row[item.prop] | timeFormat("YYYY-MM") }}
            </p>
          </div>
          <div v-else-if="item.prop == 'pa'">
            {{ keepTwo(scope.row[item.prop], "--", 0) }}
          </div>
          <div v-else>{{ scope.row[item.prop] | toNormalFixed }}</div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { option_line, titleList, titleList1 } from "./title";
import { MyChart } from "@/app/components";
import moment from "moment";
import mixinf from "../../../common/mixins/mixin_func";
export default {
  mixins: [mixinf],
  components: { MyChart },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: this.$t("lang.today"),
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: this.$t("lang.yesterday"),
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: this.$t("lang.beforeweek"),
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      time: new Date(),
      radioValue: this.$t("lang.month"),
      reqParams: {
        deviceType: 0, //设备类型(0-电表;1-水表)
        pointId: null,
        datatype: 2, //数据类型(0-分钟;1-小时;2-天;3-月)
        pageSize: 99,
        pageNum: 1,
        starttime: moment().format("YYYY-MM-01 00:00:00"),
        endtime: moment().format("YYYY-MM-DD 23:59:59"),
      },
      optionLine: option_line,
      dataList: [],
      titleList: titleList,
      loading: false,
      phaseShow: false,
    };
  },
  mounted() {
    let that = this;
    this.$bus.$on("phaseType", (res) => {
      console.log(res);
      that.phaseShow = res.phaseType == 1;
      that.titleList = res.phaseType == 1 ? titleList1 : titleList;
    });
  },
  methods: {
    ...mapActions({
      hisList: "ZD_DeviceHistory",
    }),
    exportToLocal() {
      this.$download(
        this.reqParams,
        this.titleList,
        "Export_DeviceHistory",
        {},
        this.$t("lang.historicaldata")
      );
    },
    setData(e) {
      this.reqParams.pointId = e.meterId;
      this.reqParams.deviceType = e.type;
      this.getDataList();
    },
    getDataList() {
      this.hisList(this.reqParams).then((res) => {
        // console.log(res);
        if (res.success == 1) {
          if (res.listitem.length > 0) {
            this.dataList = res.listitem;
            setTimeout(() => {
              this.getChart(res.listitem);
            }, 100);
          } else {
            this.dataList = [];
            this.optionLine.xAxis[0].data = [0, 0, 0];
            this.optionLine.series[0].data = [0, 0, 0];
          }
        }
      });
    },
    //查询
    search() {
      this.getChooseTime();
      this.getDataList();
    },
    timeChange(e) {
      // console.log(e);
      this.search();
    },
    //年月日选择
    radioChange(e) {
      this.time = new Date();
      switch (e) {
        case this.$t("lang.day"):
          this.reqParams.datatype = 1;
          break;
        case this.$t("lang.month"):
          this.reqParams.datatype = 2;
          break;
        case this.$t("lang.year"):
          this.reqParams.datatype = 3;
          break;
        default:
          break;
      }
      this.search();
    },
    getChooseTime() {
      //   console.log(this.time, this.reqParams.datatype);
      let i = this.reqParams.datatype;
      let time = this.time;
      switch (i) {
        case 1:
          this.reqParams.endtime = moment(time).format("YYYY-MM-DD 23:59:59");
          this.reqParams.starttime = moment(time).format("YYYY-MM-DD 00:00:00");
          break;
        case 2:
          this.reqParams.endtime = moment(time).format("YYYY-MM-31 23:59:59");
          this.reqParams.starttime = moment(time).format("YYYY-MM-01 00:00:00");
          break;
        case 3:
          this.reqParams.endtime = moment(time).format("YYYY-12-31 23:59:59");
          this.reqParams.starttime = moment(time).format("YYYY-01-01 00:00:00");
          break;
        default:
          break;
      }
    },
    //获取chart
    getChart(list) {
      this.optionLine.xAxis[0].data = [];
      this.optionLine.series[0].data = [];
      if (this.reqParams.datatype == 1) {
        for (let index = list.length - 1; index >= 0; index--) {
          this.optionLine.xAxis[0].data.push(
            moment(list[index].datatime).format("hh:mm:ss")
          );
          this.optionLine.series[0].data.push(list[index].totalenergy);
        }
      } else if (this.reqParams.datatype == 2) {
        for (let index = list.length - 1; index >= 0; index--) {
          this.optionLine.xAxis[0].data.push(
            moment(list[index].datatime).format("MM-DD")
          );
          this.optionLine.series[0].data.push(list[index].totalenergy);
        }
      } else if (this.reqParams.datatype == 3) {
        for (let index = list.length - 1; index >= 0; index--) {
          this.optionLine.xAxis[0].data.push(
            moment(list[index].datatime).format("YYYY-MM")
          );
          this.optionLine.series[0].data.push(list[index].totalenergy);
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.device_tab {
  margin-top: 22px;
}
/deep/.icon_margin {
  margin-right: 6px;
}
</style>