<template>
  <div>
    <!-- 返回按钮 -->
    <div class="goback" @click="onClickGoBack">
      <i class="iconfont">&#xe616;</i>
      <span>返回</span>
    </div>
    <p class="title">结算日期详情</p>
    <div class="date_box">
      <div v-for="item in dataList" :key="item.year">
        <div class="year_box">
          <span :class="[item.year == getY() ? '' : 'color_grey', 'year']">{{
            item.year
          }}</span>
          <span class="year_text"> 年</span>
          <span class="settlement_amount">结算次数</span>
          <span class="settlement_amount_text">{{ item.listAmount }}</span>
        </div>
        <div
          :class="[
            item.listAmount < 2 ? 'shortTable' : 'longTable',
            'table_box',
          ]"
        >
          <cm-table
            :tableData="item.listitem"
            :tableTitle="item.listAmount < 2 ? shortTitle : longTitle"
            :maxHeight="'418'"
          >
            <template v-slot:rowRep1="{ rowData }">
              <div v-if="rowData.prop == ''"></div>
              <div v-else>
                {{ repEmpty(rowData.scope.row[rowData.prop], "--") }}
              </div>
            </template>
          </cm-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import mixinf from "@/app/common/mixins/mixin_func.js";
import {
  settlementDataDetailsShortTitle,
  settlementDataDetailsLongTitle,
} from "../titleOptions";
import cmTable from "../../../components/common/cm-table.vue";
export default {
  components: { cmTable },
  mixins: [mixinf],
  data() {
    return {
      dataList: [],
      shortTitle: settlementDataDetailsShortTitle,
      longTitle: settlementDataDetailsLongTitle,
    };
  },
  methods: {
    //#region 接口数据
    ...mapActions({
      orderDateList: "ZD_OrderDateList",
    }),
    iOrderDateList(id) {
      this.orderDateList({ orderid: id }).then((res) => {
        if (res.success == 1) {
          res.listitem.forEach((elem) => {
            elem.settleTime = elem.cutofftime.split(" ")[0];
            elem.amount = elem.price;
          });
          this.timeListHandle(res.listitem);
        }
      });
    },
    //#endregion
    init(list) {
      if (typeof list != "object") this.iOrderDateList(list);
      else this.timeListHandle(list);
    },
    /**结算日期详情列表数据处理 */
    timeListHandle(list) {
      this.dataList = [];
      let tempHasYear = (year) => {
        for (const key in this.dataList) {
          if (this.dataList[key].year == year) return key;
        }
        return -1;
      };
      list.forEach((elem) => {
        if (this.isEmpty(elem.settleTime)) return;
        let tempYear = elem.settleTime.split("-")[0],
          tempKey;
        tempKey = tempHasYear(tempYear);
        if (tempKey == -1) {
          this.dataList.push({
            year: tempYear,
            listAmount: 1,
            listitem: [
              {
                index: 1,
                date: elem.settleTime,
                amount: elem.amount,
              },
            ],
          });
        } else {
          let tempItem = this.dataList[tempKey];
          tempItem.listitem.push({
            index: ++tempItem.listAmount,
            date: elem.settleTime,
            amount: elem.amount,
          });
        }
      });
      //将listitem切分处理
      this.dataList.forEach((elem) => {
        let tempRowAmount =
            elem.listAmount % 2 == 0
              ? elem.listAmount / 2
              : (elem.listAmount + 1) / 2,
          tempListItem = [];
        elem.listitem.forEach((item, key) => {
          if (tempRowAmount >= key + 1) tempListItem.push(item);
          else {
            let tempItem = tempListItem[key - tempRowAmount];
            tempItem.index2 = item.index;
            tempItem.date2 = item.date;
            tempItem.amount2 = item.amount;
          }
        });
        elem.listitem = tempListItem;
      });
    },
    onClickGoBack() {
      this.$parent.onClickGoBack(1);
    },
  },
};
</script>

<style lang="less" scoped>
.date_box {
  height: calc(100vh - 270px);
  overflow: auto;
}
.table_box {
  margin-top: 5px;
  margin-bottom: 32px;
}
.shortTable {
  width: 415px;
}
.longTable {
  width: 1000px;
}
.year_box {
  font-size: 14px;
  color: #333;
  .year {
    font-size: 24px;
    color: #409eff;
    font-weight: 700;
  }
  .color_grey {
    color: #666;
  }
  .settlement_amount {
    margin-left: 25px;
    margin-right: 8px;
  }
  .settlement_amount_text {
    color: #409eff;
  }
}
.goback {
  cursor: pointer;
  display: flex;
  width: 70px;
  padding-bottom: 15px;
  margin-bottom: 5px;
  align-items: center;
  color: #333;
  span {
    display: inline-block;
    font-size: 14px;
    padding-left: 8px;
  }
  i {
    font-size: 14px;
  }
}
.goback:hover {
  opacity: 0.5;
}
.title {
  font-size: 14px;
  color: #333;
  font-weight: 700;
  margin-bottom: 20px;
}
</style>
