// 废弃脚本
<template>
  <div class="common_bg">
    <el-form inline>
      <el-form-item label="生产订单号" class="form_margin">
        <el-input
          placeholder="请输入生产订单号"
          v-model="reqParams.ordername"
          :maxlength="20"
        ></el-input>
      </el-form-item>
      <el-form-item label="设备类型" class="form_margin">
        <el-select
          v-model="reqParams.devicetypeid"
          placeholder="请选择"
          @change="deviceSelChange"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="导入设备数量" class="form_margin">
        <el-input
          placeholder="请输入设备数量"
          v-model="reqParams.devicecount"
          :maxlength="20"
        ></el-input>
      </el-form-item>
      <el-form-item label="设备版本号" class="form_margin">
        <el-select v-model="reqParams.versionid" placeholder="请选择">
          <el-option
            v-for="item in versionOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item class="form_margin">
        <el-upload
          action=""
          class="upload-demo"
          :auto-upload="false"
          :show-file-list="false"
          multiple
          :limit="999"
          :on-change="handleChange"
        >
          <el-button type="primary" plain>产品入库</el-button>
        </el-upload>
      </el-form-item>
      <el-form-item class="form_margin">
        <el-button type="primary" @click="deviceImport">确定导入</el-button>
      </el-form-item>
    </el-form>
    <el-form inline>
      <el-form-item label="备注">
        <el-input
          style="width: 80vw"
          placeholder="请输入备注"
          v-model="reqParams.remark"
          :maxlength="100"
        ></el-input>
      </el-form-item>
    </el-form>
    <div style="margin: 0px 0 20px 10px">
      <p class="font14" style="color: #666666">
        错误信息：<span style="color: red">{{ errorMassage }}</span>
      </p>
    </div>
    <div class="aline"></div>
    <el-table :data="tableData" style="width: 100%; margin-top: 10px">
      <!-- 废弃脚本 -->
      <el-table-column label="序号" type="index" width="100"></el-table-column>
      <el-table-column
        v-for="(item, index) in titleList"
        :key="index"
        :prop="item.prop"
        align="left"
        :limit="1"
        :min-width="item.width"
        :label="item.label"
        :show-overflow-tooltip="true"
        :formatter="formatter"
      >
      </el-table-column>
      <el-table-column
        v-if="showTabelError"
        prop="failReason"
        label="错误原因"
      ></el-table-column>
    </el-table>
    <div style="height: 65px"></div>
    <div>
      <span id="test_infoCount"
        >{{ $t("lang.common") }}{{ totalItemCount }}{{ $t("lang.strip") }}</span
      >
    </div>
  </div>
</template>
<script>
import { importTitleList } from "./titleList";
import XLSX from "xlsx";
import { mapActions } from "vuex";
export default {
  name: "",
  components: {},
  data() {
    return {
      tableData: [],
      titleList: importTitleList,
      options: [],
      versionOptions: [],
      reqParams: {
        devicecount: "",
        devicetypeid: null,
        remark: "",
        versionid: "",
        orderid: "",
        ordername: "",
        listItem: [],
      },
      totalItemCount: 0,
      errorMassage: "",
      regular: "",
      showTabelError: false,
    };
  },
  created() {
    this.reqParams.orderid = this.$route.params.orderid;
  },
  mounted() {
    this.getTypeList();
  },
  methods: {
    ...mapActions({
      typeList: "ZD_DeviceTypedefList",
      versionList: "ZD_VersionList",
      orderSubmit: "ZD_ProductionOrderSubmit",
    }),
    getTypeList() {
      let option = [];
      let params = {
        commtype: null,
        devicetype: null,
        nameSearch: "",
        pageNum: 1,
        pageSize: 99,
        paymode: null,
        protocolid: null,
      };
      this.typeList(params).then((res) => {
        if (res.success == 1) {
          res.listitem &&
            res.listitem.map((item) => {
              option.push({
                label: item.discShow,
                value: item.id,
                regular: item.regular,
              });
            });
        }
      });
      this.options = option;
    },
    deviceSelChange(e) {
      console.log(e);
      if (e || e === 0) {
        this.regular = this.options.find((item) => item.value == e).regular;
      }
      let option = [];
      this.versionList({ typedefid: e, versionid: null }).then((res) => {
        if (res.success == 1) {
          res.listitem &&
            res.listitem.map((item) => {
              option.push({
                label: item.discShow,
                value: item.id,
                software: item.software,
              });
            });
        }
      });
      this.versionOptions = option;
    },
    getOrderList() {
      this.orderList(this.reqParams).then((res) => {
        if (res.success == 1) {
          this.tableData = res.listitem;
          this.totalItemCount = res.totalItemCount;
        } else {
          this.tableData = [];
        }
      });
    },
    judgeDeviceInfo() {
      let ret = true;
      if (this.reqParams.ordername == "") {
        this.$message.error("请导入入库文件");
        ret = false;
      } else if (this.reqParams.devicetypeid == "") {
        this.$message.error("请选择设备类型");
        ret = false;
      } else if (this.reqParams.devicecount == "") {
        this.$message.error("请输入导入设备数量");
        ret = false;
      } else if (this.reqParams.versionid == "") {
        this.$message.error("选择设备版本号");
        ret = false;
      }
      return ret;
    },
    deviceImport() {
      if (!this.judgeDeviceInfo()) {
        return;
      }
      let errorMassage = "";
      let list = this.tableData;
      let errinmeterno = false;
      let errcommaddress = false;
      let errimei = false;
      let errsimcode = false;
      let errversion = false;
      let errqmeterno = false;
      let inmeternoregular = false;
      let versionname = "";
      console.log(list);
      let regular = new RegExp(this.regular);
      console.log(regular);
      if (this.reqParams.versionid) {
        versionname = this.versionOptions.find(
          (item) => item.value == this.reqParams.versionid
        ).software;
      }
      if (this.totalItemCount != this.reqParams.devicecount) {
        errorMassage += "导入设备数量不一致、";
      }
      list.map((item, index) => {
        list.map((ele, idx) => {
          if (index != idx) {
            if (ele.inmeterno && item.inmeterno == ele.inmeterno) {
              errinmeterno = true;
            }
            if (ele.commaddress && item.commaddress == ele.commaddress) {
              errcommaddress = true;
            }
            if (ele.imei && item.imei == ele.imei) {
              errimei = true;
            }
            if (ele.simcode && item.simcode == ele.simcode) {
              errsimcode = true;
            }
            if (ele.qmeterno && item.qmeterno == ele.qmeterno) {
              console.log(item.qmeterno, ele.qmeterno);
              errqmeterno = true;
            }
          }
        });
        if (versionname && item.version != versionname) {
          errversion = true;
        }

        if (!regular.test(item.commaddress)) {
          inmeternoregular = true;
        }
      });

      if (errinmeterno) {
        errorMassage += "资产号存在重复、";
      }
      if (errcommaddress) {
        errorMassage += "通讯地址存在重复、";
      }
      if (inmeternoregular) {
        errorMassage += "通讯地址格式错误、";
      }
      if (errimei) {
        errorMassage += "IMEI号存在重复、";
      }
      if (errsimcode) {
        errorMassage += "SIM卡号存在重复、";
      }
      if (errqmeterno) {
        errorMassage += "预付费号存在重复、";
      }
      if (!versionname) {
        errorMassage += "设备版本号未选择、";
      }
      if (errversion) {
        errorMassage += "设备版本号不统一、";
      }
      this.errorMassage = errorMassage;
      console.log("errorMassage>>>", errorMassage);
      if (errorMassage == "") {
        this.putInStorage();
      }
    },
    putInStorage() {
      this.reqParams.listItem = this.tableData;
      this.orderSubmit(this.reqParams).then((res) => {
        if (res.success == 1) {
          this.$message.success("导入成功");
          this.$router.push("/equipmentputstorage");
        } else {
          this.showTabelError = res.failList && res.failList.length > 0;
          res.failList && (this.tableData = res.failList);
        }
      });
    },
    formatter(row, { property }) {
      let ret = null;
      switch (property) {
        case "yearfeeflag":
          ret = row[property] ? "收取" : "不收取";
          break;
        case "nbstatus":
          if (row[property] == 0) {
            ret = "未注册";
          } else if (row[property] == 1) {
            ret = "注册中";
          } else if (row[property] == 2) {
            ret = "注册成功";
          } else if (row[property] == 3) {
            ret = "注册失败";
          } else {
            ret = "未知";
          }
          break;
        default:
          ret = row[property] ? row[property] : row[property] == 0 ? 0 : "--";
          break;
      }
      return ret;
    },
    dealExcelData(list) {
      let data = [];
      list.map((item) => {
        let obj = {
          inmeterno: null,
          commaddress: null,
          yearfeeflag: null,
          imei: null,
          boxnum: null,
          simcode: null,
          flow: null,
          activatecard: null,
          duecard: null,
          version: null,
          qmeterno: null,
        };
        for (let key in item) {
          switch (key) {
            case "资产号":
              obj.inmeterno = item[key];
              break;
            case "通讯地址":
              obj.commaddress = item[key];
              break;
            case "是否收取年费":
              obj.yearfeeflag = item[key];
              break;
            case "IMEI号":
              obj.imei = item[key];
              break;
            case "所属纸箱箱号":
              obj.boxnum = item[key];
              break;
            case "SIM卡号":
              obj.simcode = item[key];
              break;
            case "流量（M）":
              obj.flow = item[key];
              break;
            case "开卡时间":
              obj.activatecard = item[key];
              break;
            case "到期时间":
              obj.duecard = item[key];
              break;
            case "预付费号":
              obj.qmeterno = item[key];
              break;
            case "版本号":
              obj.version = item[key];
              break;
            default:
              break;
          }
        }

        data.push(obj);
      });
      this.tableData = data;
      this.totalItemCount = data.length;
    },
    handleChange(file) {
      // let file = file.files[0] // 使用传统的input方法需要加上这一步
      const types = file.name.split(".")[1];
      const fileType = ["xlsx", "xlc", "xlm", "xls", "xlt", "xlw", "csv"].some(
        (item) => item === types
      );
      if (!fileType) {
        alert("格式错误！请重新选择");
        return;
      }
      this.file2Xce(file).then((tabJson) => {
        // console.log(tabJson);
        if (tabJson && tabJson.length > 0) {
          this.xlsxJson = tabJson;
          this.dealExcelData(tabJson[0].sheet);
          // xlsxJson就是解析出来的json数据,数据格式如下
          // [
          //   {
          //     sheetName: sheet1
          //     sheet: sheetData
          //   }
          // ]
        }
      });
    },
    file2Xce(file) {
      this.showTabelError = false;
      this.reqParams.ordername = this.reqParams.orderid =
        file.name.split(".")[0];
      return new Promise(function (resolve, reject) {
        const reader = new FileReader();
        reader.onload = function (e) {
          const data = e.target.result;
          this.wb = XLSX.read(data, {
            type: "binary",
          });
          const result = [];
          console.log(this.wb);
          this.wb.SheetNames.forEach((sheetName) => {
            result.push({
              sheetName: sheetName,
              sheet: XLSX.utils.sheet_to_json(this.wb.Sheets[sheetName]),
            });
          });
          resolve(result);
        };
        reader.readAsBinaryString(file.raw);
        // console.log(file);
        // reader.readAsBinaryString(file) // 传统input方法
      });
    },
  },
  computed: {},
  watch: {},
  filters: {},
  //keepAlive
  activated() {},
  deactivated() {},
  updated() {},
  destroyed() {},
};
</script>
<style lang='less' scoped>
.form_margin {
  margin-right: 14px;
}
// /deep/.el-button--primary.is-plain {
//   background-color: #fff;
//   color: #409eff;
// }
#test_infoCount {
  font-size: 14px;
  color: #999;
  position: absolute;
  bottom: 42px;
  left: 30px;
}
.bottom_page {
  position: absolute;
  bottom: 36px;
  // bottom: 13px;
  right: 20px;
}
.aline {
  height: 5px;
  width: 100%;
  background-color: #f1f1f1;
  margin-bottom: 20px;
}
</style>