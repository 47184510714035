const tableList = [
    {
        label: "lang.num",
        key: "num",
        width: "80"
    },
    {
        label: "lang.apmetername",
        key: "metername",
        width: "200"
    },
    {
        label: "lang.apmeterid",
        key: "qmeterno",
        width: "150"
    },
    {
        label: "lang.totalusepw",
        key: "totalvalue",
        width: "150"
    },
    {
        label: "lang.monthusepw",
        key: "monthuse",
        width: "150"
    },
    {
        label: "lang.pricepw",
        key: "pricevalue",
        width: "200"
    },
    {
        label: "lang.devicetype",
        key: "metertype",
        width: "150"
    },
    {
        label: "lang.devicestate",
        key: "state",
        width: "120"
    },
    {
        label: "lang.freshtime",
        key: "datatime",
        width: "180"
    },
    {
        label: "lang.operate",
        key: "operate",
        // width: "200"
    },
]

const operateMenu = [
    {
        title: "lang.setapportion",
        value: 1,
    },
    {
        title: "lang.untyingdevice",
        value: 2,
    },
    {
        title: "lang.editprice",
        value: 3,
    },
    {
        title: "lang.devicereplace",
        value: 4,
    }
]

export default {
    tableList,
    operateMenu,
}