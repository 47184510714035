<template>
  <div class="device_tab">
    <!-- 表格信息 -->
    <el-table
      :data="dataList"
      :show-summary="false"
      style="width: 100%; margin-top: 20px"
      height="65vh"
      v-el-table-infinite-scroll="load"
    >
    <el-table-column :label="$t('lang.num')" align="left" type="index" width="50">
      </el-table-column>
      <el-table-column
        v-for="(item, index) in titleList"
        :key="index"
        :prop="item.prop"
        :width="item.width?item.width:'150'"
        :align="item.align ? item.align : 'left'"
        :label="$t(item.label)"
      >
        <template slot-scope="scope">
          <div
            v-if="item.prop == 'createtime'"
            style="width: 200px; text-align: left"
          >
            {{ scope.row[item.prop] | timeFormat }}
          </div>
          <div v-else-if="item.prop == 'money'" >{{ scope.row[item.prop] | toNormalFixed }}</div>
          <div v-else-if="item.prop == 'status'" >{{ scope.row[item.prop] | orderStatus }}</div>
          <div v-else-if="item.prop == 'paytype'" >{{ scope.row[item.prop] | payStatus }}</div>
          <div v-else>{{ scope.row[item.prop] || '--' }}</div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { option_line, titleList } from "./title";
import { MyChart } from "@/app/components";
import moment from "moment";
export default {
  components: { MyChart },
  data() {
    return {
      reqParams: {
        pointid: null,
        pageSize: 15,
        pageNum: 1,
      },
      dataList: [],
      titleList: titleList,
      loading: false,
      endShow:false,
    };
  },
  methods: {
    ...mapActions({
      recordList: "ZD_DeviceRecord",
    }),
    setData(e) {
      this.endShow = false;
      this.reqParams.pageNum = 1;
      this.reqParams.pointid = e.meterId;
      this.reqParams.deviceType = e.type;
      this.getDataList();
    },
    getDataList() {
      this.recordList(this.reqParams).then((res) => {
        // console.log(res);
        if (res.success == 1) {
          if (res.listitem.length > 0) {
            if (this.reqParams.pageNum>1) {
                res.listitem.map(item=>{
                  this.dataList.push(item);
                })
            }else{
              this.dataList = res.listitem;
              // res.listitem.map(item=>{
              //     this.dataList.push(item);
              //   })
            }
          } else {
            this.endShow = true;
            this.reqParams.pageNum--;
          }
        }
      });
    },
    load () {
        if (this.endShow) {
          return;
        }
        console.log("loading");
        this.reqParams.pageNum++;
        this.getDataList();
      },
    //查询
    search() {
      this.getDataList();
    },
  },
};
</script>

<style lang="less" scoped>
.device_tab {
  margin-top: 22px;
}
</style>