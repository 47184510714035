const titleList = [{
        label: "lang.devicename", //设备名称
        prop: "disc",
    },
    {
        label: "lang.assetno", //资产号
        prop: "qmeterno",
        // align: "left"
    },
    {
        label: "lang.equipmenttype", //设备类型
        prop: "devicetype",
    },
    {
        label: "lang.subposition", //所属位置
        prop: "roomdisc",
        width: "200"
    },
    {
        label: "lang.networkstate", //组网状态
        prop: "isnetwork"
    },
    {
        label: "lang.status", //状态
        prop: "mstatus",
    },
    {
        label: "lang.refreshtime", //刷新时间
        prop: "updatetime",
        width: "200"
    },
]

export {
    titleList
}