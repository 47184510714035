// tools文件夹用于放置共用方法
//正则
/**
 * password  //新密码只能使用大小写字母，并且至少1个大写字母、1个小写字母和1个数字!
 * phone  //手机号正则
 * email   //邮箱正则
 * idcard  //身份证号
 * bandCard //银行卡
 * businessRegistration //企业  社会统一信用代码
 * pInteger  //匹配非负整数（正整数 + 0）
 * fixed2   // 限制只能为数字并且数字最多带2位小数
 */
const regexp = {
    password: /^(?=.*[0-9].*)(?=.*[A-Z].*)(?=.*[a-z].*)[0-9A-Za-z]{6,20}$/,
    phone: /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/,
    email: /^([a-zA-Z0-9]+[_|_|\-|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/,
    idcard: /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/,
    bandCard: /^([1-9]{1})(\d{14}|\d{18})$/,
    businessRegistration: /^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\d{14})$/,
    pInteger: /^([0]|[1-9][0-9]*)$/,
    numFixed2: /^\d+(\.\d{0,2})?$/,
}


export {
    regexp
}