const titleList = [{
        label: "lang.total_y", //总额
        prop: "money",
        align: "center"
    },
    {
        label: "lang.paymentcontent", //缴费内容
        prop: "goodname",
        // align: "left"
    },
    {
        label: "lang.createtime", //创建时间
        prop: "createtime",
        width: "200"
    },
    {
        label: "lang.paymethod", //支付方式
        prop: "paytype"
    },
    {
        label: "lang.orderstatus", //订单状态
        prop: "status"
    },
    {
        label: "lang.ordernum", //订单号
        prop: "outtradeno",
        width: "300"
    },
]

export {
    titleList
}