<template>
  <div class="common_bg" id="sign1">
    <el-form inline>
      <el-form-item label="生产订单号">
        <el-input
          placeholder="请输入生产订单号"
          v-model="reqParams.orderidSearch"
          @keyup.enter.native="refrash"
        ></el-input>
      </el-form-item>
      <el-form-item label="设备类型" class="form_margin">
        <el-select v-model="reqParams.devicetypeid" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="form_margin">
        <el-button type="primary" @click="refrash">查询</el-button>
      </el-form-item>
      <!-- <el-form-item class="form_margin">
        <el-button type="primary" plain
          ><a
            :href="`${path}import.xls`"
            style="color: #409eff"
            download="设备入库模版.xls"
            >模板下载</a
          >
        </el-button>
      </el-form-item> -->
      <el-form-item class="form_margin">
        <el-button type="primary" plain @click="deviceImport"
          >产品入库</el-button
        >
      </el-form-item>
      <el-form-item class="form_margin" v-if="false">
        <el-button type="primary" plain @click="onClickEquipExport"
          >未关联设备导出</el-button
        >
      </el-form-item>
    </el-form>
    <el-table
      :data="tableData"
      style="width: 100%; margin-top: 10px"
      v-loading="loading"
    >
      <el-table-column
        label="序号"
        type="index"
        :index="getIndex"
      ></el-table-column>
      <el-table-column
        v-for="(item, index) in titleList"
        :key="index"
        :prop="item.prop"
        align="left"
        :limit="1"
        :min-width="item.width"
        :label="item.label"
        :show-overflow-tooltip="true"
        :formatter="formatter"
      >
      </el-table-column>
      <el-table-column label="操作" width="120">
        <template slot-scope="scope" class="_row">
          <el-button @click="detail(scope.row)" type="text">详情</el-button>
          <el-button type="text">|</el-button>
          <el-button
            @click="deleteOrder(scope.row)"
            type="text"
            style="color: #f56c6c"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div style="height: 65px"></div>
    <div>
      <el-pagination
        class="bottom_page"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="10"
        :total="totalItemCount"
        :current-page.sync="reqParams.pageNum"
      >
      </el-pagination>
    </div>
    <order-delete ref="orderDelete"></order-delete>
  </div>
</template>
<script>
import orderDelete from "./dialog/order-delete.vue";
import { tableTitleList } from "./titleList";
import XLSX from "xlsx";
import { mapActions } from "vuex";
export default {
  name: "EquipmentPutStorage",
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$nextTick(() => {
        vm.addKeepAlive("EquipmentPutStorage");
        vm.toUpdate();
      });
    });
  },
  components: { orderDelete },
  data() {
    return {
      tableData: [],
      titleList: tableTitleList,
      options: [],
      path: process.env.BASE_URL,
      loading: false,
      reqParams: {
        devicetypeid: null,
        orderidSearch: "",
        pageNum: 1,
        pageSize: 10,
        pageNumFinish: 1,
      },
      totalItemCount: 0,
    };
  },
  created() {
    this.getTypeList();
    this.getOrderList();
  },
  mounted() {},
  methods: {
    getIndex(idx) {
      return (
        idx + 1 + (this.reqParams.pageNumFinish - 1) * this.reqParams.pageSize
      );
    },
    toUpdate() {
      if (this.$route.query.isUpdate) {
        this.$route.query.isUpdate = false;
        this.refrash();
      }
    },
    ...mapActions({
      addKeepAlive: "addKeepAlivePage",
      typeList: "ZD_DeviceTypedefList",
      orderList: "ZD_ProductionOrderList",
    }),
    handleSizeChange(size) {
      this.reqParams.pageSize = size;
      this.getOrderList();
    },
    handleCurrentChange(e) {
      this.reqParams.pageNum = e;
      this.getOrderList();
    },
    refrash() {
      this.reqParams.pageNum = 1;
      this.getOrderList();
    },
    deleteOrder(e) {
      console.log(e);
      this.$refs.orderDelete.init(e);
    },
    getOrderList() {
      this.loading = true;
      this.orderList(this.reqParams)
        .then((res) => {
          this.loading = false;
          if (res.success == 1) {
            this.tableData = res.listitem;
            this.totalItemCount = res.totalItemCount;
          } else {
            this.tableData = [];
          }
        })
        .catch((err) => {
          this.loading = false;
        })
        .finally((f) => {
          this.reqParams.pageNumFinish = this.reqParams.pageNum;
        });
    },
    detail(e) {
      this.$store.commit("device/orderid", e.orderid);
      this.$router.push({
        name: "EquipmentDetail",
        params: {
          orderid: e.orderid,
        },
      });
    },
    getTypeList() {
      let option = [{ label: "全部", value: null }];
      let params = {
        commtype: null,
        devicetype: null,
        nameSearch: "",
        pageNum: 1,
        pageSize: 99999,
        paymode: null,
        protocolid: null,
      };
      this.typeList(params).then((res) => {
        if (res.success == 1) {
          res.listitem &&
            res.listitem.map((item) => {
              option.push({
                label: item.discShow,
                value: item.id,
              });
            });
        }
      });
      this.options = option;
    },
    deviceImport() {
      this.$router.push("/equipmentstore");
      // this.$router.push("/equipmentimport");
    },
    formatter(row, { property }) {
      if (property == "loginsuccess") {
        return row["type"] != 2
          ? "--"
          : row[property] + "/" + row["devicecount"];
      }
      return row[property] ? row[property] : row[property] === 0 ? 0 : "--";
    },
    /**未关联设备导出 */
    onClickEquipExport() {
      this.$download(
        {},
        [
          {
            label: "资产号",
            prop: "inmeterno",
          },
          {
            label: "通讯地址",
            prop: "commaddress",
          },
          {
            label: "生产订单号",
            prop: "orderid",
          },
          {
            label: "所属纸箱箱号",
            prop: "boxnum",
          },
          {
            label: "IMEI",
            prop: "imei",
          },
          {
            label: "ICCID",
            prop: "simcode",
          },
          {
            label: "流量（M）",
            prop: "flow",
          },
          {
            label: "开卡时间",
            prop: "activatecard",
          },
          {
            label: "到期时间",
            prop: "duecard",
          },
          {
            label: "预付费号",
            prop: "qmeterno",
          },
          {
            label: "版本号",
            prop: "versionid",
          },
          {
            label: "是否收取年费",
            prop: "yearfeeflag",
          },
        ],
        "Export_NotAssociatedDevice",
        {
          yearfeeflag: {
            0: "否",
            1: "是",
          },
        },
        "未关联设备导出"
      );
    },
  },
  computed: {},
  watch: {},
  filters: {},
  //keepAlive
  activated() {},
  deactivated() {},
  updated() {},
  destroyed() {},
};
</script>
<style lang='less' scoped>
.form_margin {
  margin-left: 14px;
}
/deep/.el-button--primary.is-plain {
  background-color: #fff;
  color: #409eff;
}
#test_infoCount {
  font-size: 14px;
  color: #999;
  position: absolute;
  bottom: 42px;
  left: 30px;
}
.bottom_page {
  position: absolute;
  bottom: 36px;
  // bottom: 13px;
  right: 20px;
}

#sign1 /deep/ .el-table--medium td,
.el-table--medium th {
  padding: 8px 0;
}
</style>