<template>
  <div class="common_bg">
    <div id="sign1">
      <el-form inline>
        <el-form-item>
          <el-button type="primary" :disabled="loading">
            <a
              :href="`${path}template1.xls`"
              style="color: #fff"
              download="设备入库模版.xls"
              >下载模板</a
            >
          </el-button>
        </el-form-item>
        <el-form-item>
          <el-upload
            action=""
            class="upload-demo"
            :auto-upload="false"
            :show-file-list="false"
            multiple
            :limit="999"
            :on-change="handleChange"
            :disabled="loading"
          >
            <el-button type="primary" plain :disabled="loading"
              >加载订单</el-button
            >
          </el-upload>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            plain
            @click="onClickWarehousing"
            :disabled="loading"
            >入库</el-button
          >
        </el-form-item>
        <!-- <el-form-item>
          <el-button type="primary" plain @click="onClickUploadFile"
            >上传附件</el-button
          >
        </el-form-item> -->
      </el-form>
    </div>
    <div v-loading="loading" :element-loading-text="loadingMsg">
      <div :class="[errorList.length < 1 ? 'sign3' : '', 'sign2']">
        <el-tabs v-model="activeName" @tab-click="handleClick" ref="tabs">
          <el-tab-pane label="订单信息" name="a">
            <div class="order_message_box">
              <div class="item_box">
                订单号
                <span>{{ repEmpty(orderMsg.orderId, "--") }}</span>
              </div>
              <div class="item_box">
                设备类型
                <span>{{ repEmpty(orderMsg.equipType, "--") }}</span>
              </div>
              <div class="item_box">
                软件版本号
                <span>{{ repEmpty(orderMsg.version, "--") }}</span>
              </div>
              <div class="item_box">
                设备数量
                <span>{{ repEmpty(orderMsg.equipNum, "--") }}</span>
              </div>
            </div>
            <div class="remark_box">
              <div class="item_box">
                备注<span>{{ repEmpty(orderMsg.remark, "--") }}</span>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="错误信息" name="b">
            <div class="error_msg_box">
              <div v-for="(item, index) in errorList" :key="index">
                {{ index + 1 }}、{{ item }}
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <el-table
        :data="tableData"
        style="width: 100%; margin-top: 10px"
        max-height="500"
      >
        <el-table-column
          v-for="(item, index) in tableTitle"
          :key="index"
          :prop="item.prop"
          align="left"
          :limit="1"
          :label="item.label"
          :show-overflow-tooltip="true"
          :formatter="formatter"
          :width="item.width"
        >
        </el-table-column>
        <template slot="empty">
          <span>没有错误数据</span>
        </template>
      </el-table>
    </div>
  </div>
</template>
<script>
import XLSX, { WorkSheet } from "xlsx";
import { mapActions } from "vuex";
import { storeTitleList } from "./titleList";
import mixinf from "../../common/mixins/mixin_func";
export default {
  name: "EquipmentStore",
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$nextTick(() => {
        vm.addKeepAlive("EquipmentStore");
      });
    });
  },
  mixins: [mixinf],
  data() {
    return {
      path: process.env.BASE_URL,
      activeName: "a",
      tableTitle: storeTitleList,
      tableData: [],
      excelData: [],
      orderMsg: {
        orderName: "",
        orderId: "",
        equipType: "",
        version: "",
        equipNum: "",
        remark: "",
        commaddress: "",
      },
      req: {
        devicetypeid: "",
        versionId: "",
        listitem: [],
      },
      /**是否已加载订单 */
      isLoading: false,
      /**是否正在验证 */
      loading: false,
      loadingMsg: "加载中",
      /**验证次数 */
      verAmount: 0,
      /**错误信息列表 */
      errorList: [],
    };
  },
  mounted() {},
  methods: {
    //#region 错误信息维护
    addErrorMsg(msg) {
      let tempVer = true;
      for (const key in this.errorList) {
        if (this.errorList[key] == msg) {
          tempVer = false;
          break;
        }
      }
      if (tempVer) this.errorList.push(msg);
      if (tempVer && this.errorList.length < 2) this.activeName = "b";
    },
    clearErrorMsg() {
      this.activeName = "a";
      this.errorList = [];
    },
    //#endregion
    //#region 表格维护
    addTable(item) {
      if (this.tableData.length > 100) return;
      let tempVer = true;
      for (const key in this.tableData) {
        if (this.tableData[key].id === item.id) {
          tempVer = false;
          break;
        }
      }
      if (tempVer) this.tableData.push(item);
    },
    //#endregion
    //#region 接口数据
    ...mapActions({
      addKeepAlive: "addKeepAlivePage",
      delKeepAlive: "delKeepAlivePage",
      productionOrderCheckOrder: "ZD_ProductionOrderCheckOrder",
      productionOrderSubmit: "ZD_ProductionOrderSubmit",
    }),
    iProductionOrderSubmit() {
      this.loadingMsg = "正在入库中";
      this.loading = true;
      let tempReq = {
        devicecount: this.orderMsg.equipNum,
        devicetypeid: this.req.devicetypeid,
        orderid: this.orderMsg.orderId,
        ordername: this.orderMsg.orderName,
        remark: this.orderMsg.remark == "--" ? "" : this.orderMsg.remark,
        versionid: this.req.versionId,
        listItem: this.req.listitem,
      };
      this.productionOrderSubmit(tempReq).then((res) => {
        if (res.success === 1) {
          this.$message.success("入库成功");
          this.$router.push({
            path: "/equipmentputstorage",
            query: {
              isUpdate: true,
            },
          });
          // this.delKeepAlive("EquipmentStore");
          setTimeout(() => {
            this.$bus.$emit("navClose", "/equipmentstore");
          }, 20);
        } else {
          if (this.isEmpty(res.failList)) {
            this.addErrorMsg(res.errordetail);
          } else {
            res.failList.forEach((elem) => {
              this.addErrorMsg(elem.failReason);
            });
            this.setTableData(res.failList);
          }
        }
        this.loading = false;
      });
    },
    setTableData(list) {
      list.forEach((elem, idx) => {
        this.tableData.push({
          id: idx,
          assetId: this.repEmpty(elem.inmeterno, "--"),
          postalAddress: this.repEmpty(elem.commaddress, "--"),
          boxId: this.repEmpty(elem.boxnum, "--"),
          imel: this.repEmpty(elem.imei, "--"),
          sim: this.repEmpty(elem.simcode, "--"),
          prepaymentNo: this.repEmpty(elem.qmeterno, "--"),
          version: this.repEmpty(elem.version, "--"),
          annualFee: this.getAnnualFee(elem.yearfeeflag, "--"),
        });
      });
    },
    async iProductionOrderCheckOrder() {
      if (this.errorList.length > 0) {
        this.verOver();
        return;
      }
      this.productionOrderCheckOrder({
        commaddress: this.orderMsg.commaddress,
        deviceTypeShowName: this.orderMsg.equipType,
        version: this.orderMsg.version,
        successCheckType: 1,
      }).then((res) => {
        if (res.success === 1) {
          this.req.devicetypeid = res.deviceTypeId;
          this.req.versionId = res.versionId;
          this.iProductionOrderCheckOrderHandle(res.regular, res.regflag);
        } else {
          this.addErrorMsg(res.errordetail);
        }
        this.verOver();
      });
    },
    iProductionOrderCheckOrderHandle(reg, flag) {
      let toOnce = true,
        toOnce2 = true,
        toOnce3 = true,
        regular = new RegExp(reg);
      for (const key in this.excelData) {
        if (!regular.test(this.excelData[key].postalAddress)) {
          this.addTable(this.excelData[key]);
          if (toOnce) {
            toOnce = false;
            this.addErrorMsg("通讯地址格式错误");
          }
          if (this.tableData.length > 99) break;
        } else if (
          flag == 1 &&
          (this.isEmpty(this.excelData[key].prepaymentNo) ||
            this.excelData[key].prepaymentNo == "--")
        ) {
          this.addTable(this.excelData[key]);
          if (toOnce2) {
            toOnce2 = false;
            this.addErrorMsg("预付费号为空");
          }
          if (this.tableData.length > 99) break;
        } else if (
          flag == 0 &&
          !this.isEmpty(this.excelData[key].prepaymentNo) &&
          this.excelData[key].prepaymentNo != "--"
        ) {
          this.addTable(this.excelData[key]);
          if (toOnce3) {
            toOnce3 = false;
            this.addErrorMsg("当前设备不需要预付费号");
          }
          if (this.tableData.length > 99) break;
        }
      }
      return;
    },
    //#endregion
    /**tab切换 */
    handleClick() {
      //
    },
    /**表格过滤 */
    formatter(row, { property }) {
      return row[property];
    },
    //#region 上传文件
    handleChange(file) {
      this.clearErrorMsg();
      this.tableData = [];
      this.verAmount = 0;
      this.isLoading = true;
      this.loading = true;
      this.loadingMsg = "正在校验中";
      // let file = file.files[0] // 使用传统的input方法需要加上这一步
      const types = file.name.split(".")[1];
      const fileType = ["xlsx", "xlc", "xlm", "xls", "xlt", "xlw", "csv"].some(
        (item) => item === types
      );
      if (!fileType) {
        alert("格式错误！请重新选择");
        return;
      }
      setTimeout(() => {
        this.file2Xce(file)
          .then((tabJson) => {
            if (tabJson && tabJson.length > 0) {
              let tempList = tabJson[0].sheet,
                tempItem;
              if (tempList.length > 0) {
                tempItem = tempList[0];
                //生产订单号
                if (this.isEmpty(tempItem["生产订单号"])) {
                  this.orderMsg.orderId = "--";
                  this.addErrorMsg("生产订单号为空");
                } else this.orderMsg.orderId = tempItem["生产订单号"];
                //设备数量检测
                this.orderMsg.equipNum = tempItem["设备数量"];
                if (tabJson[1].sheet.length != this.orderMsg.equipNum) {
                  this.addErrorMsg("设备数量和实际数量不一致");
                }
                //设备类型和软件版本号检测
                let temp1 = tempItem["设备类型"],
                  temp2 = tempItem["软件版本号"];
                if (this.isEmpty(temp1)) {
                  this.orderMsg.equipType = "--";
                  this.addErrorMsg("设备类型为空");
                } else this.orderMsg.equipType = temp1;
                if (this.isEmpty(temp2)) {
                  this.orderMsg.version = "--";
                  this.addErrorMsg("软件版本号为空");
                } else this.orderMsg.version = temp2;

                this.orderMsg.remark = this.repEmpty(tempItem["备注"], "--");
              } else {
                this.addErrorMsg("没有检测到设备列表");
              }
              this.handleExcelData(tabJson[1].sheet, tabJson[0].sheet);
              //   this.xlsxJson = tabJson;
              //   this.dealExcelData(tabJson[0].sheet);
              //   console.log("导入文件数据：", tabJson);
              // xlsxJson就是解析出来的json数据,数据格式如下
              // [
              //   {
              //     sheetName: sheet1
              //     sheet: sheetData
              //   }
              // ]
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }, 300);
    },
    handleExcelData(list) {
      if (list.length > 0) this.orderMsg.commaddress = list[0]["通讯地址"];
      this.excelData = list.map((item, index) => {
        return {
          id: index,
          assetId: this.repEmpty(item["资产号"], "--"),
          postalAddress: this.repEmpty(item["通讯地址"], "--"),
          boxId: this.repEmpty(item["所属纸箱箱号"], "--"),
          imel: this.repEmpty(item["IMEI号"], "--"),
          sim: this.repEmpty(item["SIM卡号"], "--"),
          prepaymentNo: this.repEmpty(item["预付费号"], "--"),
          version: this.repEmpty(item["版本号"], "--"),
          annualFee: this.getAnnualFee(item["是否收取年费"], "--"),
        };
      });
      this.reqListItem(list);
      //后端传的正则验证
      this.iProductionOrderCheckOrder();
      //前端验证
      this.deviceImport();
    },
    /**获取入库数据 */
    reqListItem(list) {
      this.req.listitem = list.map((item, index) => {
        return {
          id: index,
          activatecard: this.repEmpty(item["开卡时间"], ""),
          boxnum: this.repEmpty(item["所属纸箱箱号"], ""),
          commaddress: this.repEmpty(item["通讯地址"], ""),
          duecard: this.repEmpty(item["到期时间"], ""),
          flow: this.repEmpty(item["流量（M）"], ""),
          imei: this.repEmpty(item["IMEI号"], ""),
          inmeterno: this.repEmpty(item["资产号"], ""),
          qmeterno: this.repEmpty(item["预付费号"], ""),
          simcode: this.repEmpty(item["SIM卡号"], ""),
          version: this.repEmpty(item["版本号"], ""),
          // version: this.repEmpty(item["版本号"], ""),
          yearfeeflag: this.repEmpty(item["是否收取年费"], ""),
        };
      });
    },
    file2Xce(file) {
      this.showTabelError = false;
      this.orderMsg.orderName = file.name.split(".")[0];
      return new Promise(function (resolve, reject) {
        const reader = new FileReader();
        reader.onload = function (e) {
          const data = e.target.result;
          this.wb = XLSX.read(data, {
            type: "binary",
          });
          const result = [];
          console.log(this.wb);
          this.wb.SheetNames.forEach((sheetName) => {
            result.push({
              sheetName: sheetName,
              sheet: XLSX.utils.sheet_to_json(this.wb.Sheets[sheetName]),
            });
          });
          resolve(result);
        };
        reader.readAsBinaryString(file.raw);
        // reader.readAsBinaryString(file) // 传统input方法
      });
    },
    getAnnualFee(idx) {
      if (this.isEmpty(idx)) return "--";
      if (parseInt(idx) === 0) return "不收取";
      if (parseInt(idx) === 1) return "收取";
      return "未知";
    },
    /**验证导入列表 */
    deviceImport() {
      if (this.errorList.length > 0) {
        this.verOver();
        return;
      }
      let tempListAsset = [],
        tempListPostal = [],
        tempListIMEI = [],
        tempListSIM = [],
        tempListPrepay = [];
      for (const key in this.excelData) {
        if (this.tableData.length > 99) {
          this.verOver();
          return;
        }
        //资产号
        if (this.excelData[key].assetId !== "--") {
          if (tempListAsset[this.excelData[key].assetId]) {
            this.addTable(this.excelData[key]);
            this.addErrorMsg("资产号存在重复");
          } else tempListAsset[this.excelData[key].assetId] = true;
        }
        //通讯地址
        if (this.excelData[key].postalAddress !== "--") {
          if (tempListPostal[this.excelData[key].postalAddress]) {
            this.addTable(this.excelData[key]);
            this.addErrorMsg("通讯地址存在重复");
          } else tempListPostal[this.excelData[key].postalAddress] = true;
        }
        //IMEI号
        if (this.excelData[key].imel !== "--") {
          if (tempListIMEI[this.excelData[key].imel]) {
            this.addTable(this.excelData[key]);
            this.addErrorMsg("IMEI号存在重复");
          } else tempListIMEI[this.excelData[key].imel] = true;
        }
        //SIM卡号
        if (this.excelData[key].sim !== "--") {
          if (tempListSIM[this.excelData[key].sim]) {
            this.addTable(this.excelData[key]);
            this.addErrorMsg("SIM卡号存在重复");
          } else tempListSIM[this.excelData[key].sim] = true;
        }
        //预付费号
        if (this.excelData[key].prepaymentNo !== "--") {
          if (tempListPrepay[this.excelData[key].prepaymentNo]) {
            this.addTable(this.excelData[key]);
            this.addErrorMsg("预付费号存在重复");
          } else tempListPrepay[this.excelData[key].prepaymentNo] = true;
        }
        //设备版本号
        if (this.orderMsg.version != this.excelData[key].version) {
          this.addTable(this.excelData[key]);
          this.addErrorMsg("设备版本号不统一");
        }
      }
      this.verOver();
    },
    verOver() {
      if (this.verAmount++ > 0) {
        this.loading = false;
        if (this.errorList.length < 1) this.$message.success("验证通过");
        else this.$message.error("验证错误");
      }
    },
    //#endregion

    onClickUploadFile() {
      //   this.iProductionOrderCheckOrder();
    },
    onClickWarehousing() {
      if (!this.isLoading) {
        this.$message.warning("请先加载订单");
        return;
      } else if (this.errorList.length > 0) {
        this.$message.warning("订单信息错误");
        return;
      }
      this.$confirm("您确定要将订单信息入库吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.iProductionOrderSubmit();
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="less" scoped>
#sign1 {
  /deep/ .el-form-item {
    margin-bottom: 24px;
  }
  /deep/ .el-form--inline .el-form-item {
    margin-right: 24px;
  }
  /deep/.el-button--primary.is-plain {
    background-color: #fff;
    color: #409eff;
  }
}
.sign2 {
  .order_message_box {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    padding: 24px 64px 0 64px;
    .item_box {
      color: #999;
      span {
        padding-left: 4px;
        color: #333;
      }
    }
  }
  .remark_box {
    color: #999;
    font-size: 14px;
    padding: 10px 64px 24px 77px;
    span {
      padding-left: 10px;
      color: #333;
    }
  }
  .error_msg_box {
    margin: 0 0px 16px 104px;
    box-sizing: border-box;
    height: 80px;
    font-size: 14px;
    color: #f56c6c;

    overflow: auto;

    div {
      display: inline-block;
      margin-right: 120px;
      margin-bottom: 20px;
      min-width: 140px;
    }
  }
  /deep/ .el-tabs {
    border: 1px solid #409eff;
  }
  /deep/ .el-tabs__header {
    background-color: rgba(64, 158, 255, 0.1);
    padding-left: 16px;
    margin-bottom: 13px;
  }
  /deep/ .el-tabs__item {
    padding: 0 16px;
    height: 45px;
    line-height: 45px;
  }
  /deep/ .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
    color: #333;
  }
  /deep/ .el-tabs--top .el-tabs__item.is-top:last-child {
    color: #f56c6c;
  }
}
.sign3 {
  /deep/ .el-tabs--top .el-tabs__item.is-top:last-child {
    display: none;
  }
}
</style>