<template>
  <el-dialog
    title="订单删除确认"
    :visible.sync="dialogVisible"
    width="600px"
    :before-close="handleClose"
    id="sign1"
  >
    <el-form :model="reqParams">
      <el-form-item label="订单号">
        <p class="font14 color666">{{ reqParams.orderid }}</p>
      </el-form-item>
      <el-form-item label="请输入删除原因">
        <el-input
          style="width: 300px"
          type="textarea"
          :rows="3"
          placeholder="请输入内容"
          v-model="reqParams.reason"
        >
        </el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="getDel">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "",
  components: {},
  data() {
    return {
      reqParams: {
        orderid: "",
        reason: "",
      },
      dialogVisible: false,
    };
  },
  created() {},
  mounted() {},
  methods: {
    ...mapActions({
      del: "ZD_ProductionOrderDelete",
    }),
    init(e) {
      this.reqParams.orderid = e.orderid;
      this.dialogVisible = true;
    },
    getDel() {
      if (this.reqParams.reason == "") {
        this.$message.warning("请输入删除原因");
        return;
      }
      this.del(this.reqParams)
        .then((res) => {
          if (res.success == 1) {
            this.dialogVisible = false;
            this.$parent.refrash();
            this.$message.success("删除成功");
          }
        })
        .catch((err) => {
          this.$message.error("删除失败");
        });
    },
    handleClose(done) {
      this.dialogVisible = false;
      this.reqParams = {
        deviceCount: 0,
        inmeternoList: [],
        orderid: "",
        reason: "",
      };
    },
  },
  computed: {},
  watch: {},
  filters: {},
  //keepAlive
  activated() {},
  deactivated() {},
  updated() {},
  destroyed() {},
};
</script>
<style lang='less' scoped>
#sign1 {
  /deep/ .el-form-item__label {
    width: 120px;
  }
  /deep/ .el-form-item__content {
    display: inline-block;
    width: 300px;
  }
  /deep/ .el-form-item {
    display: inline-block;
    margin-right: 20px;
  }
  /deep/ .el-form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>