<template>
  <div class="device_tab">
    <el-row justify="space-between" style="position: relative">
      <el-col style="width: 220px; margin-right: 40px">
        <el-date-picker
          v-if="reqParams.datatype == 1"
          v-model="time"
          align="right"
          type="date"
          :placeholder="$t('lang.tochoose')"
          :picker-options="pickerOptions"
          @change="timeChange($event)"
        >
        </el-date-picker>
        <el-date-picker
          v-if="reqParams.datatype == 2"
          v-model="time"
          type="month"
          :placeholder="$t('lang.tochoose')"
          @change="timeChange($event)"
        >
        </el-date-picker>
        <el-date-picker
          v-if="reqParams.datatype == 3"
          v-model="time"
          type="year"
          :placeholder="$t('lang.tochoose')"
          @change="timeChange($event)"
        >
        </el-date-picker>
      </el-col>
      <el-col style="width: 166px">
        <el-radio-group v-model="radioValue" @change="radioChange($event)">
          <el-radio-button :label="$t('lang.day')"></el-radio-button>
          <el-radio-button :label="$t('lang.month')"></el-radio-button>
          <el-radio-button :label="$t('lang.year')"></el-radio-button>
        </el-radio-group>
      </el-col>
      <el-col style="width: 60px; position: absolute; right: 0; top: -5px">
        <el-button
          icon="iconfont icon-daochu icon_margin"
          type="text"
          size="medium"
          @click="exportToLocal"
          >{{ $t("lang.export") }}</el-button
        >
      </el-col>
    </el-row>
    <my-chart
      :options="optionLine"
      :width="'100%'"
      :vHeight="'19.5vh'"
    ></my-chart>
    <!-- 表格信息 -->
    <el-table
      :data="dataList"
      :show-summary="false"
      style="width: 100%; margin-top: 20px"
      height="45vh"
      v-loading="loading"
    >
      <el-table-column
        v-for="(item, index) in titleList"
        :key="index"
        :prop="item.prop"
        width=""
        :align="item.align ? item.align : 'right'"
        :label="$t(item.label)"
      >
        <template slot-scope="scope">
          <el-popover
            placement="right"
            width="500"
            @show="popoverShow(scope.row)"
            trigger="click"
          >
            <el-table :data="gridData">
              <el-table-column
                prop="begintime"
                :label="$t('lang.startingtime')"
                :formatter="timeFormatter"
              ></el-table-column>
              <el-table-column
                prop="endtime"
                :label="$t('lang.endingtime')"
                :formatter="timeFormatter"
              ></el-table-column>
              <el-table-column
                width="140"
                prop="beginvalue"
                :label="$t('lang.initialread')"
                :formatter="valueFormatter"
              ></el-table-column>
              <el-table-column
                width="140"
                prop="endvalue"
                :label="$t('lang.endread')"
                :formatter="valueFormatter"
              ></el-table-column>
            </el-table>
            <template slot="reference">
              <div
                v-if="item.prop == 'begintime' || item.prop == 'endtime'"
                style="width: 200px; text-align: left"
              >
                <p v-if="reqParams.datatype == 1" class="font14">
                  {{ scope.row[item.prop] | timeFormat("HH:mm:ss") }}
                </p>
                <p v-else-if="reqParams.datatype == 2" class="font14">
                  {{ scope.row[item.prop] | timeFormat("YYYY-MM-DD 00:00") }}
                </p>
                <p v-else class="font14">
                  {{ scope.row[item.prop] | timeFormat("YYYY-MM-01 00:00") }}
                </p>
              </div>
              <div v-else>{{ scope.row[item.prop] | toNormalFixed }}</div>
            </template>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { option_line, titleList } from "./title";
import { MyChart } from "@/app/components";
import moment from "moment";
export default {
  components: { MyChart },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: this.$t("lang.today"),
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: this.$t("lang.yesterday"),
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: this.$t("lang.beforeweek"),
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      time: new Date(),
      radioValue: this.$t("lang.month"),
      reqParams: {
        deviceType: 0, //设备类型(0-电表;1-水表)
        pointId: null,
        datatype: 2, //数据类型(0-分钟;1-小时;2-天;3-月)
        pageSize: 99,
        pageNum: 1,
        starttime: moment().format("YYYY-MM-01 00:00:00"),
        endtime: moment().format("YYYY-MM-DD 23:59:59"),
      },
      gridData: [],
      optionLine: option_line,
      dataList: [],
      titleList: titleList,
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      hisList: "ZD_DeviceAnalysis",
      hisListDetail: "ZD_DeviceAnalysisDetail",
    }),
    popoverShow(e) {
      console.log(e);
      let params = this.reqParams;
      params.begintime = e.begintime;
      params.endtime = e.endtime;
      this.gridData = [];
      this.hisListDetail(params).then((res) => {
        if (res.success == 1) {
          this.gridData = [
            {
              begintime: res.begintime,
              beginvalue: res.beginvalue,
              endtime: res.endtime,
              endvalue: res.endvalue,
            },
          ];
        }
      });
    },
    exportToLocal() {
      this.$download(
        this.reqParams,
        this.titleList,
        "Export_AnalysisDownload",
        {},
        this.$t("lang.poweranalysis")
      );
    },
    setData(e) {
      this.reqParams.pointId = e.meterId;
      this.reqParams.deviceType = e.devicetype;
      this.radioValue = this.$t("lang.month");
      this.reqParams.datatype = 2;
      this.time = new Date();
      this.reqParams.starttime = moment().format("YYYY-MM-01 00:00:00");
      this.reqParams.endtime = moment().format("YYYY-MM-DD 23:59:59");
      this.getDataList();
    },
    getDataList() {
      this.hisList(this.reqParams).then((res) => {
        // console.log(res);
        if (res.success == 1) {
          if (res.listitem.length > 0) {
            this.dataList = res.listitem;
            setTimeout(() => {
              this.getChart(res.listitem);
            }, 100);
          } else {
            this.dataList = [];
            this.optionLine.xAxis[0].data = [0, 0, 0];
            this.optionLine.series[0].data = [0, 0, 0];
          }
        }
      });
    },
    valueFormatter(e, { property }) {
      if (e[property]) {
        return e[property];
      } else if (e[property] == 0) {
        return 0.0;
      } else {
        return "--";
      }
    },
    timeFormatter(e, { property }) {
      if (e[property]) {
        if (this.reqParams.datatype == 1) {
          return moment(e[property]).format("HH:mm:ss");
        } else if (this.reqParams.datatype == 2) {
          return moment(e[property]).format("YYYY-MM-DD");
        } else {
          return moment(e[property]).format("YYYY-MM-01 00:00:00");
        }
      } else {
        return "--";
      }
    },
    //查询
    search() {
      this.getChooseTime();
      this.getDataList();
    },
    timeChange(e) {
      // console.log(e);
      this.search();
    },
    //年月日选择
    radioChange(e) {
      this.time = new Date();
      switch (e) {
        case this.$t("lang.day"):
          this.reqParams.datatype = 1;
          break;
        case this.$t("lang.month"):
          this.reqParams.datatype = 2;
          break;
        case this.$t("lang.year"):
          this.reqParams.datatype = 3;
          break;
        default:
          break;
      }
      this.search();
    },
    getChooseTime() {
      //   console.log(this.time, this.reqParams.datatype);
      let i = this.reqParams.datatype;
      let time = this.time;
      switch (i) {
        case 1:
          this.reqParams.endtime = moment(time).format("YYYY-MM-DD 23:59:59");
          this.reqParams.starttime = moment(time).format("YYYY-MM-DD 00:00:00");
          break;
        case 2:
          this.reqParams.endtime = moment(time)
            .add({ months: 1 })
            .add({ days: -1 })
            .format("YYYY-MM-DD 23:59:59");
          this.reqParams.starttime = moment(time).format("YYYY-MM-01 00:00:00");
          break;
        case 3:
          this.reqParams.endtime = moment(time).format("YYYY-12-31 23:59:59");
          this.reqParams.starttime = moment(time).format("YYYY-01-01 00:00:00");
          break;
        default:
          break;
      }
    },
    //获取chart
    getChart(list) {
      this.optionLine.xAxis[0].data = [];
      this.optionLine.series[0].data = [];
      if (this.reqParams.datatype == 1) {
        for (let index = list.length - 1; index >= 0; index--) {
          this.optionLine.xAxis[0].data.push(
            moment(list[index].endtime).format("HH:mm:ss")
          );
          this.optionLine.series[0].data.push(list[index].importenergy);
        }
      } else if (this.reqParams.datatype == 2) {
        for (let index = list.length - 1; index >= 0; index--) {
          this.optionLine.xAxis[0].data.push(
            moment(list[index].endtime).format("MM-DD")
          );
          this.optionLine.series[0].data.push(list[index].importenergy);
        }
      } else if (this.reqParams.datatype == 3) {
        for (let index = list.length - 1; index >= 0; index--) {
          this.optionLine.xAxis[0].data.push(
            moment(list[index].endtime).format("YYYY-MM")
          );
          this.optionLine.series[0].data.push(list[index].importenergy);
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.device_tab {
  margin-top: 10px;
}
/deep/.icon_margin {
  margin-right: 6px;
}
</style>