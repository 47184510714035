const options = [{
        label: "全部",
        value: null
    },
    {
        label: "电信",
        value: 2
    },
    {
        label: "移动",
        value: 0
    },
    {
        label: "联通",
        value: 1
    },
    {
        label: "其他",
        value: -1
    },
]


const options1 = [{
        label: "全部",
        value: null
    },
    {
        label: "4G",
        value: 2
    },
    {
        label: "NB",
        value: 0
    },
    {
        label: "GPRS",
        value: 1
    },
    {
        label: "其他",
        value: -1
    },
]


const options2 = [{
        label: "电信",
        value: 2
    },
    {
        label: "移动",
        value: 0
    },
    {
        label: "联通",
        value: 1
    },
    {
        label: "其他",
        value: -1
    },
]


const options3 = [{
        label: "4G",
        value: 2
    },
    {
        label: "NB",
        value: 0
    },
    {
        label: "GPRS",
        value: 1
    },
    {
        label: "其他",
        value: -1
    },
]

const options4 = [{
        label: "全部",
        value: null
    },
    {
        label: "未激活",
        value: 0
    },
    {
        label: "在用",
        value: 1
    },
    {
        label: "注销",
        value: 3
    },
]

export {
    options,
    options1,
    options2,
    options3,
    options4
}