export default {
    data() {
        return {
            cmLocalStoreKeys: [],
        }
    },
    destroyed() {
        this.cmLocalStoreKeys.forEach(key => {
            localStorage.removeItem(key);
        });
        this.cmLocalStoreKeys = [];
    },
    methods: {
        //#region 存储
        cmStore(key, value, type = 1) {
            localStorage.setItem(key, value);
            let tempIsPush = true;
            for (const k in this.cmLocalStoreKeys) {
                if (this.cmLocalStoreKeys[k] == key) {
                    tempIsPush = false;
                    break;
                }
            }
            if (tempIsPush) this.cmLocalStoreKeys.push(key);
        },
        cmRead(key, type = 1) {
            return localStorage.getItem(key);
        },
        //#endregion
        /**四舍五入保留两位小数 */
        keepTwo(val, back = null, keepLen = 2) {
            if (val === undefined || val === "" || val === null || val.length < 1)
                return back == null ? val : back;
            return (val - 0).toFixed(keepLen);
        },
        /**空回boolearn */
        isEmpty(val) {
            if (val === undefined || val === "" || val === null || val.length < 1)
                return true;
            else return false;
        },
        /**空回替换 */
        repEmpty(val, rep = "") {
            if (val === undefined || val === "" || val === null || val.length < 1)
                return rep;
            else return val;
        },
        /**深拷贝 */
        deepCopy(val) {
            if (!val) return null;
            return JSON.parse(JSON.stringify(val));
        },
        /**字符串中有几个相同字符 (字符串，字符) */
        charCount(str, char) {
            str += "";
            let num = 0,
                index = str.indexOf(char);
            while (index !== -1) {
                num++;
                index = str.indexOf(char, index + 1);
            }
            return num;
        },
        /**监听事件 */
        listener(event, callBack = null) {
            this.$bus
                .$off(event)
                .$on(event, callBack);
        },
        /**发送事件 */
        send(event, data = null) {
            this.$bus.$emit(event, data);
        },
        //#region 时间
        /**获得时分秒 */
        getHMS() {
            let date = new Date();
            let hh = date.getHours();
            let mf = date.getMinutes();
            let ss = date.getSeconds();
            hh = hh > 9 ? hh : "0" + hh;
            mf = mf > 9 ? mf : "0" + mf;
            ss = ss > 9 ? ss : "0" + ss;
            return hh + ":" + mf + ":" + ss;
        },
        /**获得年月日 */
        getYMD() {
            let date = new Date();
            let yy = date.getFullYear();
            let mm = date.getMonth() + 1;
            let dd = date.getDate();
            mm = mm > 9 ? mm : "0" + mm;
            dd = dd > 9 ? dd : "0" + dd;
            return yy + "-" + mm + "-" + dd;
        },
        /**获得年月 */
        getYM() {
            let date = new Date();
            let yy = date.getFullYear();
            let mm = date.getMonth() + 1;
            mm = mm > 9 ? mm : "0" + mm;
            return yy + "-" + mm;
        },
        /**获得年月 */
        getM() {
            let date = new Date();
            let mm = date.getMonth() + 1;
            mm = mm > 9 ? mm : "0" + mm;
            return mm;
        },
        /**获得日 */
        getD() {
            let date = new Date();
            let dd = date.getDate();
            dd = dd > 9 ? dd : "0" + dd;
            return dd;
        },
        /**获得年 */
        getY() {
            let date = new Date();
            let yy = date.getFullYear();
            return yy + "";
        },
        /**获得年月日时分秒 */
        getYMDHMS() {
            let dateTime;
            let date = new Date();
            let yy = date.getFullYear();
            let mm = date.getMonth() + 1;
            let dd = date.getDate();
            let hh = date.getHours();
            let mf = date.getMinutes();
            let ss = date.getSeconds();
            mm = mm < 10 ? "0" + mm : mm;
            dd = dd < 10 ? "0" + dd : dd;
            hh = hh < 10 ? "0" + hh : hh;
            mf = mf < 10 ? "0" + mf : mf;
            ss = ss < 10 ? "0" + ss : ss;
            dateTime = yy + "-" + mm + "-" + dd + " " + hh + ":" + mf + ":" + ss;
            return dateTime;
        },
        /**获得年月日时分秒 */
        toYMDHMS(time) {
            let dateTime;
            let date = new Date(time);
            let yy = date.getFullYear();
            let mm = date.getMonth() + 1;
            let dd = date.getDate();
            let hh = date.getHours();
            let mf = date.getMinutes();
            let ss = date.getSeconds();
            mm = mm < 10 ? "0" + mm : mm;
            dd = dd < 10 ? "0" + dd : dd;
            hh = hh < 10 ? "0" + hh : hh;
            mf = mf < 10 ? "0" + mf : mf;
            ss = ss < 10 ? "0" + ss : ss;
            dateTime = yy + "-" + mm + "-" + dd + " " + hh + ":" + mf + ":" + ss;
            return dateTime;
        },
        //#endregion

        //#region 计算
        /**乘法 */
        mul(arg1, arg2) {
            var m = 0;
            var s1 = arg1.toString();
            var s2 = arg2.toString();
            try {
                m += s1.split(".")[1].length;
            } catch (e) {
            }
            try {
                m += s2.split(".")[1].length;
            } catch (e) { }

            return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);
        },
        /**除法 */
        div(arg1, arg2) {
            arg1 = Number(arg1);
            arg2 = Number(arg2);
            if (!arg2) {
                return null;
            }
            if (!arg1 && arg1 !== 0) {
                return null;
            } else if (arg1 === 0) {
                return 0;
            }
            arg1 = this.toNonExponential(arg1);
            arg2 = this.toNonExponential(arg2);
            var n1, n2;
            var r1, r2; // 小数位数
            try {
                r1 = arg1.toString().split(".")[1].length;
            } catch (e) {
                r1 = 0;
            }
            try {
                r2 = arg2.toString().split(".")[1].length;
            } catch (e) {
                r2 = 0;
            }
            n1 = Number(arg1.toString().replace(".", ""));
            n2 = Number(arg2.toString().replace(".", ""));
            return this.mul((n1 / n2), Math.pow(10, r2 - r1));
        },
        /**加法 */
        add(arg1, arg2) {
            var r1, r2, m;
            try { r1 = arg1.toString().split(".")[1].length } catch (e) { r1 = 0 }
            try { r2 = arg2.toString().split(".")[1].length } catch (e) { r2 = 0 }
            m = Math.pow(10, Math.max(r1, r2))
            return (arg1 * m + arg2 * m) / m
        },
        /**减法 */
        sub(arg1, arg2) {
            var re1, re2, m, n;
            try {
                re1 = arg1.toString().split(".")[1].length;
            } catch (e) {
                re1 = 0;
            }
            try {
                re2 = arg2.toString().split(".")[1].length;
            } catch (e) {
                re2 = 0;
            }
            m = Math.pow(10, Math.max(re1, re2));
            n = (re1 >= re2) ? re1 : re2;
            return ((arg1 * m - arg2 * m) / m).toFixed(n);
        },
        /**将科学计数法的数字转为字符串 */
        toNonExponential(num) {
            if (num == null) {
                return num;
            }
            if (typeof num == "number") {
                var m = num.toExponential().match(/\d(?:\.(\d*))?e([+-]\d+)/);
                return num.toFixed(Math.max(0, (m[1] || '').length - m[2]));
            } else {
                return num;
            }
        },
        //#endregion
    },

}