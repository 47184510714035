const tableTitleList = [{
    label: "生产订单号",
    prop: "orderid"
},
{
    label: "设备类型",
    prop: "devicetypeShow"
},
{
    label: "设备数",
    prop: "devicecount"
},
{
    label: "录入人",
    prop: "creatorname"
},
{
    label: "创建时间",
    prop: "createtime"
},
{
    label: "备注",
    prop: "remark"
},
{
    label: "注册成功率",
    prop: "loginsuccess"
},
]

const tableTitleListDetail = [{
    label: "资产号",
    prop: "inmeterno"
},
{
    label: "通讯地址",
    prop: "commaddress"
},
{
    label: "年费",
    prop: "yearfeeflag"
},
{
    label: "状态",
    prop: "status"
},
{
    label: "IMEI",
    prop: "imei"
},
{
    label: "CCID",
    prop: "iccid"
},
{
    label: "箱号",
    prop: "boxnum"
},
{
    label: "SIM卡号",
    prop: "simcode"
},
{
    label: "备注",
    prop: "remark"
},
{
    label: "注册时间",
    prop: "regtime",
    canRegister: true
},
{
    label: "注册状态",
    prop: "nbstatus",
    canRegister: true
},
{
    label: "预付费号",
    prop: "qmeterno"
},
]

const importTitleList = [{
    label: "资产号",
    prop: "inmeterno"
},
{
    label: "通讯地址",
    prop: "commaddress"
},
{
    label: "年费",
    prop: "yearfeeflag"
},
{
    label: "IMEI",
    prop: "imei"
},
{
    label: "箱号",
    prop: "boxnum"
},
{
    label: "SIM卡号",
    prop: "simcode"
},
{
    label: "预付费号",
    prop: "qmeterno"
},

]

const storeTitleList = [
    {
        label: "资产号",
        prop: "assetId"
    },
    {
        label: "通讯地址",
        prop: "postalAddress"
    },
    {
        label: "箱号",
        prop: "boxId"
    },
    {
        label: "IMEI号",
        prop: "imel"
    },
    {
        label: "SIM卡号",
        prop: "sim",
        width: "140"
    },
    {
        label: "预付费号",
        prop: "prepaymentNo"
    },
    {
        label: "版本号",
        prop: "version"
    },
    {
        label: "年费",
        prop: "annualFee"
    },
]

export {
    tableTitleList,
    tableTitleListDetail,
    importTitleList,
    storeTitleList
}