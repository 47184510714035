<template>
  <div class="common_bg">
    <div v-show="!showSettlementDate">
      <div class="flowcard_equip_info">
        <div class="flowcard_box">
          <p class="title">流量卡信息</p>
          <div class="content_box">
            <p v-for="item in flowcardMsg" :key="item.label" class="line_box">
              <span class="label">{{ item.label }}</span>
              <span class="value">{{ repEmpty(item.value, "--") }}</span>
            </p>
          </div>
        </div>
        <div class="bind_box">
          <p class="title">绑定设备信息</p>
          <div class="content_box">
            <span class="label">设备资产号</span>
            <span class="value">{{ repEmpty(inmeterno, "--") }}</span>
          </div>
        </div>
      </div>
      <div class="sign2">
        <el-tabs v-model="activeName" ref="tabs">
          <el-tab-pane label="订单信息" name="a">
            <div class="order_message_box">
              <div
                class="item_box"
                v-for="(item, index) in orderMsg"
                :key="index"
              >
                <span class="title">{{ item.title }}</span>
                <span v-if="item.title != '结算日期'" class="content">{{
                  item.value
                }}</span>
                <span
                  v-else
                  :class="[item.value == '--' ? '' : 'detail', 'content']"
                  @click="onClickCheckDetail(item.value)"
                  >{{ item.value }}</span
                >
              </div>
            </div>
            <div class="remark_box"></div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div v-show="showSettlementDate">
      <settlement-date-details ref="settlementDateDetails" />
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import mixinf from "@/app/common/mixins/mixin_func.js";
import { storeMsg } from "../titleOptions";
import settlementDateDetails from "./settlement-date-details.vue";
export default {
  components: { settlementDateDetails },
  name: "FlowCardInformation",
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$nextTick(() => {
        vm.addKeepAlive("FlowCardInformation");
      });
    });
  },
  mixins: [mixinf],
  data() {
    return {
      flowcardMsg: [
        {
          label: "用户账号",
          value: "1410138897247",
        },
        {
          label: "ICCID",
          value: "8986112027201050300",
        },
        {
          label: "IMSI",
          value: "460113133971789",
        },
        {
          label: "卡状态",
          value: "占用",
        },
        {
          label: "产品状态",
          value: "在用",
        },
        {
          label: "受理时间",
          value: "2022-06-08 00:37:49",
        },
        {
          label: "激活时间",
          value: "2022-06-07 13:49:38",
        },
        {
          label: "首次上线时间",
          value: "2022-06-03 02:51:15",
        },
        {
          label: "开始收费时间",
          value: "2022-05-30 17:04:47",
        },
        {
          label: "注销时间",
          value: "2022-06-03 02:51:15",
        },
      ],
      inmeterno: "",
      //写死 不需要改动
      activeName: "a",
      orderMsg: [],
      templateMsg: storeMsg,
      showSettlementDate: false,
      /**保存id 点击查看详情时使用 */
      id: null,
    };
  },
  methods: {
    //#region 接口数据
    ...mapActions({
      addKeepAlive: "addKeepAlivePage",
      flowCardDetail: "ZD_FlowCardDetail",
    }),
    iFlowCardDetail() {
      let tempCCID = this.$store.getters["flowcard/ccid"];
      if (this.isEmpty(tempCCID)) {
        this.$message.warning("CCID为空");
        return;
      }
      this.flowCardDetail({ iccid: tempCCID }).then((res) => {
        if (res.success == 1) {
          this.cardOrderItemHandle(res.cardOrderItem);
          this.flowCardItemHandle(res.flowCardItem);
        }
      });
    },
    cardOrderItemHandle(item) {
      this.id = item.id;
      this.orderMsg[0].value = this.repEmpty(item.company);
      this.orderMsg[1].value = this.repEmpty(item.platform);
      this.orderMsg[2].value = this.repEmpty(item.buyer);
      this.orderMsg[3].value = this.repEmpty(item.provider);
      this.orderMsg[4].value = this.repEmpty(item.plainName);
      this.orderMsg[5].value = this.getOperator(item.carrierType);
      this.orderMsg[6].value = this.repEmpty(item.orderno);
      this.orderMsg[7].value = this.repEmpty(item.cardCount);
      this.orderMsg[8].value = "查看详情";
      this.orderMsg[9].value = this.repEmpty(item.customerManager);
      this.orderMsg[10].value = this.getNet(item.flowType);
      this.orderMsg[11].value = this.repEmpty(item.plainDetail);
      this.orderMsg[12].value = this.repEmpty(item.acceptTime);
      this.orderMsg[13].value = this.repEmpty(item.acceptArea);
      this.orderMsg[14].value = this.repEmpty(item.phonenum);
    },
    flowCardItemHandle(item) {
      this.flowcardMsg[0].value = this.repEmpty(item.phonenum, "--");
      this.flowcardMsg[1].value = this.repEmpty(item.ccid, "--");
      this.flowcardMsg[2].value = this.repEmpty(item.imei, "--");
      this.flowcardMsg[3].value = this.repEmpty(item.cardDisc, "--");
      this.flowcardMsg[4].value = this.getCardStatus(item.cardStatus);
      this.flowcardMsg[5].value = this.repEmpty(item.acceptTime, "--");
      this.flowcardMsg[6].value = this.repEmpty(item.activeTime, "--");
      this.flowcardMsg[7].value = this.repEmpty(item.activeTime, "--");
      this.flowcardMsg[8].value = this.repEmpty(item.activeTime, "--");
      this.flowcardMsg[9].value = this.repEmpty(item.cancelTime, "--");
      this.inmeterno = this.repEmpty(item.inmeterno, "--");
    },
    //#endregion
    init() {
      this.showSettlementDate = false;
      this.orderMsg = this.deepCopy(this.templateMsg);
      this.iFlowCardDetail();
    },
    onClickCheckDetail(val) {
      if (val == "--") return;
      this.$refs.settlementDateDetails.init(this.id);
      this.showSettlementDate = true;
    },
    onClickGoBack(idx = 1) {
      if (idx == 1) this.showSettlementDate = false;
    },
    //#region 获取状态
    getOperator(key) {
      let operatorList = [
        {
          key: "电信",
          value: 2,
        },
        {
          key: "移动",
          value: 0,
        },
        {
          key: "联通",
          value: 1,
        },
        {
          key: "其他",
          value: -1,
        },
      ];
      for (const k in operatorList) {
        if (operatorList[k].value == key) return operatorList[k].key;
      }
      return "--";
    },
    getNet(key) {
      let netList = [
        {
          key: "4G",
          value: 2,
        },
        {
          key: "NB",
          value: 0,
        },
        {
          key: "GPRS",
          value: 1,
        },
        {
          key: "其他",
          value: -1,
        },
      ];
      for (const k in netList) {
        if (netList[k].value == key) return netList[k].key;
      }
      return "--";
    },
    getCardStatus(val) {
      if (this.isEmpty(val)) return "--";
      switch (val) {
        case -1:
          return "未知";
        case 0:
          return "未激活";
        case 1:
          return "在用";
        case 3:
          return "注销";
        default:
          return "未知";
      }
    },
    //#endregion
  },
  activated() {
    if (!this.isEmpty(this.$route.params.ccid)) this.init();
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="less" scoped>
.flowcard_equip_info {
  display: flex;
  .flowcard_box {
    margin-right: 14px;
    .title {
      font-size: 14px;
      color: #333;
      font-weight: 700;
      margin-bottom: 7px;
    }
    .content_box {
      background-color: #ecf5ff;
      padding: 20px 20px 2px 20px;
      .line_box {
        display: flex;
        font-size: 14px;
        color: #333;
        margin-bottom: 18px;
        .label {
          display: inline-block;
          width: 124px;
          text-align: right;
          margin-right: 20px;
        }
        .value {
          display: inline-block;
          width: 336px;
        }
      }
    }
  }
  .bind_box {
    width: calc(100% - 534px);
    .title {
      font-size: 14px;
      color: #333;
      font-weight: 700;
      margin-bottom: 7px;
    }
    .content_box {
      border: 1px solid #409eff;
      height: calc(100% - 70px);
      padding: 20px 36px;
      font-size: 14px;
      color: #333;
      .label {
        display: inline-block;
        margin-right: 20px;
      }
    }
  }
}

.sign2 {
  margin-top: 20px;
  .order_message_box {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    font-size: 14px;
    padding: 24px 64px 0 64px;
    .item_box {
      display: flex;
      align-items: center;
      color: #999;
      margin-bottom: 10px;
      .title {
        width: 60px;
        text-align: right;
        margin-right: 8px;
      }
      .content {
        width: 182px;
        color: #333;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 100%;
      }
      .detail {
        text-decoration: underline;
        color: #409eff;
        cursor: pointer;
      }
      .detail:hover {
        color: #6db6ff;
      }
      span {
        display: inline-block;
      }
    }
  }
  .remark_box {
    color: #999;
    font-size: 14px;
    padding: 0px 64px 24px 64px;
    display: flex;
    align-items: center;
    .title {
      width: 60px;
      text-align: right;
      margin-right: 8px;
    }
    .content {
      width: calc(100% - 132px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 100%;
      color: #333;
    }
    span {
      display: inline-block;
    }
  }
  .error_msg_box {
    margin: 0 0px 16px 104px;
    box-sizing: border-box;
    height: 80px;
    font-size: 14px;
    color: #f56c6c;

    overflow: auto;

    div {
      display: inline-block;
      margin-right: 120px;
      margin-bottom: 20px;
      min-width: 140px;
    }
  }
  /deep/ .el-tabs {
    border: 1px solid #409eff;
  }
  /deep/ .el-tabs__header {
    background-color: rgba(64, 158, 255, 0.1);
    padding-left: 16px;
    margin-bottom: 13px;
  }
  /deep/ .el-tabs__item {
    padding: 0 16px;
    height: 45px;
    line-height: 45px;
  }
  /deep/ .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
    color: #333;
  }
}
</style>
