<template>
  <div>
    <div class="dialogsign1">
      <el-dialog
        :title="abnormal ? '校验异常' : '数据校验'"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        width="520px"
      >
        <div v-loading="loading">
          <div class="content" v-if="!abnormal">您确定要进行数据校验吗？</div>
          <div v-else>
            <cm-table
              :showIndex="true"
              :tableData="tableData"
              :tableTitle="tableTitle"
              :maxHeight="'233'"
            >
            </cm-table>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">{{
            $t("lang.cancel")
          }}</el-button>
          <el-button type="primary" @click="onClickTrue">{{
            $t("lang.determine")
          }}</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import mixinf from "@/app/common/mixins/mixin_func.js";
import cmTable from "../../../components/common/cm-table.vue";
export default {
  components: { cmTable },
  mixins: [mixinf],
  data() {
    return {
      loading: false,
      dialogVisible: false,
      id: null,
      /**校验是否异常 */
      abnormal: false,
      tableData: [],
      tableTitle: [
        {
          label: "资产号",
          prop: "inmeterno",
        },
        {
          label: "CCID",
          prop: "iccid",
        },
      ],
    };
  },
  watch: {
    dialogVisible(val) {
      if (!val) {
        this.abnormal = false;
        this.loading = false;
        this.tableData = [];
      }
    },
  },
  methods: {
    //#region 接口数据
    ...mapActions({
      orderDeviceRefcheck: "ZD_OrderDeviceRefcheck",
    }),
    iOrderDeviceRefcheck() {
      this.loading = true;
      this.orderDeviceRefcheck({ id: this.id })
        .then((res) => {
          if (res.success == 1) {
            this.$message.success("校验完成");
            this.$parent.getList();
            this.dialogVisible = false;
          } else {
            this.abnormal = true;
            this.tableData = res.listitem;
          }
        })
        .finally((f) => {
          this.loading = false;
        });
    },
    //#endregion

    show(id) {
      this.id = id;
      this.dialogVisible = true;
    },

    //#region 按钮点击事件
    onClickTrue() {
      if (this.loading) return;
      if (this.abnormal) {
        this.dialogVisible = false;
        return;
      }
      this.iOrderDeviceRefcheck();
    },
    //#endregion
  },
};
</script>

<style lang="less" scoped>
.dialogsign1 {
  .content {
    display: flex;
    justify-content: center;
    margin: 30px 0;
    font-size: 17px;
    color: #333;
  }
  /deep/ .el-dialog__footer {
    padding: 10px 24px 24px 0;
  }
  /deep/ .el-button + .el-button {
    margin-left: 24px;
  }
}
.dialog-footer /deep/ .el-button--medium {
  padding: 12px 20px;
}
</style>
