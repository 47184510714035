<template>
  <div style="margin-top: 10px">
    <!-- 筛选条件 -->
    <el-form :inline="true">
      <!-- 房间状态 -->
      <el-form-item :label="$t('lang.roomstatus')" class="form-item-margin">
        <el-select v-model="choosedStatus">
          <el-option
            v-for="item of statusList"
            :key="item.value"
            :label="$t(item.title)"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- 输入框搜索 -->
      <el-form-item class="form-item-margin">
        <el-input
          v-model="reqParams.searchValue"
          :placeholder="$t('lang.toinput')"
        >
          <i slot="prefix" class="el-icon-search"></i>
        </el-input>
      </el-form-item>
      <!-- 查询 -->
      <el-form-item class="form-item-margin">
        <el-button type="primary" @click="onSubmit">{{
          $t("lang.query")
        }}</el-button>
      </el-form-item>
      <!-- 添加房间 -->
      <el-form-item class="form-item-margin">
        <el-button type="primary" @click="addNameDialogFormVisible = true">{{
          $t("lang.addroom")
        }}</el-button>
      </el-form-item>
    </el-form>
    <!-- 表格 -->
    <el-table :data="recvDataList" v-loading="loading" style="width: 100%">
      <el-table-column
        v-for="(item, index) of tableList"
        :key="index"
        align="center"
        :width="item.width"
        :label="$t(item.label)"
      >
        <template slot-scope="scope">
          <div v-if="item.key == 'endtime'">
            <span>{{ scope.row.info.endtime | toFormatNormalTime }}</span>
          </div>
          <div v-else-if="item.key == 'rentprice'">
            <span
              >{{ scope.row.info.rentprice | toNormalFixed
              }}{{
                scope.row.info.rentprice != null ? "/" + $t("lang.month") : ""
              }}</span
            >
          </div>
          <div v-else-if="item.key == 'remainvalue'">
            <span>{{ scope.row.remainvalue | toNormalFixed }}</span>
          </div>
          <div v-else-if="item.key == 'pmeterid'">
            <span>{{ scope.row.info && scope.row.info.pmeterid ? 1 : 0 }}</span>
          </div>
          <div v-else-if="item.key == 'wmeterid'">
            <span>{{
              scope.row.info
                ? scope.row.info.wmeterid && scope.row.info.hotwmeterid
                  ? 2
                  : scope.row.info.wmeterid || scope.row.info.hotwmeterid
                  ? 1
                  : 0
                : 0
            }}</span>
          </div>
          <div v-else-if="item.key == 'status'">
            <span>{{ scope.row.status | toRoomStatus | translate }}</span>
          </div>
          <div v-else-if="item.key == 'operate'">
            <!-- 已出租 操作 -->
            <div v-if="scope.row.status == ROOM_STATUS.RENTED">
              <el-button
                @click="roomOperate(scope.row, rentedOperateMenu[0].value)"
                type="text"
                size="small"
                >{{ $t(rentedOperateMenu[0].title) }}</el-button
              >
              <el-button
                @click="roomOperate(scope.row, rentedOperateMenu[1].value)"
                type="text"
                size="small"
                >{{ $t(rentedOperateMenu[1].title) }}</el-button
              >
              <el-dropdown
                placement="top"
                @command="roomOperate(scope.row, $event)"
                style="margin-left: 10px"
              >
                <el-button type="text" size="small" style="font-size: 22px"
                  >...</el-button
                >
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="(item, index) in rentedOperateMoreMenu"
                    :key="index"
                    :command="item.value"
                    >{{ $t(item.title) }}</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <!-- 未出租 操作 -->
            <div v-else>
              <el-button
                @click="editHouseClick(scope.row)"
                type="text"
                size="small"
                >{{ $t(unRentedOperateMenu[0].title) }}</el-button
              >
              <el-button
                @click="delHouseClick(scope.row)"
                type="text"
                size="small"
                >{{ $t(unRentedOperateMenu[1].title) }}</el-button
              >
              <el-dropdown
                placement="top"
                style="margin-left: 10px"
                @command="unRentClick($event, scope.row)"
              >
                <el-button type="text" size="small" style="font-size: 22px"
                  >...</el-button
                >
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="(item, index) in unRentedOperateMoreMenu"
                    :key="index"
                    :command="item"
                    >{{ $t(item.title) }}</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
          <div v-else class="ellipsis_show">
            <span :title="item.key == 'name' && scope.row[item.key]">{{
              scope.row[item.key] || "--"
            }}</span>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="bottom">
      <el-pagination
        class="bottom_page"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="recvTotal"
        :hide-on-single-page="true"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="reqParams.pageNum"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="10"
      ></el-pagination>
    </div>
    <!-- 修改名称 -->
    <el-dialog
      :title="$t('lang.editname')"
      :visible.sync="editNameDialogFormVisible"
      :width="'550px'"
    >
      <el-form :model="editNameForm">
        <el-form-item
          :label="$t('lang.name')"
          :label-width="formLabelWidth"
          prop="name"
          :rules="[
            {
              required: true,
              message: $t('lang.toinput') + $t('lang.roomname'),
              trigger: 'change',
            },
          ]"
        >
          <el-input
            v-model="editNameForm.name"
            autocomplete="off"
            :placeholder="$t('lang.toinput') + $t('lang.roomname')"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editNameDialogFormVisible = false">{{
          $t("lang.cancel")
        }}</el-button>
        <el-button type="primary" @click="changeName()">{{
          $t("lang.determine")
        }}</el-button>
      </div>
    </el-dialog>
    <!-- 添加房间 -->
    <el-dialog
      :title="$t('lang.editname')"
      :visible.sync="addNameDialogFormVisible"
      :width="'550px'"
    >
      <el-form :model="reqAddRoom">
        <el-form-item
          :label="$t('lang.name')"
          :label-width="formLabelWidth"
          prop="name"
          :rules="[
            {
              required: true,
              message: $t('lang.toinput') + $t('lang.roomname'),
              trigger: 'blur',
            },
          ]"
        >
          <el-input
            v-model="reqAddRoom.name"
            autocomplete="off"
            :placeholder="$t('lang.toinput') + $t('lang.roomname')"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addNameDialogFormVisible = false">{{
          $t("lang.cancel")
        }}</el-button>
        <el-button type="primary" @click="onAddRoom()">{{
          $t("lang.determine")
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import config from "./config.js";
import moment from "moment";
export default {
  data() {
    return {
      choosedStatus: 99,
      reqParams: {
        houseid: null,
        status: null,
        pageNum: 1,
        pageSize: 10,
        searchValue: "",
      },
      recvDataList: [],
      recvTotal: 0,
      loading: false,
      statusList: config.statusList,
      tableList: config.tableList,
      rentedOperateMenu: config.rentedOperateMenu,
      unRentedOperateMenu: config.unRentedOperateMenu,
      ROOM_STATUS: config.ROOM_STATUS,
      editNameDialogFormVisible: false,
      addNameDialogFormVisible: false,
      editNameForm: {
        houseid: "",
        name: "",
        remark: "",
        roomid: "",
      },
      reqAddRoom: { houseid: "", name: "", remark: "", systemid: 21 },
      formLabelWidth: "120px",
      reqDelRoom: {
        houseid: "",
        roomid: "",
      },
    };
  },
  async created() {
    let params = this.$route.params;
    this.reqParams.houseid =
      this.reqAddRoom.houseid =
      this.reqDelRoom.houseid =
      this.editNameForm.houseid =
        params.houseid;
    this.getRoomList();
  },
  methods: {
    ...mapActions({
      roomList: "ZD_Landlord_PageRoom",
      editRoomName: "ZD_updateRoom",
      addRoom: "ZD_addRoom",
      delRoom: "ZD_delRoom",
    }),
    onSubmit() {
      this.getRoomList();
    },
    handleSizeChange(size) {
      this.reqParams.pageNum = 1;
      this.reqParams.pageSize = size;
      this.getRoomList();
    },
    handleSizeChange(size) {
      this.reqParams.pageNum = 1;
      this.reqParams.pageSize = size;
      this.getRoomList();
    },
    handleCurrentChange(index) {
      this.reqParams.pageNum = index;
      this.getRoomList();
    },
    roomOperate(data, command) {
      // console.log("data-command :>> ", command + "-" + JSON.stringify(data));
      switch (command) {
        case 5: //修改名称
          this.editNameForm.name = data.name;
          this.editNameForm.roomid = data.id;
          this.editNameDialogFormVisible = true;
          break;
        case 6:
          break;
        case 10:
          break;
        default:
          break;
      }
    },
    unRentClick({ value }, data) {
      console.log(data);
      switch (value) {
        case 5: //修改名称
          this.editNameForm.name = data.name;
          this.editNameForm.roomid = data.id;
          this.editNameDialogFormVisible = true;
          break;
        case 6:
          break;
        case 10:
          this.$confirm(this.$t("lang.isdelroom"), this.$t("lang.tips"), {
            confirmButtonText: this.$t("lang.determine"),
            cancelButtonText: this.$t("lang.cancel"),
            type: "warning",
            center: false,
          })
            .then(() => {
              this.delelteRoom(data.id);
            })
            .catch(() => {});
          break;
        default:
          break;
      }
    },
    // 删除房间
    delelteRoom(id) {
      this.reqDelRoom.roomid = id;
      this.delRoom(this.reqDelRoom).then((res) => {
        if (res.success == 1) {
          this.$message({
            message: this.$t("lang.delsuccess"),
            type: "success",
          });
          this.getRoomList();
        }
      });
    },
    changeName() {
      this.editRoomName(this.editNameForm).then((res) => {
        if (res.success == 1) {
          this.$message({
            message: this.$t("lang.editsuccess"),
            type: "success",
          });
          this.getRoomList();
          this.editNameDialogFormVisible = false;
        }
      });
    },
    onAddRoom() {
      this.addRoom(this.reqAddRoom).then((res) => {
        if (res.success == 1) {
          this.$message({
            message: this.$t("lang.addsuccess"),
            type: "success",
          });
          this.getRoomList();
          this.addNameDialogFormVisible = false;
          this.resetForm("reqAddRoom");
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    async getRoomList() {
      try {
        this.loading = true;
        this.reqParams.status =
          this.choosedStatus == 99 ? null : this.choosedStatus;
        let res = await this.roomList(this.reqParams);
        res.listitem.forEach((element, index) => {
          element.num = (this.reqParams.pageNum - 1) * 10 + index + 1;
        });
        this.recvDataList = res.listitem || [];
        this.recvTotal = res.totalItemCount || 0;
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    rentedOperateMoreMenu() {
      return this.rentedOperateMenu.filter((item, index) => index > 1);
    },
    unRentedOperateMoreMenu() {
      return this.unRentedOperateMenu.filter((item, index) => index > 1);
    },
  },
  filters: {
    toFormatNormalTime(val) {
      if (val) {
        return moment(val).format("YYYY-MM-DD");
      } else {
        return "--";
      }
    },
    toRoomStatus(val) {
      switch (val) {
        case config.ROOM_STATUS.RENTED:
          return "lang.rented";
        case config.ROOM_STATUS.NOT_RENT:
          return "lang.notrent";
        case config.ROOM_STATUS.WAIT_FOR_CONFIRM:
          return "lang.waitforconfirmation";
      }
    },
  },
};
</script>
<style lang="less" scoped>
.form-item-margin {
  margin-right: 20px;
}
.bottom {
  width: 100%;
  height: 80px;
}
.bottom_page {
  position: absolute;
  bottom: 20px;
  right: 0px;
}
.ellipsis_show {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>