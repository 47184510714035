const tableList = [
    {
        label: "lang.num",
        key: "num",
        width: "80"
    },
    {
        label: "lang.roomname",
        key: "name",
        width: "200"
    },
    {
        label: "lang.tenant",
        key: "tenantname",
        width: "200"
    },
    {
        label: "lang.contactway",
        key: "tenantphone",
        width: "150"
    },
    {
        label: "lang.dueTime",
        key: "endtime",
        width: "150"
    },
    {
        label: "lang.rentmoney",
        key: "rentprice",
        width: "200"
    },
    {
        label: "lang.lastmoney",
        key: "remainvalue",
        width: "200"
    },
    {
        label: "lang.meter",
        key: "pmeterid",
        width: "80"
    },
    {
        label: "lang.watermeter",
        key: "wmeterid",
        width: "80"
    },
    {
        label: "lang.status",
        key: "status",
        // width: "100"
    },
    {
        label: "lang.operate",
        key: "operate",
        width: "200"
    },
]

const statusList = [
    {
        title: "lang.all",
        value: 99
    },
    {
        title: "lang.haverent",
        value: 2
    },
    {
        title: "lang.notrent",
        value: 0
    },
    {
        title: "lang.waitforconfirmation",
        value: 4
    }
]

const rentedOperateMenu = [
    {
        title: "lang.coststatistics",
        value: 1,
    },
    {
        title: "lang.paymentbill",
        value: 2,
    },
    {
        title: "lang.operaterecord",
        value: 3,
    },
    {
        title: "lang.rentalagreement",
        value: 4,
    },
    {
        title: "lang.editname",
        value: 5,
    },
    {
        title: "lang.rentwithdrawal",
        value: 7,
    },
    {
        title: "lang.setalarm",
        value: 8,
    },
    {
        title: "lang.balanceclear",
        value: 9,
    },
    {
        title: "lang.delroom",
        value: 10,
    },
    {
        title: "lang.roommanagement",
        value: 11
    },
]
const unRentedOperateMenu = [
    {
        title: "lang.paymentbill",
        value: 2,
    },
    {
        title: "lang.operaterecord",
        value: 3,
    },
    {
        title: "lang.editname",
        value: 5,
    },
    {
        title: "lang.addtenant",
        value: 6,
    },
    {
        title: "lang.setalarm",
        value: 8,
    },
    {
        title: "lang.delroom",
        value: 10,
    },
    {
        title: "lang.adddevice",
        value: 12,
    },
]

const ROOM_STATUS = {
    RENTED: 2,
    NOT_RENT: 0,
    WAIT_FOR_CONFIRM: 4
}

export default {
    tableList,
    statusList,
    ROOM_STATUS,
    rentedOperateMenu,
    unRentedOperateMenu
}