<template>
  <div style="margin-top: 10px">
    <!-- 筛选条件 -->
    <el-form :inline="true">
      <!-- 输入框搜索 -->
      <el-form-item class="form-item-margin">
        <el-input
          v-model="reqParams.searchValue"
          :placeholder="$t('lang.toinput')"
        >
          <i slot="prefix" class="el-icon-search"></i>
        </el-input>
      </el-form-item>
      <!-- 查询 -->
      <el-form-item class="form-item-margin">
        <el-button type="primary" @click="onSubmit">{{
          $t("lang.query")
        }}</el-button>
      </el-form-item>
      <!-- 添加设备 -->
      <el-form-item class="form-item-margin">
        <el-button type="primary" @click="onAddDevice">{{
          $t("lang.adddevice")
        }}</el-button>
      </el-form-item>
    </el-form>
    <!-- 表格 -->
    <el-table :data="recvDataList" v-loading="loading" style="width: 100%">
      <el-table-column
        v-for="(item, index) of tableList"
        :key="index"
        align="center"
        :width="item.width"
        :label="$t(item.label)"
      >
        <template slot-scope="scope">
          <div v-if="item.key == 'datatime'">
            <span>{{ scope.row.datatime | toFormatNormalTime }}</span>
          </div>
          <div
            v-else-if="item.key == 'pricevalue'"
            style="display: flex; align-items: center; justify-content: center"
          >
            <span
              >{{ scope.row.pricevalue | toNormalFixed
              }}{{
                scope.row.pricevalue != null
                  ? $t("lang.yuan1") +
                    "/" +
                    (scope.row.devicetype === DEVICETYPE.ELE ? "kW·h" : "m³")
                  : ""
              }}</span
            >
            <el-popover placement="top" width="150" trigger="hover">
              <img
                v-if="scope.row.newpricevalue"
                src="@/assets/img/public_area_img/price_tips.png"
                slot="reference"
                style="
                  width: 15px;
                  height: 15px;
                  margin-left: 5px;
                  margin-top: 5px;
                "
              />
              <div>
                <p class="tip_content">{{ $t("lang.newpricetip") }}:</p>
                <p class="tip_content">
                  {{ scope.row.devicetype | toPriceType | translate }}
                  {{
                    scope.row.newpricevalue +
                    $t("lang.yuan1") +
                    "/" +
                    (scope.row.devicetype === DEVICETYPE.ELE ? "kW·h" : "m³")
                  }}
                </p>
              </div>
            </el-popover>
          </div>
          <div v-else-if="item.key == 'metertype'">
            <span>{{ scope.row.devicetype | toDeviceType | translate }}</span>
          </div>
          <div v-else-if="item.key == 'state'">
            <span
              :style="{
                color:
                  toDevStatus(scope.row) == 'lang.offline' ? '#FB3535' : '#333',
              }"
              >{{ toDevStatus(scope.row) | translate }}</span
            >
          </div>
          <!-- 操作 -->
          <div v-else-if="item.key == 'operate'">
            <el-button
              v-for="item in operateMenu"
              :key="item.value"
              @click="deviceOperate(scope.row, item.value)"
              type="text"
              size="small"
              style="margin-left: 0px; margin-right: 10px"
              >{{ $t(item.title) }}</el-button
            >
          </div>
          <div v-else class="ellipsis_show">
            <span :title="item.key == 'metername' && scope.row[item.key]">{{
              scope.row[item.key] || "--"
            }}</span>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="bottom">
      <el-pagination
        class="bottom_page"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="recvTotal"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="10"
        :current-page.sync="reqParams.pageNum"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import config from "./config.js";
import moment from "moment";
import { DEVICETYPE } from "@/app/common/resources/resources.js";
export default {
  data() {
    return {
      DEVICETYPE: DEVICETYPE,
      choosedStatus: 99,
      reqParams: {
        houseid: null,
        pageNum: 1,
        pageSize: 10,
        searchValue: "",
      },
      recvDataList: [],
      recvTotal: 0,
      loading: false,
      statusList: config.statusList,
      tableList: config.tableList,
      operateMenu: config.operateMenu,
    };
  },
  async created() {
    let params = this.$route.params;
    this.reqParams.houseid = params.houseid;
    this.getPubDevList();
  },
  methods: {
    ...mapActions({
      pubDevList: "ZD_Landlord_ApporDevice",
    }),
    onSubmit() {
      this.getPubDevList();
    },
    handleSizeChange(size) {
      this.reqParams.pageNum = 1;
      this.reqParams.pageSize = size;
      this.getPubDevList();
    },
    handleSizeChange(size) {
      this.reqParams.pageNum = 1;
      this.reqParams.pageSize = size;
      this.getPubDevList();
    },
    handleCurrentChange(index) {
      this.reqParams.pageNum = index;
      this.getPubDevList();
    },
    deviceOperate(data, command) {
      // console.log("data-command :>> ", command + "-" + JSON.stringify(data));
    },
    onAddDevice() {},
    toDevStatus(val) {
      if (!val.datatime) {
        return "lang.offline";
      }
      if (
        new Date(val.datatime).getTime() + val.offlinetime * 60 * 1000 <
        new Date().getTime()
      ) {
        return "lang.offline";
      } else {
        if (val.devicetype == DEVICETYPE.ELE) {
          let status =
            val.ctrlstatus == 1 ? "lang.closedswitch" : "lang.pullswitch";
          return status;
        } else {
          return "lang.online";
        }
      }
    },
    async getPubDevList() {
      try {
        this.loading = true;
        let res = await this.pubDevList(this.reqParams);
        res.listitem.forEach((element, index) => {
          element.num = (this.reqParams.pageNum - 1) * 10 + index + 1;
          element.totalvalue &&
            (element.totalvalue +=
              element.devicetype == DEVICETYPE.ELE ? "kW·h" : "m³");
          element.monthuse &&
            (element.monthuse +=
              element.devicetype == DEVICETYPE.ELE ? "kW·h" : "m³");
        });
        this.recvDataList = res.listitem || [];
        this.recvTotal = res.totalItemCount || 0;
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    rentedOperateMoreMenu() {
      return this.rentedOperateMenu.filter((item, index) => index > 1);
    },
    unRentedOperateMoreMenu() {
      return this.unRentedOperateMenu.filter((item, index) => index > 1);
    },
  },
  filters: {
    toFormatNormalTime(val) {
      if (val) {
        return moment(val).format("YYYY-MM-DD HH:mm:ss");
      } else {
        return "--";
      }
    },
    toRoomStatus(val) {
      switch (val) {
        case config.ROOM_STATUS.RENTED:
          return "lang.rented";
        case config.ROOM_STATUS.NOT_RENT:
          return "lang.notrent";
        case config.ROOM_STATUS.WAIT_FOR_CONFIRM:
          return "lang.waitforconfirmation";
      }
    },
    toPriceType(val) {
      switch (val) {
        case DEVICETYPE.ELE:
          return "lang.eleprice";
        case DEVICETYPE.WATER:
          return "lang.waterprice";
        case DEVICETYPE.HOT_WATER:
          return "lang.hotwaterprice";
      }
    },
  },
};
</script>
<style lang="less" scoped>
.form-item-margin {
  margin-right: 20px;
}
.bottom {
  width: 100%;
  height: 80px;
}
.bottom_page {
  position: absolute;
  bottom: 20px;
  right: 0px;
}
.ellipsis_show {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.tip_content {
  margin: 0;
  font-size: 12px;
}
</style>