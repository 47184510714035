const titleList = [{
    label: "lang.filename",
    prop: "fileName",
    width: '100'
},
{
    label: "lang.createtime",
    prop: "startTime",
    width: "150"
},
{
    label: "lang.taskstatus",
    prop: "status",
    width: '100',
    align: "center"
},
{
    label: "lang.completiontime",
    prop: "endTime",
    width: "150"
},
{
    label: "lang.statusInformation",
    prop: "remark",
},

]






export {
    titleList,
}