<template>
  <el-drawer
    title="我是标题"
    size="44%"
    :visible.sync="drawerShow"
    @close="close($event)"
    :with-header="false"
  >
    <div class="device_mask">
      <Top :deviceParams="deviceInfo"></Top>
      <bottom v-if="devicetype==0" ref="bottom_device" :deviceInfo="deviceInfo"></bottom>
      <waterBottom v-else-if="devicetype==1 || devicetype==3" ref="bottom_device" :deviceInfo="deviceInfo"></waterBottom>
      <concentractorBottom v-else ref="concentractorBottom" :deviceInfo="deviceInfo"></concentractorBottom>
    </div>
  </el-drawer>
</template>

<script>
import Top from "./device-top";
import Bottom from "./device-bottom";
import waterBottom from "./water-device-bottom";
import concentractorBottom from "./concentractor-bottom";
export default {
  components: { Top, Bottom ,waterBottom,concentractorBottom},
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
    deviceParams: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      drawerShow: false,
      devicetype:0,
      deviceInfo:{}
    };
  },
  watch: {
    drawer(val) {
      this.drawerShow = val;
    },
    deviceParams(val) {
      console.log(val);
      this.devicetype = val.devicetype;
      this.deviceInfo = val;
      // this.$refs.bottom_device.getList(val);
    },
  },
  methods: {
    close(e) {
      this.$emit("drawerClose", false);
    },
  },
};
</script>

<style lang="less" scoped>
.device_mask {
  padding-top: 48px;
  padding-left: 28px;
  padding-right: 28px;
  overflow-x:hidden;
  overflow-y:auto;
  height: 100vh;
}
</style>