const titleList = [{
        label: "lang.content", //内容
        prop: "disc",
    },
    {
        label: "lang.operator", //操作人
        prop: "operatortype",
        // align: "left"
        width: "100"
    },
    {
        label: "lang.contentdetail", //详细内容
        prop: "remark",
        width: "340"
    },
    // {
    //     label: "lang.status", //详细内容
    //     prop: "status",
    // },
    {
        label: "lang.operatime", //操作时间
        prop: "createtime"
    },
]

export {
    titleList
}