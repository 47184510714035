<template>
  <div>
      <el-dialog
        :visible.sync="equipShow"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        :show-close="false"
        custom-class="equipDialog"
        width="408px"
        :before-close="handleClose">
  <div slot="title" class="equip_title _col_center">
      <img src="@/assets/img/home/lease_title.png" alt="" srcset="">
      <p>{{$t("lang.equiptitle")}}</p>
  </div>
    <template v-for="item,index in equipList">
    <div v-if="equipNum == index" class="equip_content" :key="index">
        <!-- 合约名称 -->
        <div class=" _row_between equip_line">
            <p>{{$t("lang.contractname")}}</p>
            <p>{{item.contractname}}</p>
        </div>
        <!-- 租赁模式 -->
        <div class=" _row_between equip_line">
            <p>{{$t("lang.leasemode")}}</p>
            <p>{{item.contractType | contractTypeFilters(that)}}</p>
        </div>
        <!-- 租金总额 -->
        <div class=" _row_between equip_line">
            <p>{{$t("lang.totalrent")}}</p>
            <p>{{item.totalamount}}{{$t("lang.yuan1")}}</p>
        </div>
        <!-- 每度分成 月租 -->
        <div class=" _row_between equip_line">
            <p>{{item.contractType == 1 ?$t("lang.monthrent"):$t("lang.eachdegree")}}</p>
            <p>{{item.periodamount}}{{$t("lang.yuan1")}}</p>
        </div>
        <!-- 租约期数 -->
        <div class=" _row_between equip_line">
            <p>{{$t("lang.leaseperiod")}}</p>
            <p>{{item.periodcount}}{{$t("lang.amonth")}}</p>
        </div>
        <!-- 祖约时间 -->
        <div class=" _row_between equip_line" style="margin-bottom:0px;">
            <p>{{$t("lang.leasetime")}}</p>
            <p>{{item.starttime | timeFormat("YYYY-MM-DD")}} ~ {{item.endtime | timeFormat("YYYY-MM-DD")}}</p>
        </div>
    </div>
    </template>


  <span slot="footer" class="dialog-footer">
    <el-button @click="$router.push('/login')">{{$t("lang.cancel")}}</el-button>
    <el-button type="primary" @click="equipAgress">{{$t("lang.agree")}}</el-button>
  </span>
</el-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
  export default {
    data() {
      return {
        that:this,
        equipShow: false,
        equipList:[],
        equipNum:0,
        totalItemCount:0,
        
      };
    },
    filters:{
        contractTypeFilters(val,that){
            let ret = "--";
            switch (val) {
                case 0:
                    ret="lang.unknown";
                    break;
                case 1:
                    ret="lang.monthleasemode";
                    break;
                case 2:
                    ret="lang.divided";
                    break;
                case 3:
                    ret="lang.traffic";
                    break;
                default:
                    break;
            }
            return that.$t(ret);
        }
    },
    created(){
        this.getList();
    },
    methods: {
        ...mapActions({
           contractList:"UNIFIED_ContractMsgList",
           setContract:"UNIFIED_ContractMsgConfirm"
        }),
        handleClose(e){
        },
        getList(){
            this.contractList({}).then(res=>{
                console.log(res);
                if (res.success == 1) {
                    this.equipList = res.listItem;
                    this.totalItemCount = res.totalItemCount;
                    this.equipShow = this.totalItemCount>0;
                }
            })
        },
        equipAgress(){
            this.setContract({id:this.equipList[this.equipNum].id}).then(res=>{
                this.equipShow = false;
                if (res.success == 1) {
                    if (this.equipNum < (this.totalItemCount-1)) {
                        this.equipNum += 1;
                        this.equipShow = true;
                    }
                }
            }).catch(err=>{
                this.equipShow = false;
            })
        },
    }
  };
</script>

<style lang="less" scoped>
/deep/.el-dialog{
        position: relative;
    margin: 0 auto 50px;
    background: #FFF;
    border-radius: 10px;
    box-shadow: 0 1px 3px rgb(0 0 0 / 30%);
    box-sizing: border-box;
}
/deep/.el-dialog__header{
        padding: 0px;
    }
.equip_title{
    width: 100%;
    height: 54px;
    position: relative;
    p{
        font-size: 16px;
        font-family: Microsoft YaHei, Microsoft YaHei-Regular;
        font-weight: 400;
        color: #ffffff;
        z-index: 99;
    }
    img{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
    }
}
.equip_content{
    padding: 0 10px;
    .equip_line{
        margin-bottom: 20px;
        p:nth-child(1){
            font-size: 14px;
            font-family: Microsoft YaHei, Microsoft YaHei-Regular;
            font-weight: 400;
            text-align: left;
            color: #999999;
        }
        p:nth-child(2){
            font-size: 14px;
            font-family: Microsoft YaHei, Microsoft YaHei-Regular;
            font-weight: 400;
            text-align: left;
            color: #333333;
        }
    }
}
</style>