<template>
  <div style="margin-top: 15px">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane :label="$t('lang.wateranalysis')" name="first">
        <analysis ref="wateranalysis"></analysis>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import history from "./historical-data/historical-data";
import analysis from "./analysis/analysis-water";
import RechardRecord from "./rechard-record/rechard-record";
import OperaRecord from "./opera-record/opera-record";
export default {
  components: { history, analysis, RechardRecord, OperaRecord },
  props: {
    deviceInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      activeName: "first",
      info: {},
    };
  },
  watch: {
    deviceInfo(val) {
        console.log(val);
      if (val) {
        this.info = val;
        this.$refs.wateranalysis.setData(val);
      }
    },
  },
  mounted() {
    if (this.deviceInfo) {
            this.info = this.deviceInfo;
          this.$refs.wateranalysis.setData(this.deviceInfo);
        }
  },
  methods: {
    handleClick(tab, event) {
          this.$refs.wateranalysis.setData(this.info);
    },
    getList(val){
        if (val) {
            this.info = val;
            this.$refs.history.setData(val);
        }
    },
  },
};
</script>

<style lang="less" scoped>
</style>