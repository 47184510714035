<template>
  <div class="common_bg">
    <div v-show="!showSettlementDate">
      <div id="sign1">
        <el-form inline>
          <el-form-item>
            <el-button type="primary" :disabled="loading">
              <a
                :href="`${path}template2.xlsx`"
                style="color: #fff"
                download="流量卡入库模版.xlsx"
                >下载模板</a
              >
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-upload
              action=""
              class="upload-demo"
              :auto-upload="false"
              :show-file-list="false"
              multiple
              :limit="999"
              :on-change="handleChange"
              :disabled="loading"
            >
              <el-button type="primary" plain :disabled="loading"
                >加载订单</el-button
              >
            </el-upload>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              plain
              @click="onClickWarehousing"
              :disabled="loading"
              >入库</el-button
            >
          </el-form-item>
          <el-form-item>
            <div style="display: flex; align-items: flex-end">
              <el-upload
                action="#"
                :http-request="doMyUploadPic1"
                :before-upload="beforeUpload1"
                :limit="100"
                :disabled="loading"
              >
                <el-button
                  type="primary"
                  plain
                  @click="onClickUploadFile"
                  :disabled="loading"
                  >上传附件</el-button
                >
              </el-upload>
              <div class="file_name" v-if="fileName !== ''">
                <i class="el-icon-folder"></i>
                <span>{{ fileName }}</span>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div v-loading="loading" :element-loading-text="loadingMsg">
        <div :class="[errorList.length < 1 ? 'sign3' : '', 'sign2']">
          <el-tabs v-model="activeName" @tab-click="handleClick" ref="tabs">
            <el-tab-pane label="订单信息" name="a">
              <div class="order_message_box">
                <div
                  class="item_box"
                  v-for="(item, index) in baseMsg"
                  :key="index"
                >
                  <span class="title">{{ item.title }}</span>
                  <span v-if="item.title != '结算日期'" class="content">{{
                    item.value
                  }}</span>
                  <span
                    v-else
                    :class="[item.value == '--' ? '' : 'detail', 'content']"
                    @click="onClickCheckDetail(item.value)"
                    >{{ item.value }}</span
                  >
                </div>
              </div>
              <div class="remark_box">
                <span class="title">备注</span>
                <span class="content">{{ repEmpty(remark, "--") }}</span>
              </div>
            </el-tab-pane>
            <el-tab-pane label="错误信息" name="b">
              <div class="error_msg_box">
                <div v-for="(item, index) in errorList" :key="index">
                  {{ index + 1 }}、{{ item }}
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
        <el-table
          :data="tableData"
          style="width: 100%; margin-top: 10px"
          max-height="432"
        >
          <!-- 不分页 -->
          <el-table-column label="编号" type="index" width="80">
          </el-table-column>
          <el-table-column
            v-for="(item, index) in tableTitle"
            :key="index"
            :prop="item.prop"
            align="left"
            :limit="1"
            :label="item.label"
            :show-overflow-tooltip="true"
            :formatter="formatter"
            :width="item.width"
          >
          </el-table-column>
          <template slot="empty">
            <span>没有错误数据</span>
          </template>
        </el-table>
      </div>
    </div>
    <div v-show="showSettlementDate">
      <settlement-date-details ref="settlementDateDetails" />
    </div>
  </div>
</template>
<script>
import XLSX, { WorkSheet } from "xlsx";
import { mapActions } from "vuex";
import { storeTitleList, storeMsg } from "./titleOptions";
import mixinf from "../../common/mixins/mixin_func";
import moment from "moment";
import settlementDateDetails from "./children/settlement-date-details.vue";
export default {
  components: { settlementDateDetails },
  name: "FlowCardStore",
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$nextTick(() => {
        vm.addKeepAlive("FlowCardStore");
      });
    });
  },
  mixins: [mixinf],
  data() {
    return {
      /**显示结算日期 */
      showSettlementDate: false,
      /**结算日期列表 */
      settlementDateList: [],
      path: process.env.BASE_URL,
      activeName: "a",
      tableTitle: storeTitleList,
      tableData: [],
      excelData: [],
      storeMsg: storeMsg,
      baseMsg: [],
      remark: "",
      fileUrl: "",
      //
      orderMsg: {
        orderId: "",
        equipType: "",
        version: "",
        equipNum: "",
        remark: "",
        commaddress: "",
      },
      req: {
        devicetypeid: "",
        versionId: "",
        listitem: [],
      },
      /**上传的附件名称 */
      fileName: "",
      /**是否已加载订单 */
      isLoading: false,
      /**是否正在验证 */
      loading: false,
      loadingMsg: "",
      /**错误信息列表 */
      errorList: [],
      /**运营商列表 */
      operatorList: [
        {
          key: "电信",
          value: 2,
        },
        {
          key: "移动",
          value: 0,
        },
        {
          key: "联通",
          value: 1,
        },
        {
          key: "其他",
          value: -1,
        },
      ],
      netList: [
        {
          key: "4G",
          value: 2,
        },
        {
          key: "NB",
          value: 0,
        },
        {
          key: "GPRS",
          value: 1,
        },
        {
          key: "其他",
          value: -1,
        },
      ],
    };
  },
  mounted() {
    this.baseMsg = this.deepCopy(this.storeMsg);
  },
  methods: {
    //#region 错误信息维护
    addErrorMsg(msg) {
      let tempVer = true;
      for (const key in this.errorList) {
        if (this.errorList[key] == msg) {
          tempVer = false;
          break;
        }
      }
      if (tempVer) this.errorList.push(msg);
      if (tempVer && this.errorList.length < 2) this.activeName = "b";
    },
    clearErrorMsg() {
      this.activeName = "a";
      this.errorList = [];
    },
    //#endregion
    //#region 表格维护
    addTable(item) {
      if (this.tableData.length > 100) return;
      let tempVer = true;
      for (const key in this.tableData) {
        if (this.tableData[key].id === item.id) {
          tempVer = false;
          break;
        }
      }
      if (tempVer) this.tableData.push(item);
    },
    //#endregion
    //#region 接口数据
    ...mapActions({
      addKeepAlive: "addKeepAlivePage",
      flowCardOrderSubmit: "ZD_FlowCardOrderSubmit",
      upload: "ZD_UPLOAD_FILE",
    }),
    iFlowCardOrderSubmit() {
      this.loading = true;
      this.loadingMsg = "正在入库中";
      //时间处理
      let tempList = this.deepCopy(this.settlementDateList);
      tempList.forEach((elem) => {
        elem.settleTime = elem.settleTime + " 00:00:00";
      });
      //
      let tempReq = {
        company: this.baseMsg[0].value == "--" ? "" : this.baseMsg[0].value,
        platform: this.baseMsg[1].value == "--" ? "" : this.baseMsg[1].value,
        buyer: this.baseMsg[2].value == "--" ? "" : this.baseMsg[2].value,
        provider: this.baseMsg[3].value == "--" ? "" : this.baseMsg[3].value,
        plainName: this.baseMsg[4].value == "--" ? "" : this.baseMsg[4].value,
        carrierType: this.getOperator(this.baseMsg[5].value),
        //订单号
        orderno: this.baseMsg[6].value == "--" ? "" : this.baseMsg[6].value,
        //订单数量
        cardCount: this.baseMsg[7].value == "--" ? "" : this.baseMsg[7].value,
        //结算日期跳过
        //客户经理
        customerManager:
          this.baseMsg[9].value == "--" ? "" : this.baseMsg[9].value,
        //网络类型
        flowType: this.getNet(this.baseMsg[10].value),
        //套餐详情
        plainDetail:
          this.baseMsg[11].value == "--" ? "" : this.baseMsg[11].value,
        //受理时间
        acceptTime:
          this.baseMsg[12].value == "--" ? "" : this.baseMsg[12].value,
        //受理区域
        acceptArea:
          this.baseMsg[13].value == "--" ? "" : this.baseMsg[13].value,
        //联系方式
        phonenum: this.baseMsg[14].value == "--" ? "" : this.baseMsg[14].value,
        //////////////////////////////////
        // plainPrice: this.baseMsg[9].value == "--" ? "" : this.baseMsg[9].value,
        // paymentMethod:
        //   this.baseMsg[10].value == "--" ? "" : this.baseMsg[10].value,
        // chargingTime:
        //   this.baseMsg[11].value == "--" ? "" : this.baseMsg[11].value,
        settleTimeInfo: tempList,
        remark: this.remark,
        fileUrl: this.fileUrl,
        listItem: this.excelData,
      };
      this.flowCardOrderSubmit(tempReq).then((res) => {
        if (res.success === 1) {
          this.$message.success("入库成功");
          this.$router.push({
            path: "/dataCardManagement",
            query: {
              isUpdate: true,
            },
          });
          setTimeout(() => {
            this.$bus.$emit("navClose", "/flowcardstore");
          }, 20);
        } else {
          if (this.isEmpty(res.failList)) {
            this.addErrorMsg(res.errordetail);
          } else {
            res.failList.forEach((elem) => {
              this.addErrorMsg(elem.failReason);
            });
            this.tableData = res.failList;
          }
        }
        this.loading = false;
      });
      console.log("get>>", tempReq);
    },
    getOperator(key) {
      for (const k in this.operatorList) {
        if (this.operatorList[k].key == key) return this.operatorList[k].value;
      }
      return "";
    },
    getNet(key) {
      for (const k in this.netList) {
        if (this.netList[k].key == key) return this.netList[k].value;
      }
      return "";
    },
    //#endregion
    /**tab切换 */
    handleClick() {
      //
    },
    /**表格过滤 */
    formatter(row, { property }) {
      // console.log(">>", property);
      let ret = null;
      switch (property) {
        case "carrierType":
          ret = this.options.find((item) => item.value == row[property]).label;
          break;
        case "flowType":
          ret = this.options1.find((item) => item.value == row[property]).label;
          break;
        case "cardStatus":
          if (row[property] == 0) {
            ret = "未激活";
          } else if (row[property] == 1) {
            ret = "在用";
          } else if (row[property] == 3) {
            ret = "注销";
          } else {
            ret = "未知";
          }
          break;
        case "acceptTime":
          ret = row[property]
            ? moment(row[property]).format("YYYY-MM-DD HH:mm")
            : "--";
          break;
        case "activeTime":
          ret = row[property]
            ? moment(row[property]).format("YYYY-MM-DD HH:mm")
            : "--";
          break;
        case "cancelTime":
          ret = row[property]
            ? moment(row[property]).format("YYYY-MM-DD HH:mm")
            : "--";
          break;
        default:
          ret = row[property] ? row[property] : row[property] == 0 ? 0 : "--";
          break;
      }
      return ret;
    },
    //#region 上传文件
    handleChange(file) {
      this.clearErrorMsg();
      this.tableData = [];
      this.isLoading = true;
      this.loading = true;
      this.loadingMsg = "正在校验中";
      // let file = file.files[0] // 使用传统的input方法需要加上这一步
      let tempNames = file.name.split(".");
      const types = tempNames[tempNames.length - 1];
      // const fileType = ["xlsx", "xlc", "xlm", "xls", "xlt", "xlw", "csv"].some(
      //   (item) => item === types
      // );
      const fileType = ["xlsx"].some((item) => item === types);
      if (!fileType) {
        alert("格式错误！请选择xlsx格式文件");
        this.loading = false;
        this.loadingMsg = "";
        return;
      }
      setTimeout(() => {
        this.file2Xce(file)
          .then((tabJson) => {
            if (tabJson && tabJson.length < 1) return;
            if (tabJson[0].sheet < 1) {
              this.addErrorMsg("没有检测到订单信息");
              return;
            }
            let tempItem;
            tempItem = tabJson[0].sheet[0];
            //订单信息检测
            this.baseMsg.forEach((elem, idx) => {
              if (elem.title == "结算日期") {
                this.baseMsg[idx].value = "查看详情";
                return;
              }
              if (this.isEmpty(tempItem[elem.title]))
                this.addErrorMsg(elem.title + "为空");
              else this.baseMsg[idx].value = tempItem[elem.title];
            });
            this.remark = tempItem["备注"];

            //流量卡信息检测
            this.handleExcelData(tabJson[1].sheet);
            this.paymentCycle(tabJson[2].sheet);
          })
          .catch(() => {
            this.loading = false;
          })
          .finally((f) => {
            this.loading = false;
          });
      }, 300);
    },
    /**付费周期数据处理 */
    paymentCycle(list) {
      this.settlementDateList = [];
      list.forEach((elem) => {
        this.settlementDateList.push({
          settleTime: elem["结算日期"],
          amount: elem["结算金额"],
        });
      });
    },
    handleExcelData(list) {
      if (list.length < 1) {
        this.addErrorMsg("没有检测到流量卡信息");
        return;
      }
      this.excelData = list.map((item, index) => {
        return {
          id: index,
          phonenum: this.repEmpty(item["用户号码"], ""),
          ccid: this.repEmpty(item["ICCID"], ""),
          // ccid: item["ICCID"],
          imei: this.repEmpty(item["L_IMSI"], ""),
          cardDisc: this.repEmpty(item["卡状态"], ""),
          cardStatus: this.repEmpty(item["产品状态"], ""),
          acceptTime: this.repEmpty(item["受理时间"], ""),
          activeTime: this.repEmpty(item["激活时间"], ""),
          cancelTime: this.repEmpty(item["注销时间"], ""),
        };
      });
      //订单数量检测
      if (
        this.baseMsg[7].value == "--" ||
        isNaN(this.baseMsg[7].value - 0) ||
        this.baseMsg[7].value - 0 !== this.excelData.length
      ) {
        this.addErrorMsg("订单数量与实际数量不一致");
        // if (this.excelData.length < 101) this.tableData = this.excelData;
        // else
        //   for (const key in this.excelData) {
        //     if (key > 99) break;
        //     this.tableData.push(this.excelData[key]);
        //   }
      }
      //前端验证
      this.deviceImport();
    },
    file2Xce(file) {
      let that = this;
      this.showTabelError = false;
      this.orderMsg.orderId = file.name.split(".")[0];
      return new Promise(function (resolve, reject) {
        const reader = new FileReader();
        reader.onload = function (e) {
          const data = e.target.result;
          this.wb = XLSX.read(data, {
            type: "binary",
          });
          const result = [];
          console.log(this.wb);
          this.wb.SheetNames.forEach((sheetName) => {
            result.push({
              sheetName: sheetName,
              sheet: XLSX.utils.sheet_to_json(this.wb.Sheets[sheetName]),
            });
          });
          //#region 定制修改
          //订单信息
          if (!that.isEmpty(result[1].sheet) && result[1].sheet.length > 0) {
            result[0].sheet.forEach((elem) => {
              if (!that.isEmpty(elem["受理时间"]))
                elem["受理时间"] = that.toDate(elem["受理时间"]);
            });
          }
          //流量卡信息
          if (!that.isEmpty(result[1].sheet) && result[1].sheet.length > 0) {
            result[1].sheet.forEach((elem) => {
              if (!that.isEmpty(elem["受理时间"]))
                elem["受理时间"] = that.toDate(elem["受理时间"]);
              if (!that.isEmpty(elem["激活时间"]))
                elem["激活时间"] = that.toDate(elem["激活时间"]);
              if (!that.isEmpty(elem["注销时间"]))
                elem["注销时间"] = that.toDate(elem["注销时间"]);
            });
          }
          //付费周期
          if (!that.isEmpty(result[2].sheet) && result[2].sheet.length > 0) {
            result[2].sheet.forEach((elem) => {
              if (!that.isEmpty(elem["结算日期"]))
                elem["结算日期"] = that.toDate(elem["结算日期"]).split(" ")[0];
            });
          }
          //基础数据
          if (!that.isEmpty(result[3].sheet) && result[3].sheet.length > 0) {
            result[3].sheet.forEach((elem) => {
              if (!that.isEmpty(elem["受理时间"]))
                elem["受理时间"] = that.toDate(elem["受理时间"]);
            });
          }
          //#endregion
          resolve(result);
        };
        reader.readAsBinaryString(file.raw);
        // reader.readAsBinaryString(file) // 传统input方法
      });
    },
    //将excel的日期格式转成Date()对象;
    toDate(serial) {
      if (typeof serial == "string") return serial;
      var utc_days = Math.floor(serial - 25569);
      var utc_value = utc_days * 86400;
      var date_info = new Date(utc_value * 1000);
      var fractional_day = serial - Math.floor(serial) + 0.0000001;
      var total_seconds = Math.floor(86400 * fractional_day);
      var seconds = total_seconds % 60;
      total_seconds -= seconds;
      var hours = Math.floor(total_seconds / (60 * 60));
      var minutes = Math.floor(total_seconds / 60) % 60;
      var d = new Date(
        date_info.getFullYear(),
        date_info.getMonth(),
        date_info.getDate(),
        hours,
        minutes,
        seconds
      );
      return this.toYMDHMS(d);
    },
    toYMDHMS(time) {
      let dateTime;
      let date = new Date(time);
      let yy = date.getFullYear();
      let mm = date.getMonth() + 1;
      let dd = date.getDate();
      let hh = date.getHours();
      let mf = date.getMinutes();
      let ss = date.getSeconds();
      mm = mm < 10 ? "0" + mm : mm;
      dd = dd < 10 ? "0" + dd : dd;
      hh = hh < 10 ? "0" + hh : hh;
      mf = mf < 10 ? "0" + mf : mf;
      ss = ss < 10 ? "0" + ss : ss;
      dateTime = yy + "-" + mm + "-" + dd + " " + hh + ":" + mf + ":" + ss;
      return dateTime;
    },
    getAnnualFee(idx) {
      if (this.isEmpty(idx)) return "--";
      if (parseInt(idx) === 0) return "不收取";
      if (parseInt(idx) === 1) return "收取";
      return "未知";
    },
    /**验证导入列表 */
    deviceImport() {
      if (this.errorList.length > 0) {
        this.loading = false;
        return;
      }
      //todo
      let tempListUserNum = [],
        tempListIMEI = [],
        tempListCCID = [];
      for (const key in this.excelData) {
        if (this.tableData.length > 99) {
          this.verOver();
          return;
        }
        //用户号码
        if (this.excelData[key].phonenum !== "") {
          if (tempListUserNum[this.excelData[key].phonenum]) {
            this.addTable(this.excelData[key]);
            this.addErrorMsg("用户号码存在重复");
          } else tempListUserNum[this.excelData[key].phonenum] = true;
        }
        //IMEI号
        if (this.excelData[key].imei !== "") {
          if (tempListIMEI[this.excelData[key].imei]) {
            this.addTable(this.excelData[key]);
            this.addErrorMsg("IMEI号存在重复");
          } else tempListIMEI[this.excelData[key].imei] = true;
        }
        //
        if (this.excelData[key].ccid !== "") {
          if (tempListCCID[this.excelData[key].ccid]) {
            this.addTable(this.excelData[key]);
            this.addErrorMsg("CCID存在重复");
          } else tempListCCID[this.excelData[key].ccid] = true;
        }
        if (
          this.excelData[key].cardStatus != -1 &&
          this.excelData[key].cardStatus != 0 &&
          this.excelData[key].cardStatus != 1 &&
          this.excelData[key].cardStatus != 3
        ) {
          this.addTable(this.excelData[idx]);
          this.addErrorMsg("产品状态格式输入错误");
        }
      }
      this.verOver();
    },
    verOver() {
      this.loading = false;
      if (this.errorList.length < 1) this.$message.success("验证通过");
      else this.$message.error("验证错误");
    },
    //#endregion

    //#region 上传附件
    doMyUploadPic1(file) {
      this.loading = true;
      const params = new FormData();
      params.append("file", file.file);
      this.upload(params).then((res) => {
        var url = res.url;
        this.fileUrl = url;
        this.loading = false;
        this.$message.success("上传成功");
      });
      this.fileName = file.file.name.split(".")[0];
    },
    beforeUpload1(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      let type = file.name.split(".");
      let length = type.length - 1;
      let isText =
        type[length] == "xls" ||
        type[length] == "xlsx" ||
        type[length] == "doc" ||
        type[length] == "docx" ||
        type[length] == "rar" ||
        type[length] == "zip";
      if (!isText) {
        this.$message.error("上传文件只能是 EXCEL/WORD/RAR/ZIP 格式!");
      } else {
        this.text2 = file.name;
      }
      // if (!isLt2M) {
      //   this.$message.error('上传头像图片大小不能超过 2MB!');
      // }
      return isText;
    },
    //#endregion
    onClickUploadFile() {
      //
    },
    onClickWarehousing() {
      if (!this.isLoading) {
        this.$message.warning("请先加载订单");
        return;
      } else if (this.errorList.length > 0) {
        this.$message.warning("订单信息错误");
        return;
      }
      this.$confirm("您确定要将订单信息入库吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.iFlowCardOrderSubmit();
        })
        .catch(() => {});
    },
    //结算日期 查看详情
    onClickCheckDetail(val) {
      if (val == "--") return;
      this.$refs.settlementDateDetails.init(this.settlementDateList);
      this.showSettlementDate = true;
    },
    onClickGoBack(idx = 1) {
      if (idx == 1) this.showSettlementDate = false;
    },
  },
};
</script>

<style lang="less" scoped>
#sign1 {
  /deep/ .el-form-item {
    margin-bottom: 24px;
  }
  /deep/ .el-form--inline .el-form-item {
    margin-right: 24px;
  }
  /deep/.el-button--primary.is-plain {
    background-color: #fff;
    color: #409eff;
  }
}
.sign2 {
  .order_message_box {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    font-size: 14px;
    padding: 24px 64px 0 64px;
    .item_box {
      display: flex;
      align-items: center;
      color: #999;
      margin-bottom: 10px;
      .title {
        width: 60px;
        text-align: right;
        margin-right: 8px;
      }
      .content {
        width: 182px;
        color: #333;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 100%;
      }
      .detail {
        text-decoration: underline;
        color: #409eff;
        cursor: pointer;
      }
      .detail:hover {
        color: #6db6ff;
      }
      span {
        display: inline-block;
      }
    }
  }
  .remark_box {
    color: #999;
    font-size: 14px;
    padding: 0px 64px 24px 64px;
    display: flex;
    align-items: center;
    .title {
      width: 60px;
      text-align: right;
      margin-right: 8px;
    }
    .content {
      width: calc(100% - 132px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 100%;
      color: #333;
    }
    span {
      display: inline-block;
    }
  }
  .error_msg_box {
    margin: 0 0px 16px 104px;
    box-sizing: border-box;
    height: 80px;
    font-size: 14px;
    color: #f56c6c;

    overflow: auto;

    div {
      display: inline-block;
      margin-right: 120px;
      margin-bottom: 20px;
      min-width: 140px;
    }
  }
  /deep/ .el-tabs {
    border: 1px solid #409eff;
  }
  /deep/ .el-tabs__header {
    background-color: rgba(64, 158, 255, 0.1);
    padding-left: 16px;
    margin-bottom: 13px;
  }
  /deep/ .el-tabs__item {
    padding: 0 16px;
    height: 45px;
    line-height: 45px;
  }
  /deep/ .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
    color: #333;
  }
  /deep/ .el-tabs--top .el-tabs__item.is-top:last-child {
    color: #f56c6c;
  }
}
.sign3 {
  /deep/ .el-tabs--top .el-tabs__item.is-top:last-child {
    display: none;
  }
}
/deep/ .el-form-item__content ul {
  display: none !important;
}
.file_name {
  display: flex;
  line-height: 100%;
  margin-left: 8px;
  color: #999;
  font-size: 12px;
  span {
    margin-left: 4px;
  }
}
</style>