<template>
  <div class="table_box" :id="borderType === 'none' ? 'none' : 'inner'">
    <el-table
      :cell-style="{
        fontSize: '14px',
        paddingTop: '6.5px',
        paddingBottom: '6.5px',
        height: '36px',
      }"
      :header-cell-style="{ height: '40px' }"
      :data="tableData"
      :max-height="maxHeight != 'none' ? maxHeight : '500'"
      :show-summary="summaryMethod !== null"
      :summary-method="summaryMethod"
      @selection-change="(val) => showSelection(val)"
      :key="updateKey"
    >
      <!-- <el-table-column width="20"> </el-table-column> -->
      <el-table-column
        v-if="showSelection && selectable"
        type="selection"
        :selectable="selectable"
        width="55"
      ></el-table-column>
      <el-table-column
        v-if="showIndex"
        label="序号"
        align="center"
        width="80"
        type="index"
      >
      </el-table-column>
      <el-table-column
        :show-overflow-tooltip="showOverflow"
        v-for="(item, index) in tableTitle"
        :key="index"
        :align="item.align"
        :width="item.width"
      >
        <template slot="header">
          <div
            class="table_title_style"
            :title="
              item.label.indexOf('.') !== -1 ? $t(item.label) : item.label
            "
          >
            {{ item.label.indexOf(".") !== -1 ? $t(item.label) : item.label }}
          </div>
        </template>
        <template slot-scope="scope" v-if="!item.list">
          <div class="font_style_default">
            <slot name="rowRep1" :rowData="{ scope, prop: item.prop }">
              <p>
                {{ repEmpty(scope.row[item.prop], "--") }}
              </p>
            </slot>
          </div>
        </template>
        <el-table-column
          :show-overflow-tooltip="true"
          v-for="(item2, index2) in item.list"
          :key="index2"
          :align="item2.align"
          :width="item2.width"
        >
          <template slot="header">
            <div
              class="table_title_style"
              :title="
                item2.label.indexOf('.') !== -1 ? $t(item2.label) : item2.label
              "
            >
              {{
                item2.label.indexOf(".") !== -1 ? $t(item2.label) : item2.label
              }}
            </div>
          </template>
          <template slot-scope="scope">
            <!-- 其他 -->
            <div>
              <p class="font14 color_666">
                {{ repEmpty(scope.row[item2.prop], "--") }}
              </p>
            </div>
          </template>
        </el-table-column>
      </el-table-column>
      <slot name="operate"></slot>
    </el-table>
    <span id="mask"></span>
  </div>
</template>
<script>
import mixinF from "../../common/mixins/mixin_func";
export default {
  mixins: [mixinF],
  props: {
    /**是否显示序号 */
    showIndex: {
      type: Boolean,
      default: false,
      required: false,
    },
    //#region 多选框
    /**显示多选框，等同于@selection-change */
    showSelection: {
      type: Function,
      default: null,
      required: false,
    },
    /**多选框是否可以选择，return true or false */
    selectable: {
      type: Function,
      default: null,
      required: false,
    },
    //#endregion
    tableData: {
      type: Array,
      default: [],
      required: true,
    },
    tableTitle: {
      type: Array,
      default: [],
      required: true,
    },
    maxHeight: {
      type: String,
      default: "none",
      required: false,
    },
    /**none没边框 inner内边框显示 */
    borderType: {
      type: String,
      default: "none",
      required: false,
    },
    summaryMethod: {
      type: Function,
      default: null,
      required: false,
    },
    /**行超出时以提示形式 显示全部内容 */
    showOverflow: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  components: {},
  data() {
    return {
      updateKey: 0,
    };
  },
  created() {},
  mounted() {},
  methods: {
    getKey() {
      this.updateKey = Math.floor(Math.random() * 10000);
    },
  },
  computed: {},
  watch: {
    tableData(val) {
      this.getKey();
    },
  },
  filters: {},
  //keepAlive
  activated() {},
  deactivated() {},
  updated() {},
  destroyed() {},
};
</script>
<style lang='less' scoped>
.table_box {
  margin-top: 22px;
  position: relative;
  //   /deep/.el-table__body-wrapper {
  //   max-height: calc(100vh - 450px);
  //   width: 100%;
  //   overflow: auto;
  //   }
  //   /deep/ .el-table {
  //     &::before {
  //       height: 0px !important;
  //     }
  //   }
  .font_style_default div,
  p {
    font-size: 14px;
    color: #666;
  }
  .font14 {
    font-size: 14px;
  }
  .color666 {
    color: #666;
  }
  .table_title_style {
    //一行
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  #mask {
    position: absolute;
    top: 0;
    right: 0px;
    width: 2px;
    height: 100%;
    background-color: #fff;
    z-index: 1;
  }
  /deep/ .el-table thead.is-group th.el-table__cell {
    background-color: rgba(87, 184, 255, 0.05);
  }
  /deep/ .el-table .cell {
    font-size: 14px;
    color: #666;
  }
}
#inner /deep/ .el-table--border,
.el-table--group {
  border: none;
}

#none /deep/ .el-table--border,
.el-table--group {
  border: none;
}
#none /deep/ .el-table--border .el-table__cell,
.el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
  border-right: none;
}
#none /deep/ .el-table--border th.el-table__cell,
.el-table__fixed-right-patch {
  border-bottom: none;
}
/*使用示例
      <template v-slot:rowRep1="{ rowData }">
        <div v-if="rowData.prop == 'level'">
          <el-rate
            disabled
            v-model="reqParams.value"
            disabled-void-color="#C4C4C4"
            text-color="#ff9900"
          />
        </div>
        <div v-else>
          {{ repEmpty(rowData.scope.row[rowData.prop], "--") }}
        </div>
      </template>
      <template v-slot:operate>
        <el-table-column label="操作" width="155">
          <template slot-scope="scope">
            <cm-operate :operates="getOperate(scope.row)" />
          </template>
        </el-table-column>
      </template>
      */
</style>