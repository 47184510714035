<template>
  <div class="device_top">
    <el-row :gutter="20">
      <el-col :span="2">
        <div
          class="device_mask_left_water _col_center"
          v-if="deviceParams.devicetype == 1 || deviceParams.devicetype == 3"
        >
          <i
            class="iconfont icon-shui"
            style="color: #409eff; font-size: 20px"
          ></i>
        </div>
        <div
          class="device_mask_left_ele _col_center"
          v-else-if="deviceParams.devicetype == 0"
        >
          <i
            class="iconfont icon-dian"
            style="color: #fdb344; font-size: 20px"
          ></i>
        </div>
        <div class="device_mask_left_ele _col_center" v-else>
          <i
            class="iconfont icon-jizhongqi"
            style="color: #fdb344; font-size: 20px"
          ></i>
        </div>
      </el-col>
      <el-col :span="20">
        <div>{{ $t("lang.assetno") }}</div>
        <div class="_row">
          <p class="font18 margin16" style="font-weight: bold">
            {{ info.inmeterno || "--" }}
          </p>
          <p class="font14">
            {{ $t("lang.installposition") }}：{{ info.postion || "--" }}
          </p>
        </div>
        <div class="_row marginTop15">
          <p
            v-if="info.deviceType"
            :class="[
              'font14',
              'padding78',
              deviceParams.devicetype == 1 ? 'bgc_blue' : 'bgc_orange',
              'margin16',
            ]"
          >
            {{ info.deviceType || "--" }}
          </p>
          <p class="font14 padding78 bgc_blue">
            {{ $t("lang.version") }}：{{ info.version || "--" }}
          </p>
        </div>
        <div class="_row marginTop15" v-if="info.imei || info.sim">
          <p class="font14" v-if="info.imei">
            {{ $t("lang.IMEI") }}：{{ info.imei || "--" }}
          </p>
          <div class="grey_line" v-if="info.sim"></div>
          <p class="font14" v-if="info.sim">
            {{ $t("lang.SIM") }}：{{ info.sim || "--" }}
          </p>
        </div>
        <div class="_row marginTop15">
          <p class="font14">
            {{ $t("lang.addtime") }}：{{ info.addTime | timeFormat }}
          </p>
          <div class="grey_line"></div>
          <p class="font14">
            {{ $t("lang.qualityperiod") }}：{{
              info.warrantyTime | timeFormat("YYYY-MM-DD")
            }}
          </p>
          <div class="grey_line"></div>
          <p class="font14">
            {{ $t("lang.trafficfree") }}：{{
              info.trafficFreeTime | timeFormat("YYYY-MM-DD")
            }}
          </p>
        </div>
      </el-col>
    </el-row>
    <div class="bottom_line"></div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    deviceParams: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      reqDate: {
        meterId: null,
        type: null,
      },
      info: {},
    };
  },
  watch: {
    deviceParams(val) {
      this.reqDate.meterId = val.meterId;
      this.reqDate.type = val.type;
      this.getInfo();
    },
  },
  mounted() {
    // this.getInfo();
    if (this.deviceParams) {
      this.reqDate.meterId = this.deviceParams.meterId;
      this.reqDate.type = this.deviceParams.type;
      this.getInfo();
    }
  },
  methods: {
    ...mapActions({
      deviceInfo: "ZD_DEVICE_INFO",
    }),
    getInfo() {
      this.deviceInfo(this.reqDate).then((res) => {
        console.log(res);
        if (res.success == 1) {
          this.info = res.value;
          this.$bus.$emit("phaseType", res.value);
        }
      });
    },
  },
  destroyed() {
    this.$bus.$off("phaseType");
  },
};
</script>

<style scoped>
.device_mask_left_ele {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background: rgba(254, 233, 200, 0.7);
}
.device_mask_left_water {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background: rgba(64, 158, 255, 0.3);
}
.margin16 {
  margin-right: 16px;
}
.marginTop15 {
  margin-top: 15px;
}
.padding78 {
  padding: 7px 8px;
}
.bgc_orange {
  color: #fdb344;
  background: rgba(254, 233, 200, 0.7);
  border-radius: 4px;
}
.bgc_blue {
  color: rgba(64, 158, 255, 1);
  background: rgba(64, 158, 255, 0.3);
  border-radius: 4px;
}
.grey_line {
  width: 1px;
  height: 16px;
  opacity: 1;
  background: #c0c4cc;
  margin: 0 15px;
}
.bottom_line {
  width: 100%;
  height: 4px;
  opacity: 1;
  background: #e4e7ed;
  margin-top: 16px;
}
.device_top {
  /* height: 19.5vh;
    overflow-y: auto;
    overflow-x: hidden; */
}
</style>