<template>
  <span id="sign1">
    <el-button size="mini" round>
      <i
        style="color: #999; font-size: 12px"
        class="icon-pc-fanhui iconfont"
      ></i>
      返回</el-button
    >
  </span>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
#sign1 {
  display: inline-block;
}
#sign1 /deep/ .el-button {
  color: #999;
  font-size: 12px;
}
</style>
