<template>
  <div class="common_bg">
    <span
      style="display: inline-block; margin-bottom: 20px"
      @click="onClickGoBack"
    >
      <cm-back />
    </span>
    <div style="padding: 0 30px 0 10px">
      <div class="_row top_detail">
        <div class="_row">
          <p>生产订单号：</p>
          <p>{{ orderInfo.orderid || "--" }}</p>
        </div>
        <div class="_row">
          <p>设备类型：</p>
          <p>{{ orderInfo.devicetypeShow || "--" }}</p>
        </div>
        <div class="_row">
          <p>导入设备数量：</p>
          <p>{{ orderInfo.devicecount || "--" }}</p>
        </div>
        <div class="_row">
          <p>设备版本号：</p>
          <p>{{ orderInfo.versionShow || "--" }}</p>
        </div>
      </div>
    </div>
    <div style="margin: 20px 0 40px 10px">
      <p class="font14" style="color: #666666">
        备注:{{ orderInfo.remark || "--" }}
      </p>
    </div>
    <el-form inline>
      <el-form-item label="资产号">
        <el-input
          placeholder="请输入资产号"
          v-model="reqParams.inmeternoSearch"
          :maxlength="20"
          @keyup.enter.native="refrash"
        ></el-input>
      </el-form-item>
      <el-form-item label="IMEI">
        <el-input
          placeholder="请输入IMEI"
          v-model="reqParams.imeiSearch"
          :maxlength="20"
          @keyup.enter.native="refrash"
        ></el-input>
      </el-form-item>
      <el-form-item label="注册状态" class="form_margin" v-if="canRegister">
        <el-select v-model="reqParams.nbstatus" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="form_margin">
        <el-button type="primary" @click="refrash">查询</el-button>
      </el-form-item>
      <el-form-item class="form_margin">
        <el-button type="primary" plain v-if="!isEdit" @click="edit(0)"
           style="background: #ecf5ff">编辑</el-button
        >
        <el-button type="primary" plain v-else @click="edit(1)" style="background: #ecf5ff">取消</el-button>
      </el-form-item>
      <el-form-item class="form_margin">
        <el-button type="primary" plain @click="deleteDeviceAll" style="background: #ecf5ff">删除</el-button>
      </el-form-item>
      <el-form-item class="form_margin" label="" id="sign2">
        <el-button plain @click="onClickFlowCardVerification"
          >流量卡校验</el-button
        >
      </el-form-item>
    </el-form>
    <el-table
      :data="tableData"
      ref="multipleTable"
      style="width: 100%; margin-top: 10px"
      @selection-change="handleSelectionChange"
    >
      <el-table-column v-if="isEdit" type="selection" width="55">
      </el-table-column>
      <el-table-column
        label="序号"
        type="index"
        :index="getIndex"
        width="100"
      ></el-table-column>
      <template v-for="(item, index) in titleList">
        <el-table-column
          v-if="
            canRegister
              ? true
              : item.prop != 'nbstatus' && item.prop != 'regtime'
          "
          :key="index"
          :prop="item.prop"
          align="left"
          :min-width="item.width"
          :label="item.label"
          :show-overflow-tooltip="true"
          :formatter="formatter"
        >
        </el-table-column>
      </template>
      <el-table-column label="操作" width="140">
        <template slot-scope="scope" class="_row">
          <el-button type="text" @click="deleteDevice(scope.row)" style="color:#F56C6C"
            >删除</el-button
          >
          <el-button type="text" v-if="canRegister">|</el-button>
          <el-button
            @click="detail(scope.row)"
            type="text"
            :disabled="scope.row['nbstatus'] != 3"
            >{{ canRegister ? "重新注册" : "" }}</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div style="height: 65px"></div>
    <div>
      <el-pagination
        class="bottom_page"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :total="totalItemCount"
        :current-page.sync="reqParams.pageNum"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="10"
      >
      </el-pagination>
    </div>
    <device-delete ref="deviceDelete"></device-delete>
    <flowcard-verification-dialog ref="flowcardVerification" />
  </div>
</template>
<script>
import deviceDelete from "./dialog/device-delete.vue";
import { tableTitleListDetail } from "./titleList";
import XLSX from "xlsx";
import { mapActions } from "vuex";
import mixinf from "../../common/mixins/mixin_func";
import FlowcardVerificationDialog from "./dialog/flowcard-verification-dialog.vue";
import CmBack from "../../components/common/cm-back.vue";
export default {
  mixins: [mixinf],
  name: "EquipmentDetail",
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$nextTick(() => {
        vm.addKeepAlive("EquipmentDetail");
      });
    });
  },
  components: { deviceDelete, FlowcardVerificationDialog, CmBack },
  data() {
    return {
      tableData: [],
      titleList: tableTitleListDetail,
      orderInfo: {},
      isEdit: false,
      options: [
        {
          label: "全部",
          value: null,
        },
        {
          label: "未注册",
          value: 0,
        },
        {
          label: "注册成功",
          value: 2,
        },
        {
          label: "注册失败",
          value: 3,
        },
      ],
      reqParams: {
        imeiSearch: "",
        inmeternoSearch: "",
        nbstatus: null,
        orderid: "",
        pageNum: 1,
        pageSize: 10,
        pageNumFinish: 1,
      },
      totalItemCount: 0,
      canRegister: false,
      deleteList: [],
    };
  },
  activated() {
    if (this.$route.params.orderid) {
      this.reqParams.orderid = this.$route.params.orderid;
      this.reqParams.pageNum = 1;
      this.getOrderInfo();
      this.getOrderList();
    }
  },
  created() {},
  mounted() {
    if (this.$route.params.orderid) {
      this.reqParams.orderid = this.$route.params.orderid;
      this.getOrderInfo();
      this.getOrderList();
    }
  },
  methods: {
    onClickGoBack() {
      this.$router.push({ name: "EquipmentPutStorage" });
    },
    ...mapActions({
      addKeepAlive: "addKeepAlivePage",
      orderInfoDetail: "ZD_ProductionOrderDetail",
      orderList: "ZD_ProductionOrderDetailList",
      register: "ZD_ProductionOrderNbreg",
    }),
    getIndex(idx) {
      return (
        idx + 1 + (this.reqParams.pageNumFinish - 1) * this.reqParams.pageSize
      );
    },
    edit(e) {
      this.isEdit = e == 0;
      if (e == 0) {
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    deleteDeviceAll() {
      if (this.deleteList.length == 0) {
        this.$message.warning("请选择设备");
        return;
      }
      let params = {
        orderid: this.reqParams.orderid,
        inmeternoList: [],
        deviceCount: this.deleteList.length,
      };
      this.deleteList.length > 0 &&
        this.deleteList.map((item) => {
          params.inmeternoList.push(item.inmeterno);
        });
      this.$refs.deviceDelete.init(params);
    },
    deleteDevice(e) {
      let params = {
        orderid: this.reqParams.orderid,
        inmeternoList: [e.inmeterno],
        deviceCount: 1,
      };
      this.$refs.deviceDelete.init(params);
    },
    handleSelectionChange(e) {
      this.deleteList = e;
    },
    handleSizeChange(size) {
      this.reqParams.pageSize = size;
      this.reqParams.pageNum = 1;
      this.getOrderList();
    },
    handleCurrentChange(e) {
      this.reqParams.pageNum = e;
      this.getOrderList();
    },
    refrash() {
      this.reqParams.pageNum = 1;
      this.getOrderList();
      this.getOrderInfo();
    },
    getOrderList() {
      this.orderList(this.reqParams)
        .then((res) => {
          if (res.success == 1) {
            this.tableData = res.listitem;
            this.totalItemCount = res.totalItemCount;
          } else {
            this.tableData = [];
          }
        })
        .finally((f) => {
          this.reqParams.pageNumFinish = this.reqParams.pageNum;
        });
    },
    detail(e) {
      this.$confirm("是否重新注册?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.register({ inmeterno: e.inmeterno }).then((res) => {
            if (res.success == 1) {
              this.$message({
                type: "success",
                message: "注册成功!",
              });
            }
          });
        })
        .catch(() => {});
    },
    getOrderInfo() {
      this.orderInfoDetail(this.reqParams).then((res) => {
        if (res.success == 1) {
          this.orderInfo = res.value;
          this.canRegister = res.value.type == 2;
        }
      });
    },
    deviceImport() {},
    formatter(row, { property }) {
      let ret = null;
      switch (property) {
        case "yearfeeflag":
          ret = row[property] ? "收取" : "不收取";
          break;
        case "nbstatus":
          if (row[property] == 0) {
            ret = "未注册";
          } else if (row[property] == 1) {
            ret = "注册中";
          } else if (row[property] == 2) {
            ret = "注册成功";
          } else if (row[property] == 3) {
            ret = row["reginfo"];
          } else {
            ret = "未知";
          }
          break;
        case "status":
          if (row["pointid"]) {
            ret = "已安装";
            break;
          }
          if (row[property] == 0) {
            ret = "在库";
          } else if (!row[property]) {
            ret = "未知";
          } else {
            ret = "已分配";
          }
          break;
        default:
          ret = this.isEmpty(row[property]) ? "--" : row[property];
          break;
      }
      return ret;
    },
    onClickFlowCardVerification() {
      this.$refs.flowcardVerification.show();
    },
  },
  computed: {},
  watch: {},
  filters: {},
  //keepAlive
  deactivated() {},
  updated() {},
  destroyed() {},
};
</script>
<style lang='less' scoped>
#sign2 {
  /deep/ .el-button {
    color: #409eff;
    border: 1px solid #b3d8ff;
    background: #ecf5ff;
  }
  /deep/ .el-button:hover {
    background-color: #f2f9ff;
  }
}
.form_margin {
  margin-left: 14px;
}
/deep/.el-button--primary.is-plain {
  background-color: #fff;
  color: #409eff;
}
#test_infoCount {
  font-size: 14px;
  color: #999;
  position: absolute;
  bottom: 42px;
  left: 30px;
}
.bottom_page {
  position: absolute;
  bottom: 36px;
  // bottom: 13px;
  right: 20px;
}
.top_detail {
  width: 100%;
  background-color: #edf8ff;
  height: 44px;
  padding: 0 12px;
  p {
    font-size: 14px;
    color: #666;
  }
  div {
    margin-right: 40px;
  }
}
</style>