<template>
  <div class="device_tab">
    <el-row align="middle" type="flex" >
      <el-col :span="5">{{ $t("lang.underconcentractor") }}</el-col>
      <el-col :span="4"
        ><el-button type="primary" size="medium" @click="setnetworking">{{
          $t("lang.keynetwork")
        }}</el-button></el-col
      >
    </el-row>
    <!-- 表格信息 -->
    <el-table
      :data="dataList"
      :show-summary="false"
      style="width: 100%; margin-top: 20px"
      height="59.5vh"
      v-el-table-infinite-scroll="load"
    >
      <el-table-column
        :label="$t('lang.num')"
        align="left"
        type="index"
        width="100"
      >
      </el-table-column>
      <el-table-column
        v-for="(item, index) in titleList"
        :key="index"
        :prop="item.prop"
        :width="item.width ? item.width : '150'"
        :align="item.align ? item.align : 'left'"
        :label="$t(item.label)"
      >
        <template slot-scope="scope">
          <div v-if="item.prop == 'devicetype'">
            {{
              scope.row[item.prop] == 0 ? $t("lang.emeter") : $t("lang.wmeter")
            }}
          </div>
          <div v-else-if="item.prop == 'isnetwork'">
            {{
              scope.row[item.prop]
                ? $t("lang.isnetwork")
                : $t("lang.notnetwork")
            }}
          </div>
          <div v-else-if="item.prop == 'mstatus'">
            {{ deviceStatus(scope.row) }}
          </div>
          <div v-else>{{ scope.row[item.prop] || "--" }}</div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { option_line, titleList } from "./title";
import { MyChart } from "@/app/components";
import moment from "moment";
export default {
  components: { MyChart },
  data() {
    return {
      reqParams: {
        rtuid: null,
        pageSize: 15,
        pageNum: 1,
      },
      dataList: [],
      titleList: titleList,
      loading: false,
      endShow: false,
    };
  },
  methods: {
    ...mapActions({
      rtuList: "ZD_RtuDeviceList",
      setNetWork: "ZD_SetNetWorkingAPS",
    }),
    refrash(){
      this.endShow = false;
      this.reqParams.pageNum = 1;
      this.getDataList();
    },
    setData(e) {
      this.endShow = false;
      this.reqParams.pageNum = 1;
      this.reqParams.rtuid = e.meterId;
      this.reqParams.deviceType = e.type;
      this.getDataList();
    },
    getDataList() {
      this.rtuList(this.reqParams).then((res) => {
        console.log(res);
        if (res.success == 1) {
          if (res.listitem.length > 0) {
            if (this.reqParams.pageNum > 1) {
              res.listitem.map((item) => {
                this.dataList.push(item);
              });
            } else {
              this.dataList = res.listitem;
              // res.listitem.map(item=>{
              //     this.dataList.push(item);
              //   })
            }
          } else {
            this.endShow = true;
            this.reqParams.pageNum--;
          }
        }
      });
    },
    load() {
      if (this.endShow) {
        return;
      }
      console.log("loading");
      this.reqParams.pageNum++;
      this.getDataList();
    },
    //查询
    search() {
      this.getDataList();
    },
    //一键组网
    setnetworking() {
      this.dataList.forEach((item) => {
        if (item.isnetwork == null) {
          let postData = {
            address: item.qmeterno,
            pointid: item.pointid,
            rtuid: this.reqParams.rtuid,
          };
          this.setNetWork(postData).then((res) => {
            this.refrash();
            if (res.success == 1) {
              this.$message({
                type: "success",
                message: this.$t("lang.networksuccess") + "！",
              });
            }
          });
        }
      });
    },
    deviceStatus(data) {
      let ret = "lang.offline";
      if (data.mstatus == 1 && data.devicetype == 0) {
        if (data.ctrlstatus == 1) {
          ret = "lang.closedswitch";
        } else {
          ret = "lang.pullswitch";
        }
      } else if (data.mstatus == 1 && data.devicetype == 1) {
        if (data.ctrlstatus == 1) {
          ret = "lang.online";
        } else {
          ret = "lang.offline";
        }
      } else if (data.mstatus == 1 && data.devicetype == 3) {
        if (data.ctrlstatus == 1) {
          ret = "lang.online";
        } else {
          ret = "lang.offline";
        }
      } else {
        ret = "lang.offline";
      }
      return this.$t(ret);
    },
  },
  filters: {
  },
};
</script>

<style lang="less" scoped>
.device_tab {
  margin-top: 22px;
}
</style>