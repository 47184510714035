<template>
  <el-dialog
    :title="$t('lang.exportinformation')"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    width="900px"
    :before-close="handleClose"
  >
    <div class="down_bg">
      <div class="down_top _col_center">
        <p class="color333 font14">{{ $t("lang.exportsaved") }}</p>
      </div>
      <div class="export_record _row_between">
        <p class="font14">
          {{ $t("lang.exportrecord") }}({{ $t("lang.totalnum") }}:{{
            totalItemCount
          }})
        </p>
        <el-button type="primary" size="small" @click="refrash">{{
          $t("lang.refresh")
        }}</el-button>
      </div>
      <el-table
        :data="dataList"
        height="300"
        v-loading="loading"
        style="width: 100%"
        :header-cell-style="{ backgroundColor: '#eeeeee' }"
      >
        <el-table-column
          :label="$t('lang.num')"
          align="left"
          type="index"
          width="70"
        >
        </el-table-column>
        <el-table-column
          v-for="(item, index) of tableList"
          :key="index"
          :prop="item.prop"
          :width="item.width"
          :show-overflow-tooltip="true"
          :label="$t(item.label)"
          :align="item.align"
        >
          <template slot-scope="scope">
            <div v-if="item.prop == 'startTime' || item.prop == 'endTime'">
              <span>{{
                scope.row[item.prop] | timeFormat("YYYY-MM-DD HH:mm:ss")
              }}</span>
            </div>
            <div v-else-if="item.prop == 'status'">
              <el-progress
                type="circle"
                :percentage="
                  scope.row[item.prop] == 0 ? scope.row['progress'] : 100
                "
                :width="40"
                :status="
                  scope.row[item.prop] == 0
                    ? null
                    : scope.row[item.prop] == 1
                    ? 'success'
                    : 'exception'
                "
                style="padding: 10px"
              ></el-progress>
            </div>
            <div v-else-if="item.prop == 'remark'">
              <span>{{
                scope.row.status == 0
                  ? $t("lang.exporting")
                  : scope.row[item.prop]
              }}</span>
            </div>
            <div v-else>
              <span>{{ scope.row[item.prop] || "--" }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('lang.operate')" align="left" width="100">
          <template slot-scope="scope">
            <div class="_row">
              <a
                :class="[
                  scope.row.status == 3 || scope.row.status == 0
                    ? 'errorState'
                    : 'successState',
                ]"
                :href="getDownUrl(scope.row['filePath'], scope.row.status)"
                download="ssss.xls"
                >{{ $t("lang.down") }}</a
              >
              <a style="color: rgb(21, 122, 255); margin: 0 5px">|</a>
              <a
                style="color: rgb(21, 122, 255); cursor: pointer"
                @click="del(scope.row)"
                >{{ $t("lang.delete") }}</a
              >
              <!-- <el-button type="text"  @click="down(scope.row)">{{
                $t("lang.down")
                }}</el-button> -->
              <!-- <el-button type="text"  @click="del(scope.row)">{{
                $t("lang.delete")
                }}</el-button> -->
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </el-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { titleList } from "./titleList";
export default {
  data() {
    return {
      dialogVisible: false,
      dataList: [],
      loading: false,
      tableList: titleList,
      totalItemCount: 0,
      timer: null,
    };
  },
  mounted() {
    // this.timeGetList();
  },
  computed: {
    ...mapGetters(["downloadDialogShow"]),
  },
  watch: {
    downloadDialogShow(newVal) {
      console.log(newVal);
      this.dialogVisible = newVal;
      if (newVal) {
        this.getList();
      }
    },
  },
  methods: {
    ...mapMutations(["showDownload"]),
    ...mapActions({
      exportList: "Export_RecordList",
      exportListDel: "Export_RecordDel",
    }),
    timeGetList() {
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        this.getList();
      }, 1000);
    },
    handleClose(done) {
      this.showDownload();
    },
    getDownUrl(e, status) {
      if (status == 3 || status == 0) return null;
      if (e && e.indexOf("http") != -1) {
        if (process.env.NODE_ENV == "development") {
          return e;
        } else {
          return String(e).replace(/http/, "https").replace(/:9111/, ":9112");
        }
      } else {
        return e;
      }
    },
    refrash() {
      this.getList();
    },
    getList() {
      this.exportList({})
        .then((res) => {
          console.log(res);
          if (res.success == 1) {
            let list = res.listitem;
            list.map((item) => {
              item.progress = 0;
              if (item.remark == undefined)
                item.remark = this.$t("lang.exportsucceeded");
            });
            this.dataList = list;
            this.totalItemCount = res.totalItemCount;
            if (res.listitem[0].status != 0) {
              clearInterval(this.timer);
            } else {
              this.timeGetList();
            }
          } else {
            this.dataList = [];
            this.totalItemCount = 0;
          }
        })
        .catch((err) => {
          clearInterval(this.timer);
        });
    },
    down(e) {
      console.log(e);
      window.open(e.filePath);
    },
    del(e) {
      this.$confirm(this.$t("lang.makesuredel"), this.$t("lang.tips"), {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.recodeDel(e);
        })
        .catch(() => {});
      console.log(e);
    },
    recodeDel(e) {
      this.exportListDel({ id: e.id }).then((res) => {
        if (res.success == 1) {
          this.refrash();
          this.$message({
            type: "success",
            message: this.$t("lang.delsuccess"),
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.successState {
  color: rgb(21, 122, 255);
  cursor: pointer;
}
.errorState {
  color: #c4c4c4;
  cursor: not-allowed;
}
.down_bg {
  width: 780px;
  margin: 0 auto;
  padding-bottom: 20px;
  .down_top {
    width: 160px;
    height: 35px;
    background-color: rgba(245, 108, 108, 0.2);
  }
  .export_record {
    height: 56px;
    width: 100%;
  }
}
</style>