const option_line = {
    // legend: {
    //     show: true,
    //     selected: {
    //         ["电费"]: false,
    //         ["冷水费"]: false,
    //         ["热水费"]: false,
    //         ["公摊费"]: false,
    //         ["总额"]: true
    //     },
    //     icon: "roundRect",
    //     itemWidth: 10,
    //     itemHeight: 10,
    //     right: 20,
    //     top: 5,
    // },
    // title: {
    //   text: "ECharts '入门示例'"
    // },
    tooltip: {},
    grid: {
        top: "10%",
        left: "3%",
        right: "3%",
        bottom: "3%",
        containLabel: true
    },
    xAxis: [{
        type: "category",
        boundaryGap: false,
        axisLine: {
            //取消x轴线
            show: false
        },
        axisTick: {
            //取消刻度
            length: 0
        },
        data: []
    }],
    yAxis: [{
        type: "value",
        splitLine: {
            lineStyle: {
                type: "dashed"
            }
        },
        axisLine: {
            //取消y轴线
            show: false
        },
        axisTick: {
            //取消刻度
            length: 0
        }
    }],
    series: [{
        name: '电费',
        type: "line",
        areaStyle: {
            // color: "#409EFF"
            color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                    offset: 0,
                    color: '#deeeff' // 0% 处的颜色
                }, {
                    offset: 1,
                    color: 'white' // 100% 处的颜色
                }],
                global: false // 缺省为 false
            }
        },
        lineStyle: {
            color: "#409EFF"
        },
        symbol: "none",
        // itemStyle: {
        //   normal: {
        //     color: "#f4b64f"
        //   }
        // },
        smooth: true,
        // symbol: "none",
        data: []
    }]
}



const titleList = [{
    label: "lang.datatime", //数据时间
    prop: "datatime",
    align: "left"
},
// {
//     label: "lang.reminapower_unit", //剩余电量
//     prop: "totalenergy"
// },
{
    label: "lang.totalpower_unit", //总用电量
    prop: "totalenergy"
},
{
    label: "lang.Aphasevoltage", //A相电压
    prop: "ua"
},
{
    label: "lang.Aphasecurrent", //A相电流
    prop: "ia"
},
{
    label: "lang.Bphasevoltage", //B相电压
    prop: "ub"
},
{
    label: "lang.Bphasecurrent", //B相电流
    prop: "ib"
},
{
    label: "lang.Cphasevoltage", //C相电压
    prop: "uc"
},
{
    label: "lang.Cphasecurrent", //C相电流
    prop: "ic"
},
{
    label: "lang.powerw", //功率因数
    prop: "pa"
},
]
const titleList1 = [{
    label: "lang.datatime", //数据时间
    prop: "datatime",
    align: "left"
},
// {
//     label: "lang.reminapower_unit", //剩余电量
//     prop: "totalenergy"
// },
{
    label: "lang.totalpower_unit", //总用电量
    prop: "totalenergy"
},
{
    label: "lang.voltage", //A相电压
    prop: "ua"
},
{
    label: "lang.current", //A相电流
    prop: "ia"
},
{
    label: "lang.powerw", //功率因数
    prop: "pa"
},
]

export {
    option_line,
    titleList,
    titleList1
}