const option_line = {
    // legend: {
    //     show: true,
    //     selected: {
    //         ["电费"]: false,
    //         ["冷水费"]: false,
    //         ["热水费"]: false,
    //         ["公摊费"]: false,
    //         ["总额"]: true
    //     },
    //     icon: "roundRect",
    //     itemWidth: 10,
    //     itemHeight: 10,
    //     right: 20,
    //     top: 5,
    // },
    // title: {
    //   text: "ECharts '入门示例'"
    // },
    tooltip: {},
    grid: {
        top: "10%",
        left: "3%",
        right: "3%",
        bottom: "3%",
        containLabel: true
    },
    xAxis: [{
        type: "category",
        boundaryGap: false,
        axisLine: {
            //取消x轴线
            show: false
        },
        axisTick: {
            //取消刻度
            length: 0
        },
        data: []
    }],
    yAxis: [{
        type: "value",
        splitLine: {
            lineStyle: {
                type: "dashed"
            }
        },
        axisLine: {
            //取消y轴线
            show: false
        },
        axisTick: {
            //取消刻度
            length: 0
        }
    }],
    series: [{
        name: '电费',
        type: "line",
        areaStyle: {
            // color: "#409EFF"
            color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                    offset: 0,
                    color: '#deeeff' // 0% 处的颜色
                }, {
                    offset: 1,
                    color: 'white' // 100% 处的颜色
                }],
                global: false // 缺省为 false
            }
        },
        lineStyle: {
            color: "#409EFF"
        },
        symbol: "none",
        // itemStyle: {
        //   normal: {
        //     color: "#f4b64f"
        //   }
        // },
        smooth: true,
        // symbol: "none",
        data: []
    }]
}



const option_line_water = {
    // legend: {
    //     show: true,
    //     selected: {
    //         ["电费"]: false,
    //         ["冷水费"]: false,
    //         ["热水费"]: false,
    //         ["公摊费"]: false,
    //         ["总额"]: true
    //     },
    //     icon: "roundRect",
    //     itemWidth: 10,
    //     itemHeight: 10,
    //     right: 20,
    //     top: 5,
    // },
    // title: {
    //   text: "ECharts '入门示例'"
    // },
    tooltip: {},
    grid: {
        top: "10%",
        left: "3%",
        right: "3%",
        bottom: "3%",
        containLabel: true
    },
    xAxis: [{
        type: "category",
        boundaryGap: false,
        axisLine: {
            //取消x轴线
            show: false
        },
        axisTick: {
            //取消刻度
            length: 0
        },
        data: []
    }],
    yAxis: [{
        type: "value",
        splitLine: {
            lineStyle: {
                type: "dashed"
            }
        },
        axisLine: {
            //取消y轴线
            show: false
        },
        axisTick: {
            //取消刻度
            length: 0
        }
    }],
    series: [{
        name: '电费',
        type: "line",
        areaStyle: {
            // color: "#409EFF"
            color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                    offset: 0,
                    color: '#deeeff' // 0% 处的颜色
                }, {
                    offset: 1,
                    color: 'white' // 100% 处的颜色
                }],
                global: false // 缺省为 false
            }
        },
        lineStyle: {
            color: "#409EFF"
        },
        symbol: "none",
        // itemStyle: {
        //   normal: {
        //     color: "#f4b64f"
        //   }
        // },
        smooth: true,
        // symbol: "none",
        data: []
    }]
}


const titleList = [{
        label: "lang.startingtime", //数据时间
        prop: "begintime",
        align: "left"
    },
    {
        label: "lang.endingtime", //数据时间
        prop: "endtime",
        align: "left"
    },
    // {
    //     label: "lang.initialread", //起始示数
    //     prop: "beginvalue"
    // },
    // {
    //     label: "lang.endread", //结束示数
    //     prop: "endvalue"
    // },
    {
        label: "lang.usepower", //用电量
        prop: "importenergy"
    },
]

const waterTitleList = [{
        label: "lang.startingtime", //数据时间
        prop: "begintime",
        align: "left"
    },
    {
        label: "lang.endingtime", //数据时间
        prop: "endtime",
        align: "left"
    },
    // {
    //     label: "lang.winitialread", //起始示数
    //     prop: "beginvalue"
    // },
    // {
    //     label: "lang.wendread", //结束示数
    //     prop: "endvalue"
    // },
    {
        label: "lang.usewater", //用水量
        prop: "totalvalue"
    },
]

export {
    option_line,
    titleList,
    option_line_water,
    waterTitleList
}