<template>
  <div class="common_bg">
    <div class="echart_box">
      <echart
        :options="echartOption"
        :height="165"
        :style="'width:' + echartWidth"
      ></echart>
      <echart
        :options="echartOption1"
        :height="165"
        :style="'width:' + echartWidth"
      ></echart>
      <echart
        :options="echartOption2"
        :height="165"
        :style="'width:' + echartWidth"
      ></echart>
    </div>
    <div id="search_box">
      <el-form inline>
        <el-form-item class="form-margin" label="采购主体">
          <el-radio-group v-model="reqParams.company">
            <el-radio-button
              v-for="item in payOptions"
              :label="item.value"
              :key="item.label"
              >{{ item.label }}</el-radio-button
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item class="form-margin" label="运营商">
          <el-radio-group v-model="reqParams.carrierType">
            <el-radio-button
              v-for="item in operatorOptions"
              :label="item.value"
              :key="item.label"
              >{{ item.label }}</el-radio-button
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item class="form-margin" label="网络类型">
          <el-radio-group v-model="reqParams.flowType">
            <el-radio-button
              v-for="item in netOptions"
              :label="item.value"
              :key="item.label"
              >{{ item.label }}</el-radio-button
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item class="form-margin" label="所属平台" id="sign3">
          <el-input
            maxlength="20"
            v-model="reqParams.platform"
            placeholder="请输入所属平台"
            @keyup.enter.native="search"
          ></el-input>
        </el-form-item>
        <el-form-item class="form-margin" label="采购人" id="sign3">
          <el-input
            maxlength="10"
            v-model="reqParams.buyer"
            placeholder="请输入采购人"
            @keyup.enter.native="search"
          ></el-input>
        </el-form-item>
        <el-form-item class="form-margin" label="订单号" id="sign4">
          <el-input
            maxlength="20"
            v-model="reqParams.orderno"
            placeholder="请输入订单号"
            @keyup.enter.native="search"
          ></el-input>
        </el-form-item>
        <el-form-item class="form-margin" label="供应商" id="sign3">
          <el-input
            maxlength="20"
            v-model="reqParams.provider"
            placeholder="请输入供应商"
            @keyup.enter.native="search"
          ></el-input>
        </el-form-item>
        <el-form-item class="form-margin" label="业务名称" id="sign5">
          <el-input
            maxlength="20"
            v-model="reqParams.plainName"
            placeholder="请输入业务名称"
            @keyup.enter.native="search"
          ></el-input>
        </el-form-item>
        <el-form-item class="form-margin" label="受理地区" id="sign5">
          <el-input
            maxlength="20"
            v-model="reqParams.acceptArea"
            placeholder="请输入受理地区"
            @keyup.enter.native="search"
          ></el-input>
        </el-form-item>
        <el-form-item class="form-margin" label="受理时间" id="sign5">
          <el-date-picker
            v-model="timeValue"
            @change="timeChange"
            value-format="yyyy-MM-dd"
            type="month"
            placeholder="选择月"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item class="form-margin" label="客户经理" id="sign5">
          <el-input
            maxlength="20"
            v-model="reqParams.customerManager"
            placeholder="请输入客户经理"
            @keyup.enter.native="search"
          ></el-input>
        </el-form-item>
        <el-form-item class="form-margin" label="">
          <el-button type="primary" @click="search()">查询</el-button>
        </el-form-item>
        <el-form-item class="form-margin" label="">
          <el-button plain type="primary" @click="putstorage()"
            >流量卡入库</el-button
          >
        </el-form-item>
        <el-form-item class="form-margin" label="">
          <el-button plain type="primary" @click="onClickCancle"
            >流量卡注销</el-button
          >
        </el-form-item>
        <el-form-item class="form-margin" label="">
          <el-button plain type="primary" @click="onClickFlowCardExport"
            >未关联卡导出</el-button
          >
        </el-form-item>
        <!-- <el-form-item class="form-margin" label="">
        <el-button type="primary"
          ><a
            :href="`${path}card-import.xls`"
            style="color: #fff"
            download="流量卡入库模版.xls"
            >下载模板</a
          >
        </el-button>
      </el-form-item> -->
      </el-form>
    </div>
    <!-- 表格信息 -->
    <el-table
      :data="dataList"
      :show-summary="false"
      style="width: 100%"
      v-loading="loading"
      :max-height="340"
    >
      <el-table-column label="编号" type="index" :index="getIndex" width="50">
      </el-table-column>
      <el-table-column
        v-for="(item, index) in tableTitleList"
        :key="index"
        :prop="item.prop"
        align="left"
        :label="item.label"
        :width="item.width || '150'"
        show-overflow-tooltip
        :formatter="formatter"
      >
      </el-table-column>
      <el-table-column label="操作" width="200" fixed="right">
        <template slot-scope="scope" class="_row">
          <el-button type="text" @click="detail(scope.row)">详情</el-button>
          <span class="interval">|</span>
          <el-button type="text" v-if="scope.row['fileUrl']"
            ><a
              :href="scope.row['fileUrl']"
              style="color: #409eff"
              download="流量卡入库模版.xls"
              >下载附件</a
            >
          </el-button>
          <el-button type="text" disabled v-else style="color: #999;">下载附件</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="height: 65px"></div>
    <div>
      <el-pagination
        class="bottom_page"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="10"
        :total="totalItemCount"
        :current-page.sync="reqParams.pageNum"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { options, options1 } from "./options";
import { chartOption, chartOption1, chartOption2 } from "./echarts-option";
import { tableTitleList } from "./titleOptions";
import { mapActions } from "vuex";
import moment from "moment";
export default {
  name: "DataCardManagement",
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$nextTick(() => {
        vm.addKeepAlive("DataCardManagement");
        vm.toUpdate();
      });
    });
  },
  components: {},
  data() {
    return {
      echartWidth: "350px",
      options: options,
      options1: options1,
      timeValue: null,
      tableTitleList: tableTitleList,
      path: process.env.BASE_URL,
      echartOption: chartOption,
      echartOption1: chartOption1,
      echartOption2: chartOption2,
      loading: false,
      dataList: [],
      reqParams: {
        acceptArea: "",
        acceptTimeEnd: "",
        acceptTimeStart: "",
        buyer: "",
        carrierType: "",
        company: "",
        customerManager: "",
        flowType: "",
        orderno: "",
        pageNum: 1,
        pageSize: 10,
        pageNumFinish: 1,
        phonenum: "",
        plainName: "",
        platform: "",
        provider: "",
      },
      totalItemCount: 0,
      //#region 选项
      netOptions: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "NB",
          value: 0,
        },
        {
          label: "GPRS",
          value: 1,
        },
        {
          label: "4G",
          value: 2,
        },
      ],
      operatorOptions: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "移动",
          value: 0,
        },
        {
          label: "联通",
          value: 1,
        },
        {
          label: "电信",
          value: 2,
        },
      ],
      payOptions: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "永泰隆",
          value: "永泰隆",
        },
        {
          label: "我联",
          value: "我联",
        },
        {
          label: "智电",
          value: "智电",
        },
      ],
      //#endregion
    };
  },
  created() {
    this.getStatistic();
    this.getList();
  },
  mounted() {},
  methods: {
    ...mapActions({
      addKeepAlive: "addKeepAlivePage",
      orderList: "ZD_FlowCardOrderList",
      orderStatitic: "ZD_FlowCardOrderStatistic",
    }),
    getIndex(idx) {
      return (
        idx + 1 + (this.reqParams.pageNumFinish - 1) * this.reqParams.pageSize
      );
    },
    toUpdate() {
      if (this.$route.query.isUpdate) {
        this.$route.query.isUpdate = false;
        this.search();
        this.getStatistic();
      }
    },
    search() {
      this.reqParams.pageNum = 1;
      this.getList();
    },
    handleSizeChange(size) {
      this.reqParams.pageSize = size;
      this.getList();
    },
    handleCurrentChange(e) {
      this.reqParams.pageNum = e;
      this.getList();
    },
    detail(e) {
      console.log(e);
      this.$router.push({ name: "DataCardDetail", params: e });
    },
    timeChange(e) {
      console.log(e);

      if (e) {
        this.reqParams.acceptTimeStart = moment(e).format(
          "YYYY-MM-01 00:00:00"
        );
        this.reqParams.acceptTimeEnd = moment(e)
          .add({ months: 1 })
          .format("YYYY-MM-01 00:00:00");
      } else {
        this.reqParams.acceptTimeStart = "";
        this.reqParams.acceptTimeEnd = "";
      }
      return;
      if (e) {
        this.reqParams.acceptTimeStart = e[0] + " 00:00:00";
        this.reqParams.acceptTimeEnd = e[1] + " 23:59:59";
      } else {
        this.reqParams.acceptTimeStart = "";
        this.reqParams.acceptTimeEnd = "";
      }
    },
    putstorage(e) {
      this.$router.push("/flowcardstore");
      // this.$router.push("/cardputstorage");
    },
    formatter(row, { property }) {
      let ret = null;
      switch (property) {
        case "carrierType":
          ret = this.options.find((item) => item.value == row[property]).label;
          break;
        case "flowType":
          ret = this.options1.find((item) => item.value == row[property]).label;
          break;
        case "chargingTime":
          ret = "每月" + row[property] + "号";
          break;
        case "nouseCount":
          ret =
            row["nouseCount"] +
            "/" +
            row["usingCount"] +
            "/" +
            row["cancelCount"];
          break;
        case "acceptTime":
          ret = row[property]
            ? moment(row[property]).format("YYYY-MM-DD")
            : "--";
          break;
        default:
          ret = row[property] ? row[property] : row[property] == 0 ? 0 : "--";
          break;
      }
      return ret;
    },
    getStatistic() {
      this.orderStatitic({}).then((res) => {
        if (res.success == 1) {
          res.listitem && this.dealStistic(res.listitem);
        }
      });
    },
    dealStistic(list) {
      list.map((item) => {
        let acount = item.usingCount + item.nouseCount + item.cancelCount;
        if (item.carrierType == 2) {
          this.echartOption.series[0].label.formatter =
            acount + "张\n" + "电信";
          this.echartOption.series[0].data = [
            { value: item.usingCount, name: `正常 ${item.usingCount} 张` },
            { value: item.nouseCount, name: `未使用 ${item.nouseCount} 张` },
            { value: item.cancelCount, name: `注销 ${item.cancelCount} 张` },
          ];
        } else if (item.carrierType == 0) {
          this.echartOption1.series[0].label.formatter =
            acount + "张\n" + "移动";
          this.echartOption1.series[0].data = [
            { value: item.usingCount, name: `正常 ${item.usingCount} 张` },
            { value: item.nouseCount, name: `未使用 ${item.nouseCount} 张` },
            { value: item.cancelCount, name: `注销 ${item.cancelCount} 张` },
          ];
        } else if (item.carrierType == 1) {
          this.echartOption2.series[0].label.formatter =
            acount + "张\n" + "联通";
          this.echartOption2.series[0].data = [
            { value: item.usingCount, name: `正常 ${item.usingCount} 张` },
            { value: item.nouseCount, name: `未使用 ${item.nouseCount} 张` },
            { value: item.cancelCount, name: `注销 ${item.cancelCount} 张` },
          ];
        }
      });
    },
    getList() {
      this.orderList(this.reqParams)
        .then((res) => {
          if (res.success == 1) {
            this.dataList = res.listitem;
            this.totalItemCount = res.totalItemCount;
          } else {
            this.dataList = [];
            this.totalItemCount = 0;
          }
        })
        .finally((f) => {
          this.reqParams.pageNumFinish = this.reqParams.pageNum;
        });
    },
    onClickCancle() {
      this.$router.push({ name: "FlowCardCancellation" });
    },
    onClickFlowCardExport({ target }) {
      if (target.nodeName == "SPAN") target = target.parentNode;
      target.blur();
      this.$download(
        {},
        [
          {
            label: "用户号码",
            prop: "phonenum",
          },
          {
            label: "ICCID",
            prop: "ccid",
          },
          {
            label: "L_IMSI",
            prop: "imei",
          },
          {
            label: "订单号",
            prop: "orderno",
          },
          {
            label: "产品状态",
            prop: "cardstatus",
          },
          {
            label: "受理时间",
            prop: "accepttime",
          },
          {
            label: "激活时间",
            prop: "activetime",
          },
          {
            label: "注销时间",
            prop: "canceltime",
          },
        ],
        "Export_NotAssociatedFlowcard",
        {
          cardstatus: {
            0: "未激活",
            1: "在用",
            3: "注销",
          },
        },
        "未关联设备导出"
      );
    },
  },
  computed: {},
  watch: {},
  filters: {},
  //keepAlive
  activated() {},
  deactivated() {},
  updated() {},
  destroyed() {},
};
</script>
<style lang='less' scoped>
#search_box {
  #sign3 /deep/ .el-input {
    width: 180px;
  }
  #sign4 /deep/ .el-input {
    width: 197px;
  }
  #sign5 /deep/ .el-input {
    width: 157px;
  }
  /deep/ .el-form-item__label {
    padding-right: 8px;
  }
  /deep/ .form-margin {
    margin-right: 24px;
  }
  /deep/ .el-form-item {
    margin-bottom: 14px;
  }
}
#sign2 {
  /deep/ .el-button {
    border: 1px solid #409eff;
    color: #409eff;
  }
  /deep/ .el-button:hover {
    background-color: #f2f9ff;
  }
}
.echart_box {
  width: calc(100% - 437px);
  min-width: 1150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.form-margin {
  margin-right: 20px;
}
.bottom_page {
  position: absolute;
  bottom: 36px;
  // bottom: 13px;
  right: 20px;
}
#test_infoCount {
  font-size: 14px;
  color: #999;
  position: absolute;
  // bottom: 42px;
  // left: 30px;
  bottom: 38px;
  left: 45px;
}
.interval {
  display: inline-block;
  color: #409eff;
  font-size: 14px;
  padding: 0 5px;
}
</style>