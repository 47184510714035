const chartOption = {
    tooltip: {
        trigger: 'item'
    },
    legend: {
        right: '18%',
        orient: 'vertical',
        top: 'center',
        itemWidth: 14,
    },
    color: ["rgba(64,158,255,1)", "rgba(253,179,68,1)", "rgba(245,108,108,1)"],
    series: [{
        name: '',
        type: 'pie',
        radius: ['60%', '94%'],
        center: ['24%', '50%'],
        avoidLabelOverlap: true,
        label: {
            show: true,
            position: 'center',
            fontSize: '14',
            fontWeight: "bold",
            formatter: function (params) {
                // console.log(params);
                return params.value + '张\n电信';
            }
        },
        emphasis: {
            label: {
                show: true,
                fontSize: '14',
                fontWeight: "bold",
            }
        },
        labelLine: {
            show: false
        },
        data: [
            { value: 0, name: '正常 0 张' },
            { value: 0, name: '未使用 0 张' },
            { value: 0, name: '注销 0 张' }
        ]
    }]
}
const chartOption1 = {
    tooltip: {
        trigger: 'item'
    },
    legend: {
        right: '18%',
        orient: 'vertical',
        top: 'center',
        itemWidth: 14,
    },
    color: ["rgba(64,158,255,1)", "rgba(253,179,68,1)", "rgba(245,108,108,1)"],
    series: [{
        name: '',
        type: 'pie',
        radius: ['60%', '94%'],
        center: ['24%', '50%'],
        avoidLabelOverlap: true,
        label: {
            show: true,
            position: 'center',
            fontSize: '14',
            fontWeight: "bold",
            formatter: function (params) {
                return params.value + '张\n移动';
            }
        },
        emphasis: {
            label: {
                show: true,
                fontSize: '14',
                fontWeight: "bold",
            }
        },
        labelLine: {
            show: false
        },
        data: [
            { value: 0, name: '正常 0 张' },
            { value: 0, name: '未使用 0 张' },
            { value: 0, name: '注销 0 张' }
        ]
    }]
}
const chartOption2 = {
    tooltip: {
        trigger: 'item'
    },
    legend: {
        right: '12%',
        orient: 'vertical',
        top: 'center',
        itemWidth: 14,
    },
    color: ["rgba(64,158,255,1)", "rgba(253,179,68,1)", "rgba(245,108,108,1)"],
    series: [{
        name: '',
        type: 'pie',
        radius: ['60%', '94%'],
        center: ['24%', '50%'],
        avoidLabelOverlap: true,
        label: {
            show: true,
            position: 'center',
            fontSize: '14',
            fontWeight: "bold",
            formatter: function (params) {
                return params.value + '张\n联通';
            }
        },
        emphasis: {
            label: {
                show: true,
                fontSize: '14',
                fontWeight: "bold",
            }
        },
        labelLine: {
            show: false
        },
        data: [
            { value: 0, name: '正常 0 张' },
            { value: 0, name: '未使用 0 张' },
            { value: 0, name: '注销 0 张' }
        ]
    }]
}
const chartOption3 = {
    tooltip: {
        trigger: 'item'
    },
    legend: {
        right: '3%',
        orient: 'vertical',
        top: 'center',
        itemWidth: 14,
    },
    color: ["rgba(64,158,255,1)", "rgba(253,179,68,1)", "rgba(245,108,108,1)"],
    series: [{
        name: '',
        type: 'pie',
        radius: ['50%', '80%'],
        center: ['30%', '50%'],
        avoidLabelOverlap: true,
        label: {
            show: true,
            position: 'center',
            fontSize: '14',
            fontWeight: "bold",
            formatter: function (params) {
                return params.value + '张\n其他';
            }
        },
        emphasis: {
            label: {
                show: true,
                fontSize: '14',
                fontWeight: "bold",
            }
        },
        labelLine: {
            show: false
        },
        data: [
            { value: 0, name: '正常 0 张' },
            { value: 0, name: '未使用 0 张' },
            { value: 0, name: '注销 0 张' }
        ]
    }]
}

const chartOption4 = {
    tooltip: {
        trigger: 'item'
    },
    legend: {
        right: '3%',
        orient: 'vertical',
        top: 'center',
        itemWidth: 14,
    },
    color: ["rgba(64,158,255,1)", "rgba(253,179,68,1)", "rgba(245,108,108,1)"],
    series: [{
        name: '',
        type: 'pie',
        radius: ['60%', '94%'],
        center: ['30%', '50%'],
        avoidLabelOverlap: true,
        label: {
            show: true,
            position: 'center',
            fontSize: '14',
            fontWeight: "bold",
            formatter: '0张\n其他'
        },
        emphasis: {
            label: {
                show: true,
                fontSize: '14',
                fontWeight: "bold",
            }
        },
        labelLine: {
            show: false
        },
        data: [
            { value: 0, name: '正常 0 张' },
            { value: 0, name: '未使用 0 张' },
            { value: 0, name: '注销 0 张' }
        ]
    }]
}


export {
    chartOption,
    chartOption1,
    chartOption2,
    chartOption3,
    chartOption4
}